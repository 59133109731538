import { combineReducers } from "redux";

import { authReducer } from "./authReducer";
import { userReducer } from "./userReducer";
import { toastReducer } from "./toastReducer";
import { addressReducer } from "./addressReducer";
import { productReducer } from "./productReducer";
import { checkoutReducer } from "./checkoutReducer";
import { orderReducer } from "./orderReducer";

export default combineReducers({
  Auth: authReducer,
  User: userReducer,
  Toast: toastReducer,
  Address: addressReducer,
  Product: productReducer,
  Checkout: checkoutReducer,
  Order: orderReducer,
});
