import axios from 'axios';
import * as Config from '../config/config';
var FormData = require('form-data');

export const checkout = (actionType, addressId, billingAddressId, arrivalTime, departureTime, prefer_date, nowlaterflag, quotationid, cart_assist_rfq_status, orderId, productId, quantity) =>
{

  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);

  data.append('Appkey', Config.APP_KEY);
  data.append('token', token && token);
  data.append('address_id', addressId);
  data.append('billing_address_id', billingAddressId);
  data.append('arrival_time', arrivalTime);
  data.append('departure_time', departureTime);
  data.append('prefer_date', prefer_date);
  data.append('now_later_flag', nowlaterflag)
  data.append('quotation_id', quotationid);
  data.append('cart_assist_rfq_status', cart_assist_rfq_status);
  if (actionType === 'repeat_order') {
    data.append('order_id', orderId);
    data.append('action', 'repeat_order');
  } else {
    data.append('action', 'normal');
  }
  //  var data = new FormData();
  //   const token = localStorage.getItem(Config.STORAGE_TOKEN);
  //   data.append('Appkey', Config.APP_KEY);
  //   data.append('token', token && token);
  //   data.append('action', actionType);
  //   data.append('address_id', addressId);
  //   data.append('billing_address_id', billingAddressId);
  //   data.append('specification_id', '');
  //   data.append('arrival_time', arrivalTime);
  //   data.append('departure_time', departureTime);
  //   data.append('prefer_date', prefer_date);
  //   if (actionType === 'buy_now') {
  //     data.append('product_id', productId);
  //     data.append('quantity', quantity);
  //   }
  //   if (actionType === 'repeat_order') {
  //     data.append('order_id', orderId);
  //   }

  //     data.append('quotation_id', 38);
  //     data.append('now_later_flag',0)


  return axios.post(Config.API_URL + 'Buyer_api/buyer_checkout_quotation_approve', data);
};

export const getDepartments = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append('token', token && token);
  return axios.post(Config.API_URL + 'buyer_api/get_departments', data);
};

export const addCartItemToDept = (cartId, deptId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append('token', token && token);
  data.append('cart_id', cartId);
  data.append('depart_id', deptId);
  return axios.post(Config.API_URL + 'buyer_api/cart_update', data);
};
