import { call, put, takeLatest } from 'redux-saga/effects';
import * as checkoutConstants from 'store/constants/checkoutConstants';
import * as checkoutActions from 'store/actions/checkoutActions';
import * as checkoutService from 'service/checkoutService';
import { CATCH_ERROR } from 'config/config';

export function* checkoutSaga(action)
{

  try {
    const response = yield call(
      checkoutService.checkout,
      action.actionType,
      action.addressId,
      action.billingAddressId,
      action.arrivalTime,
      action.departureTime,
      action.prefer_date,
      action.nowlaterflag,
      action.quotationid,
      action.cart_assist_rfq_status,
      action.orderId,
      action.productId,
      action.quantity,
      action.specification
    );
    yield put(checkoutActions.checkoutSuccess(response.data.data));
    console.log(response.data.data, 'response.data.data-24');
  } catch (error) {
    if (error.response) {
      yield put(checkoutActions.checkoutFail(error.response.data.msg));
    } else {
      yield put(checkoutActions.checkoutFail(CATCH_ERROR));
    }
  }
}
export function* checkoutFlowWatcher()
{
  yield takeLatest(checkoutConstants.CHECKOUT_START, checkoutSaga);
}
