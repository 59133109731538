import { call, put, takeLatest } from 'redux-saga/effects';

import * as authConstants from 'store/constants/authConstants';
import * as authActions from 'store/actions/authActions';
import * as userActions from 'store/actions/userActions';
import * as addressActions from 'store/actions/addressActions';
import * as authService from 'service/authService';
import { CATCH_ERROR, STORAGE_NAME, STORAGE_TOKEN, COMPANY_NAME, STORAGE_USER_TYPE } from 'config/config';

export function* UserLoginSaga(action) {
  try {
    const response = yield call(authService.UserLogin, action.userName, action.password, action.loginType);
    const token = response.data.data.token;
    const name = response.data.data.user_name;
    const comp_name = response.data.data.comp_name;
    const u_type = response.data.data.u_type;
    // const userid = response.data.data.user_id;
    localStorage.setItem(STORAGE_TOKEN, token);
    localStorage.setItem(STORAGE_NAME, name);
    localStorage.setItem(STORAGE_USER_TYPE, u_type);
    if (comp_name) {
      localStorage.setItem(COMPANY_NAME, comp_name);
    }

    yield put(authActions.authSuccess(token, name, comp_name));
  } catch (error) {
    if (error.response) {
      yield put(authActions.authFail(error.response.data.msg));
    } else {
      yield put(authActions.authFail(CATCH_ERROR));
    }
  }
}

export function* UserLogoutSaga(action) {
  try {
    const response = yield call(authService.UserLogout);
    localStorage.removeItem(STORAGE_TOKEN);
    localStorage.removeItem(STORAGE_NAME);
    localStorage.removeItem(COMPANY_NAME);
    localStorage.removeItem(STORAGE_USER_TYPE);
    yield put(authActions.logoutSuccess(response.data.msg));
    yield put(userActions.reset());
    yield put(addressActions.addressResponseReset());
  } catch (error) {
    if (error.response) {
      yield put(authActions.logoutFail(error.response.data.msg));
    } else {
      yield put(authActions.logoutFail(CATCH_ERROR));
    }
  }
}

export function* SendOtpSaga(action) {
  try {
    const response = yield call(authService.SendOtpService, action.userName);
    const userId = response.data.data.OTP_details.user_id;
    const msg = response.data.msg;
    yield put(authActions.sendOtpSuccess(userId, msg));
  } catch (error) {
    if (error.response) {
      yield put(authActions.sendOtpFail(error.response.data.msg));
    } else {
      yield put(authActions.sendOtpFail(CATCH_ERROR));
    }
  }
}

export function* ForgotPasswordSaga(action) {
  try {
    const response = yield call(authService.ForgotPasswordService, action.userId, action.otp, action.newPassword);
    const msg = response.data.msg;
    yield put(authActions.forgotPasswordSuccess(msg));
  } catch (error) {
    if (error.response) {
      yield put(authActions.forgotPasswordFail(error.response.data.msg));
    } else {
      yield put(authActions.forgotPasswordFail(CATCH_ERROR));
    }
  }
}

export function* authFlowWatcher() {
  yield takeLatest(authConstants.AUTH_START, UserLoginSaga);
  yield takeLatest(authConstants.SEND_OTP_START, SendOtpSaga);
  yield takeLatest(authConstants.FORGOT_PASSWORD_START, ForgotPasswordSaga);
  yield takeLatest(authConstants.LOGOUT_START, UserLogoutSaga);
}
