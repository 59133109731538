import { color } from "GlobalStyles";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

export const CategoryCardHome = (props) => {
  return (
    <CategoryCardContainer {...props}>
      <NavLink to={props.pageLink}>
        <CategoryImage className="col-lg-12" {...props}>
          <span data-toggle="tooltip" title={props.productName}>
            <h3 className="text">{props.productName}</h3>
          </span>
        </CategoryImage>
      </NavLink>
    </CategoryCardContainer>
  );
};

const CategoryCardContainer = styled.div`
  box-shadow: ${(props) => (props.page === "mobile" ? "none" : `0px 4px 8px ${color.colorBoxShadow}`)};
  margin: ${(props) => (props.page === "mobile" ? "0.5rem 0rem 1.5rem" : "0.5rem 0.9rem 1.5rem")};
  width: ${(props) => (props.page === "mobile" ? "100%" : "92% !important")};
  height: 300px;
`;

const CategoryImage = styled.div`
  background-image: ${(props) => `url(${props.imgUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  position: relative;
  h3 {
    background: #28384bad;
    position: absolute;
    bottom: 0;
    left: ${(props) => (props.page === "mobile" ? "10px" : "50px")};
    right: ${(props) => (props.page === "mobile" ? "10px" : "50px")};
    margin-bottom: 0;
    padding: ${(props) => (props.page === "mobile" ? "4px" : "5px")};
    color: white;
    text-align: center;
    font: normal normal 600 13px/19.5px Ubuntu;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
