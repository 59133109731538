import React from 'react';
import { hydrate, render } from 'react-dom';
import { App } from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'store/reducers/rootReducer';
import rootSaga from 'saga/rootSaga';
import * as authActions from 'store/actions/authActions';
import { STORAGE_TOKEN, STORAGE_NAME, COMPANY_NAME } from 'config/config';

import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

const token = localStorage.getItem(STORAGE_TOKEN);
const name = localStorage.getItem(STORAGE_NAME);
const comp_name = localStorage.getItem(COMPANY_NAME);
if (token) {
  store.dispatch(authActions.authSuccess(token, name, comp_name));
}

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}


