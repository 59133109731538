import { call, put, takeLatest } from "redux-saga/effects";
import * as orderConstants from "store/constants/orderConstants";
import * as orderActions from "store/actions/orderActions";
import * as orderService from "service/orderService";
import { CATCH_ERROR } from "config/config";

export function* changeOrderStatusSaga(action) {
  try {
    const response = yield call(orderService.changeUserOrderStatus, action.orderId, action.orderStatus, action.reason, action.amount, action.utr, action.creditPeriod, action.paymentType, action.paymentAction);
    yield put(orderActions.changeOrderStatusSuccess(response.data.msg));
  } catch (error) {
    if (error.response) {
      yield put(orderActions.changeOrderStatusFail(error.response.data.msg));
    } else {
      yield put(orderActions.changeOrderStatusFail(CATCH_ERROR));
    }
  }
}

export function* UserBillingDetailsSaga(action) {
  try {
    const response = yield call(orderService.userBilling, action.productId, action.quantity, action.specification);
    yield put(orderActions.userBillingDetailsSuccess(response.data.data.ordered));
  } catch (error) {
    if (error.response) {
      yield put(orderActions.userBillingDetailsFail(error.response.data.msg));
    } else {
      yield put(orderActions.userBillingDetailsFail(CATCH_ERROR));
    }
  }
}

export function* BillingByOrderIdSaga(action) {
  try {
    const response = yield call(orderService.billByOrder, action.orderId);
    yield put(orderActions.billingByOrderIdSuccess(response.data.data.ordered));
  } catch (error) {
    if (error.response) {
      yield put(orderActions.billingByOrderIdFail(error.response.data.msg));
    } else {
      yield put(orderActions.billingByOrderIdFail(CATCH_ERROR));
    }
  }
}

export function* orderFlowWatcher() {
  yield takeLatest(orderConstants.CHANGE_ORDER_STATUS_START, changeOrderStatusSaga);
  yield takeLatest(orderConstants.GET_USER_BILLING_DETAILS_START, UserBillingDetailsSaga);
  yield takeLatest(orderConstants.BILLING_BY_ORDER_ID_START, BillingByOrderIdSaga);
}
