import React from 'react';
import buyer from 'assets/images/Home/buyer.svg';
import seller from 'assets/images/Home/seller.svg';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

function BuyerSellerSection() {
  return (
    <BuyerSellerContainer className='container-fluid sectionSpacing'>
      <div className='row justify-content-between'>
        <div className='col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center'>
          <div className='become-buyer-seller'>
            <h2>Become a buyer</h2>
            <NavLink to='/become-buyer'>
              <button className='white-bordered-btn'>Know More</button>
            </NavLink>
          </div>
        </div>
        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
          <img src={buyer} alt='become buyer' className='img-fluid' />
        </div>
        <div className='col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center'>
          <img src={seller} alt='become supplier' className='img-fluid' />
        </div>
        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
          <div className='become-buyer-seller text-right'>
            <h2 className='text-right'>Become a seller</h2>
            <NavLink to='/become-seller'>
              {' '}
              <button className='white-bordered-btn'>Know More</button>
            </NavLink>
          </div>
        </div>
      </div>
    </BuyerSellerContainer>
  );
}

const BuyerSellerContainer = styled.div`
.become-buyer-seller {
  background: #28384B 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  h2 {
    font: normal normal 500 2.625rem/4rem Ubuntu;
    color: #FAF3E1;
    text-transform: uppercase;
    padding-bottom: 60px;
  }
`;

export default React.memo(BuyerSellerSection);
