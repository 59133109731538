import * as checkoutConstants from 'store/constants/checkoutConstants';

export const checkoutStart = (actionType, addressId, billingAddressId, arrivalTime, departureTime, prefer_date, flag, quotationid, cart_assist_rfq_status, orderId, productId, quantity, specification) =>
{
  return {
    type: checkoutConstants.CHECKOUT_START,
    actionType: actionType,
    addressId: addressId,
    billingAddressId,
    arrivalTime: arrivalTime,
    departureTime: departureTime,
    prefer_date: prefer_date,
    flag,
    quotationid,
    cart_assist_rfq_status,
    orderId,
    productId,
    quantity,
    specification,
  };
};

export const checkoutSuccess = (orderDetails) =>
{
  return {
    type: checkoutConstants.CHECKOUT_SUCCESS,
    orderDetails: orderDetails,
    response: 'Order Placed Success',
  };
};

export const checkoutFail = (error) =>
{
  return {
    type: checkoutConstants.CHECKOUT_FAIL,
    error: error,
  };
};

export const checkoutResponseReset = () =>
{
  return {
    type: checkoutConstants.CHECKOUT_RESET,
  };
};
