import React, { useEffect, useState } from 'react';
import Banner from './Banner';
import * as homeService from 'service/homeService';
import * as generalService from 'service/generalService';
import * as blogService from 'service/blogService';
import AboutSection from './AboutSection';
import ShippingBoxes from 'assets/images/AboutUs/shipping-boxes.png';
import BannerImg from 'assets/images/Home/cart-to-port.png';
import BuyerSellerSection from './BuyerSellerSection';
import Counter from './Counter';
import ContactSection from './ContactSection';
import { BlogSection } from './BlogSection';
import SectionHeadings from 'component/Utilities/SectionHeadings';
import Slider from 'react-slick';
import { CategoryCard1 } from 'component/Utilities/CategoryCard1';
import Layout from 'component/Layout/Layout';
import styled from 'styled-components';
import WhatsAppIcon from 'assets/icons/whatsapp.svg';
import Skeleton from 'react-loading-skeleton';
import { CategoryCardHome } from 'component/Utilities/CategoryCardHome';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
function Home(props)
{
  const [mostSellingProducts, setMostSellingProducts] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState([]);
  const [quickDeliveryProducts, setQuickDeliveryProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const history = useHistory();
  useEffect(() =>
  {
    const orderId = sessionStorage.getItem('orderId');
    if (orderId) {
      history.push("user-profile/user-orders/" + orderId);
    }

  })
  const bannerSettings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const blogSliderSettings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() =>
  {
    async function getMostSellingProducts()
    {
      try {
        const mostSellingProducts = await generalService.getMostSellingProduct();
        setMostSellingProducts(mostSellingProducts.data.data.mostSellingProducts);
      } catch (err) {
        console.log(err);
      }
    }
    getMostSellingProducts();
  }, []);

  useEffect(() =>
  {
    async function getProductsByCategory()
    {
      try {
        const productsByCategory = await homeService.productsByCategory();
        setProductsByCategory(productsByCategory.data.data.shopByCategory);
      } catch (err) {
        console.log(err);
      }
    }
    getProductsByCategory();
  }, []);

  useEffect(() =>
  {
    async function getQuickDeliveryProducts()
    {
      try {
        const quickDeliveryProducts = await generalService.quickDeliveries();
        setQuickDeliveryProducts(quickDeliveryProducts.data.data.quickDeliveries);
      } catch (err) {
        console.log(err);
      }
    }
    getQuickDeliveryProducts();
  }, []);

  useEffect(() =>
  {
    async function getBlogs()
    {
      try {
        const blogs = await blogService.blogs();
        setBlogs(blogs.data.data.blogList);
      } catch (err) {
        console.log(err);
      }
    }
    getBlogs();
  }, []);


  return (
    <Layout>
      <Banner />
      <section className='section'>
        <div className='container-fluid'>
          <div className='ml-3 mb-2'>
            <SectionHeadings sectionHeading='Shop by Category' />
          </div>
          <div className='row'>
            <div className='col-12'>
              {productsByCategory && productsByCategory.length > 0 ? (
                <Slider {...bannerSettings} className='homeSlider1 position-relative'>
                  {productsByCategory.map((eachSection) => (
                    <CategoryCardHome
                      key={eachSection.cat_id}
                      imgUrl={eachSection.cat_img_url}
                      productName={eachSection.cat_name}
                      slugName={eachSection.cat_slug}
                      pageLink={`/shop-by-category/${eachSection.cat_slug}`}
                    />
                  ))}
                </Slider>
              ) : (
                <Skeleton count='6' />
              )}
            </div>
          </div>
        </div>
      </section>

      <section className='section'>
        <div className='container-fluid'>
          <div className='ml-3 mb-2'>
            <SectionHeadings sectionHeading='Most Selling Products' />
          </div>
          <div className='row'>
            <div className='col-12'>
              {mostSellingProducts && mostSellingProducts.length > 0 ? (
                <Slider {...bannerSettings} className='homeSlider1 position-relative'>
                  {mostSellingProducts.map((eachProduct) => (
                    <CategoryCard1
                      key={eachProduct.product_id}
                      imgUrl={eachProduct.product_img}
                      productName={eachProduct.product_name}
                      slugName={eachProduct.product_slug}
                      pageLink={`/${eachProduct.product_slug}/${eachProduct.supplier_id}`}
                    />
                  ))}
                </Slider>
              ) : (
                <Skeleton count='6' />
              )}
            </div>
          </div>
        </div>
      </section>

      <section className='section'>
        <div className='container-fluid'>
          <div className='ml-3 mb-2'>
            <SectionHeadings sectionHeading='New Releases' />
          </div>
          <div className='row'>
            <div className='col-12'>
              {quickDeliveryProducts && quickDeliveryProducts.length > 0 ? (
                <Slider {...bannerSettings} className='homeSlider1 position-relative'>
                  {quickDeliveryProducts.map((eachSection) => (
                    <CategoryCard1
                      key={eachSection.product_id}
                      imgUrl={eachSection.product_img}
                      productName={eachSection.product_name}
                      slugName={eachSection.product_slug}
                      pageLink={`/${eachSection.product_slug}/${eachSection.supplier_id}`}
                    />
                  ))}
                </Slider>
              ) : (
                <Skeleton count='6' />
              )}
            </div>
          </div>
        </div>
      </section>

      <AboutSection
        bgcolor='#bac8cb'
        title='One Stop Online Solution'
        info='Ship Stores and Spares is a one of a kind dedicated marine portal which enables purchase of Ship Stores & Spares with market place experience. Portal presents aggregation of wide range of products under one roof. ‘Stores’ are largely consumables and ‘Spares’ are generally Deck & Engine machinery and equipment as full or part components.'
        btnlink='/about'
        btnname='About Us'
        imgurl={ShippingBoxes}
        bgsize="contain"
      />
      <AboutSection
        alignmenttype='2'
        bgcolor='#FAF3E1'
        title='From Cart To Port'
        info='‘Ship Stores & Spares’ Journey starts, when the Buyers select the products, add to Cart and order through flexible options given to them. After placing the order, supplies are procured from the Warehouse with various levels of quality checks.'
        btnlink='/buyer-services'
        btnname='Read More'
        imgurl={BannerImg}
        bgsize='contain'
      />

      <BuyerSellerSection />
      <Counter />

      <div className='container-fluid'>
        <div className='ml-3 mb-2'>
          <SectionHeadings sectionHeading='Blog Articles' />
        </div>
        <div className='row'>
          <div className='col-12'>
            {blogs && blogs.length > 0 ? (
              <Slider {...blogSliderSettings} className='homeSlider1 position-relative'>
                {blogs.map((eachBlog) => (
                  <BlogSection
                    key={eachBlog.blog_id}
                    blogImageUrl={eachBlog.blog_image}
                    blogImageName={eachBlog.blog_name}
                    blogDate={eachBlog.blog_date}
                    blogTitle={eachBlog.blog_name}
                    blogSlug={eachBlog.blog_slug}
                    blogAuthor={eachBlog.blog_author_name}
                  />
                ))}
              </Slider>
            ) : (
              <Skeleton count='3' />
            )}
          </div>
        </div>
      </div>

      <ContactSection />
      <div className='whatsapp-icon-div'>
        <WhatsAppIconStyle href='https://wa.me/912240220821' target='_blank' rel='noopener noreferrer' className='scroll-top' id='myBtn'>
          {/* <img src={WhatsAppIcon} alt='whatsapp' /> */}
          <i className='fa fa-whatsapp'></i>
          <span>Here to Help!</span>
        </WhatsAppIconStyle>
      </div>
    </Layout>
  );
}

const WhatsAppIconStyle = styled.a`
	bottom: 20px;
	position: fixed;
	right: 0;
	transition: all 0.5s ease-out 0s;
  z-index:5;
`;
const mapStateToProps = (state) =>
{
  return {
    token: state.Auth.token,
  };
};

export default connect(mapStateToProps, null)(Home);
