import * as addressConstants from "store/constants/addressConstants";

export const getAddressStart = () => {
  return {
    type: addressConstants.GET_ADDRESS_START,
  };
};

export const getAddressSuccess = (address) => {
  return {
    type: addressConstants.GET_ADDRESS_SUCCESS,
    address: address,
  };
};

export const getAddressFail = (error) => {
  return {
    type: addressConstants.GET_ADDRESS_FAIL,
    error: error,
  };
};

export const getDefaultsStart = () => {
  return {
    type: addressConstants.GET_DEFAULTS_START,
  };
};

export const getDefaultsSuccess = (defaultAddressId, addressType, defaultPreferredDate) => {
  return {
    type: addressConstants.GET_DEFAULTS_SUCCESS,
    defaultAddressId: defaultAddressId,
    defaultPreferredDate: defaultPreferredDate,
    addressType: addressType,
  };
};

export const getDefaultsFail = (error) => {
  return {
    type: addressConstants.GET_DEFAULTS_FAIL,
    error: error,
  };
};

export const addAddressStart = (formData) => {
  return {
    type: addressConstants.ADD_ADDRESS_START,
    formData,
  };
};

export const addAddressSuccess = (response) => {
  return {
    type: addressConstants.ADD_ADDRESS_SUCCESS,
    response,
  };
};

export const addAddressFail = (error) => {
  return {
    type: addressConstants.ADD_ADDRESS_FAIL,
    error: error,
  };
};

export const editAddressStart = (formData) => {
  return {
    type: addressConstants.EDIT_ADDRESS_START,
    formData,
  };
};

export const editAddressSuccess = (response) => {
  return {
    type: addressConstants.EDIT_ADDRESS_SUCCESS,
    response,
  };
};

export const editAddressFail = (error) => {
  return {
    type: addressConstants.EDIT_ADDRESS_FAIL,
    error: error,
  };
};

export const deleteAddressStart = (addressId) => {
  return {
    type: addressConstants.DELETE_ADDRESS_START,
    addressId,
  };
};

export const deleteAddressSuccess = (response) => {
  return {
    type: addressConstants.DELETE_ADDRESS_SUCCESS,
    response,
  };
};

export const deleteAddressFail = (error) => {
  return {
    type: addressConstants.DELETE_ADDRESS_FAIL,
    error: error,
  };
};

export const getEditAddressStart = (addressId) => {
  return {
    type: addressConstants.GET_EDIT_ADDRESS_START,
    addressId,
  };
};

export const getEditAddressSuccess = (address) => {
  return {
    type: addressConstants.GET_EDIT_ADDRESS_SUCCESS,
    address: address,
  };
};

export const getEditAddressFail = (error) => {
  return {
    type: addressConstants.GET_EDIT_ADDRESS_FAIL,
    error: error,
  };
};

export const addressResponseReset = () => {
  return {
    type: addressConstants.ADDRESS_RESET,
  };
};
