import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import SectionHeadings from "component/Utilities/SectionHeadings";

function AboutSection(props) {
  /* alignmentType : 2 ==> image on the right side and text on left, 
   if not mentioned ==> image on left side and text on right*/

  return (
    <div className="container-fluid mt-4 my-5">
      <div className="row">
        <ImageContainer className="col-lg-6 col-md-6 col-sm-6 col-12" {...props}>
          <ImageDiv className="imageBg" {...props}></ImageDiv>
        </ImageContainer>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <ContentContainer className="text-left" {...props}>
            <SectionHeadings sectionHeading={props.title} />
            <p className="text">{props.info}</p>
            <div className="text-right">
              <NavLink to={props.btnlink}>
                <button className="btn bordered-btn"> {props.btnname} </button>
              </NavLink>
            </div>
          </ContentContainer>
        </div>
      </div>
    </div>
  );
}

const ImageDiv = styled.div`
  background: ${(props) => `url(${props.imgurl})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(props) => (props.alignmenttype === "2" ? "contain" : "cover")};
  max-width: 100%;
  height: 100%;
  @media only screen and (max-width: 576px) {
  background-size: ${(props) => (props.alignmenttype === "2" ? "contain" : "cover" || props.bgsize === '2' ? 'contain' : 'cover' )};
  }
`;
const ImageContainer = styled.div`
  order: ${(props) => props.alignmenttype === "2" && 2};
  @media only screen and (max-width: 576px) {
    height: 230px;
    margin-bottom: 15px;
  }
`;

const ContentContainer = styled.div`
  background: ${(props) => props.bgcolor} 0% 0% no-repeat padding-box;
  padding: 2.5rem 1.2rem 2.5rem 1.2rem;
`;
export default React.memo(AboutSection);
