import axios from "axios";
import * as Config from "../config/config";
var FormData = require("form-data");

export const UserLogin = (userName, password, loginType) => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  data.append("u_email", userName);
  data.append("u_password", password);
  data.append("u_type", loginType);
  return axios.post(Config.API_URL + "buyer_api/buyer_login", data);
};

export const UserLogout = () => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("token", token);
  data.append("Appkey", Config.APP_KEY);
  return axios.post(Config.API_URL + "buyer_api/logout", data);
};

export const SendOtpService = (userName) => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  data.append("token", "void");
  data.append("action", "get_otp");
  data.append("email", userName);
  return axios.post(Config.API_URL + "buyer_api/buyer_forgot_password", data);
};

export const ForgotPasswordService = (userId, otp, newPassword) => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  data.append("token", "void");
  data.append("action", "reset_password");
  data.append("user_id", userId);
  data.append("otp", otp);
  data.append("new_pass", newPassword);
  return axios.post(Config.API_URL + "buyer_api/buyer_forgot_password", data);
};

export const ChangePasswordService = (oldPassword, newPassword) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("old_pass", oldPassword);
  data.append("new_pass", newPassword);
  return axios.post(Config.API_URL + "buyer_api/buyer_change_password", data);
};
