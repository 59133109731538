import * as orderConstants from "store/constants/orderConstants";
import { updateObject } from "shared/utility";

const initialState = {
  error: null,
  loading: false,
  billing: null,
  response: null,
};

const changeOrderStatusStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const changeOrderStatusSuccess = (state, action) => {
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const changeOrderStatusFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const userBillingDetailsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const userBillingDetailsSuccess = (state, action) => {
  return updateObject(state, {
    billing: action.billing,
    error: null,
    loading: false,
  });
};

const userBillingDetailsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const billingByOrderIdStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const billingByOrderIdSuccess = (state, action) => {
  return updateObject(state, {
    billing: action.billing,
    error: null,
    loading: false,
  });
};

const billingByOrderIdFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
const orderStatusReset = (state, action) => {
  return updateObject(state, {
    error: null,
    response: null,
    loading: false,
  });
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case orderConstants.CHANGE_ORDER_STATUS_START:
      return changeOrderStatusStart(state, action);
    case orderConstants.CHANGE_ORDER_STATUS_SUCCESS:
      return changeOrderStatusSuccess(state, action);
    case orderConstants.CHANGE_ORDER_STATUS_FAIL:
      return changeOrderStatusFail(state, action);

    case orderConstants.GET_USER_BILLING_DETAILS_START:
      return userBillingDetailsStart(state, action);
    case orderConstants.GET_USER_BILLING_DETAILS_SUCCESS:
      return userBillingDetailsSuccess(state, action);
    case orderConstants.GET_USER_BILLING_DETAILS_FAIL:
      return userBillingDetailsFail(state, action);

    case orderConstants.BILLING_BY_ORDER_ID_START:
      return billingByOrderIdStart(state, action);
    case orderConstants.BILLING_BY_ORDER_ID_SUCCESS:
      return billingByOrderIdSuccess(state, action);
    case orderConstants.BILLING_BY_ORDER_ID_FAIL:
      return billingByOrderIdFail(state, action);

    case orderConstants.ORDER_STATUS_RESET:
      return orderStatusReset(state, action);

    default:
      return state;
  }
};
