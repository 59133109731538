export const API_URL =
  process.env.NODE_ENV === "production" ? "https://api.shipstorespares.com/index.php/" : "https://api.shipstorespares.com/index.php/";
export const CATCH_ERROR = "Some error occured while processing your request, Please try again!";
export const STORAGE_NAME = process.env.NODE_ENV === "production" ? "ssson" : "sssln";
export const STORAGE_TOKEN = process.env.NODE_ENV === "production" ? "sssot" : "ssslt";
export const COMPANY_NAME = process.env.NODE_ENV === "production" ? "sssoc" : "ssslc";
export const STORAGE_USER_TYPE = process.env.NODE_ENV === "production" ? "sssout" : "ssslut";
export const APP_KEY = "sss3!@#project%^&*buyer987!!sum@n@apis";
export const GOOGLE_SIGN_UP = "356304756718-6rv0o9iurlpjdgr30a59613ncolucrap.apps.googleusercontent.com";
export const PROJECT_PATH = "http://localhost:3000/";
