import React from 'react';
import { Helmet } from 'react-helmet';

export const SEO = ({ title, description, imgUrl }) =>
{
  return (
    <Helmet>
      <title>{title ? title : 'Ship Stores Spares'}</title>
      <meta
        name='description'
        content={
          description
            ? description
            : 'Ship Stores and Spares is a one of a kind dedicated marine portal which enables purchase of Ship Stores & Spares with market place experience'
        }
      />

      <meta property='name' content={title ? title : 'Ship Stores Spares'} />

      <meta
      property='description'
        content={
          description
            ? description
            : 'Ship Stores and Spares is a one of a kind dedicated marine portal which enables purchase of Ship Stores & Spares with market place experience'
        }
      />
      <meta property='og:image' content={imgUrl ? imgUrl : 'http://shipstorespares.com/static/media/cart-to-port.aa9cf1bb.png'} />
      <meta property='og:image:width' content='300' />
      <meta property='og:image:height' content='300' />

      <meta property='og:url' content={window.location.href} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title ? title : 'Ship Stores Spares'} />

      <meta
        property='og:description'
        content={
          description
            ? description
            : 'Ship Stores and Spares is a one of a kind dedicated marine portal which enables purchase of Ship Stores & Spares with market place experience'
        }
      />
      <meta property='og:image' content={imgUrl ? imgUrl : 'http://shipstorespares.com/static/media/cart-to-port.aa9cf1bb.png'} />

      <meta name='twitter:card' content={title ? title : 'Ship Stores Spares'} />
      <meta name='twitter:title' content={title ? title : 'Ship Stores Spares'} />
      <meta
        name='twitter:description'
        content={
          description
            ? description
            : 'Ship Stores and Spares is a one of a kind dedicated marine portal which enables purchase of Ship Stores & Spares with market place experience'
        }
      />
      <meta name='twitter:image' content={imgUrl ? imgUrl : 'http://shipstorespares.com/static/media/cart-to-port.aa9cf1bb.png'} />
    </Helmet>
  );
};
