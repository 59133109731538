import React from "react";

export default function Pagination(props) {
  const totalPages = Math.ceil(props.total_product / props.limit)
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(props.total_product / props.limit); i++) {
    pageNumbers.push(i);
  }
  const getPaginationGroup = () => {
    const totalPages = parseInt(props.total_product / props.limit);
    const nextPages = totalPages - props.offset;

    if (props.offset < 3) {
      return new Array(5).fill().map((_, idx) => props.offset + idx);
    } else if (props.offset === totalPages + 1) {
      return new Array(5)
        .fill()
        .map((_, idx) => props.offset - idx)
        .reverse();
    } else {
      const prev = new Array(nextPages < 1 ? 4 : 3)
        .fill()
        .map((_, idx) => props.offset - idx)
        .reverse();
      const next = new Array(nextPages < 1 ? 1 : 2).fill().map((_, idx) => props.offset + idx + 1);
      return [...prev, ...next];
    }
  };

  return (
    <div className="col-lg-5 col-sm-12 col-md-5 pr-0">
      <nav>
        <ul className="pagination d-flex pl-0 list-style-none justify-content-end ">
          {pageNumbers.length > 2 && (
            <li className={`page-item ${props.offset > 1 && "active"}`}>
              <button className="page-link" tabIndex="-1" onClick={props.onPreviousPageHandler} disabled={props.offset === 1}>
                <i className="fas fa-angle-left"></i>
              </button>
            </li>
          )}
         {pageNumbers.length > 1 && (pageNumbers.length > 3 ?
            getPaginationGroup().map((eachPage) => (
              <li className={`page-item ${props.offset === eachPage ? "active" :""}`} key={eachPage}>
                <span className="page-link" onClick={() => props.onPageChangeHandler(eachPage)}>
                  {eachPage}
                </span>
              </li>
            )): pageNumbers.map(eachPage => (
              <li className={`page-item ${props.offset === eachPage ? "active" :""}`} key={eachPage}>
                <span className="page-link" onClick={() => props.onPageChangeHandler(eachPage)}>
                  {eachPage}
                </span>
              </li>
            )))}

          {pageNumbers.length > 2 && (
            <li className={`page-item ${props.offset < totalPages && "active"}`}>
              <button className="page-link" tabIndex="-1" onClick={props.onNextPageHandler} disabled={props.offset === totalPages}>
                <i className="fas fa-angle-right"></i>
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}
