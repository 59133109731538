import React from "react";
import Modal from "react-bootstrap4-modal";
import { useHistory } from "react-router";

export default function ConfirmationPrompt(props) {
  const history = useHistory();

  const onActionHandler = (e) => {
    if (e.target.id === "ok") {
      props.closeModal();
      props.type === "buy_now"
        ? history.push({
            pathname: `/user-profile/profile-details`,
            state: { type: props.type },
          })
        : history.push("/user-profile/profile-details");
    } else {
      props.closeModal();
    }
  };
  return (
    <Modal visible={props.showModal} onClickBackdrop={props.closeModal}>
      <div className="modal-header border-0">
        <div className="text-center w-100">
          <i className="fa fa-exclamation-circle fa-6x" style={{ color: "#304855" }} aria-hidden="true"></i>
        </div>
        <button type="button" className="close">
          <span onClick={props.closeModal}>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h5 className="text-center">{props.title}</h5>
        <div className="d-flex w-100 pt-3">
          <button className="btn bordered-btn ml-2 flex-grow-1 text-center" onClick={onActionHandler} id="cancel">
            CANCEL
          </button>
          <button className="button mr-2 flex-grow-1 text-center" onClick={onActionHandler} id="ok">
            OKAY
          </button>
        </div>
      </div>
    </Modal>
  );
}
