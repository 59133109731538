import { color } from "GlobalStyles";
import React from "react";
import { NavLink } from "react-router-dom";

export default function NoItems(props)
{
  return (
    <div className={`${props.center} ${props.remove_margin} mb-5 ml-3`}>
      <h3
        className={`${props.text_style}  text-capitalize`}
        style={{
          font: "normal normal 500 27px/36px Ubuntu",
          color: `${color.colorDarkGrey}`,
          marginBottom: ".5rem",
        }}
      >
        {props.title}
      </h3>
      <NavLink to="/">
        <h5 className="font-weight-bold text-primary">{props.content}</h5>
      </NavLink>
    </div>
  );
}
