import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { color } from "GlobalStyles";
import Layout from "component/Layout/Layout";
import ProductCard from "component/Products/ProductCard";
import * as productActions from "store/actions/productActions";
import { connect } from "react-redux";
// import CommonSliders from "component/CommonSliders/CommonSliders";
import FilterProducts from "component/Products/FilterProducts";
import Pagination from "component/Utilities/Pagination/Pagination";
import NoItems from "component/Utilities/NoItems";
import SectionHeadings from "component/Utilities/SectionHeadings";
import * as generalService from "service/generalService";

const HeaderPages = (props) => {
  const [sortDay, setSortDay] = useState(props.setSortDay ? props.setSortDay : "");
  const [sortPort, setSortPort] = useState(props.sortPort ? props.sortPort : "");
  const [sortType, setSortType] = useState(props.sortType ? props.sortType : "");
  const [limit] = useState(props.limit ? props.limit : 12);
  const [offset, setOffset] = useState(props.offset ? props.offset : 1);
  const [startIndex, setStartIndex] = useState(props.startIndex ? props.startIndex : 1);
  const [endIndex, setEndIndex] = useState(props.endIndex ? props.endIndex : "");
  const [productList, setProductList] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState("");
  const [preferredDeliveryDate, setPreferredDeliveryDate] = useState("");
  const [currentPath, setCurrentPath] = useState(props.currentPath ? props.currentPath : "");
  const [portList, setPortList] = useState(props.portList ? props.portList : null);

  const {
    onGetProducts, onSaveProductData, productsByPage, response, onProductsReset, loading, loadingProducts, defaultAddressId, defaultPreferredDate, } = props;

  const [catId, setCatId] = useState("");
  const [catType, setCatType] = useState("");

  const [subCatId, setSubCatId] = useState("");
  const [brand, setBrand] = useState([]);
  const [priceFilterArr, setPriceFilterArr] = useState([]);
  // const [min, setMin] = useState(priceFilterArr && priceFilterArr.length > 0 ? parseInt(priceFilterArr[0]) : 0);
  // const [max, setMax] = useState(priceFilterArr && priceFilterArr.length > 0 ? parseInt(priceFilterArr[1]) : 500000);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [ratingVal, setRatingVal] = useState('');
  const [newArrival, setNewArrival] = useState('');
  const [newArrivalFilterArr, setNewArrivalFilterArr] = useState([]);
  const [isOpened, setIsOpened] = useState(true);
  // let minDefault = priceFilterArr && priceFilterArr.length > 0 ? parseInt(priceFilterArr[0]) : 0;
  // let maxDefault = priceFilterArr && priceFilterArr.length > 0 ? parseInt(priceFilterArr[1]) : 500000;
  useEffect(() => {
    if (currentPath !== props.match.path) {
      if (
        props.match.path === "/best-sellers" ||
        props.match.path === "/new-releases" ||
        props.match.path === "/top-offers" ||
        props.match.path === "/quick-deliveries" ||
        props.match.path === "/search-product/:type/:search"
      ) {
        resetSelection();
        setCurrentPath(props.match.path);
        setCatId('');
        setSubCatId('');
        setBrand([]);
        setMin(min);
        setMax(max);
        setRatingVal('');
        setNewArrival('');
      }
    }
  }, [props.match.path, catId, props.match.params.search, currentPath, min, max]);

  useEffect(() => {
    if (props.location.state && props.location.state.catId) {
      props.location.state.catType === 'category' ? setCatId(props.location.state.catId) : setSubCatId(props.location.state.catId);
      setCatType(props.location.state.catType);
      window.history.replaceState({}, document.title);
    }
  }, [props.location]);

  useEffect(() => {
    async function getPortList() {
      try {
        const portListResponse = await generalService.portList();
        setPortList(portListResponse.data.data);
      } catch (err) {
        console.log(err);
      }
    }
    getPortList();
  }, [])


  useEffect(() => {
    if (currentPath === "/best-sellers") {
      onGetProducts("", catId, "", "best_selling", limit, offset, sortType, defaultAddress, preferredDeliveryDate, subCatId, brand, min, max, ratingVal, newArrival);
    } else if (currentPath === "/new-releases") {
      onGetProducts("", catId, "", "new_release", limit, offset, sortType, defaultAddress, preferredDeliveryDate, subCatId, brand, min, max, ratingVal, newArrival);
    } else if (currentPath === "/top-offers") {
      onGetProducts("", catId, "", "top_offers", limit, offset, sortType, defaultAddress, preferredDeliveryDate, subCatId, brand, min, max, ratingVal, newArrival);
    } else if (currentPath === "/quick-deliveries") {
      if (sortDay !== "" && sortPort !== "") {
        onGetProducts("", catId, "", "quick_deliveries", limit, offset, sortType, defaultAddress, preferredDeliveryDate, subCatId, brand, min, max, ratingVal, newArrival, sortDay, sortPort);
      }
      else {
        setProductList([])
      }
    } else if (currentPath === "/search-product/:type/:search") {
      onGetProducts(
        catType,
        catId,
        props.match.params.search,
        "search_prod",
        limit,
        offset,
        sortType,
        defaultAddress,
        preferredDeliveryDate,
        subCatId,
        brand,
        min,
        max,
        ratingVal,
        newArrival
      );

    }
    // return () => {
    //   onProductsReset();
    // };
  }, [
    sortType,
    limit,
    offset,
    currentPath,
    response,
    props.match.params.search,
    catId,
    catType,
    onGetProducts,
    onProductsReset,
    defaultAddress,
    preferredDeliveryDate,
    subCatId,
    brand,
    min,
    max,
    ratingVal,
    newArrival,
    sortDay,
    sortPort
  ]);

  useEffect(() => {
    onSaveProductData(startIndex, endIndex, offset, limit, sortType, currentPath);
  }, [startIndex, endIndex, limit, offset, sortType, onSaveProductData, currentPath]);

  useEffect(() => {
    if (productsByPage) {
      setProductList(productsByPage);
    } else {
      setProductList([]);
    }
  }, [productsByPage]);

  useEffect(() => {
    if (defaultAddressId && defaultPreferredDate) {
      setDefaultAddress(defaultAddressId);
      setPreferredDeliveryDate(defaultPreferredDate);
    } else {
      setDefaultAddress("");
      setPreferredDeliveryDate("");
    }
  }, [defaultAddressId, defaultPreferredDate]);



  useEffect(() => {
    async function getPriceFilter() {
      try {
        const priceListResponse = await generalService.priceList();
        setPriceFilterArr(priceListResponse.data.data);
        setMin(priceListResponse.data.data && priceListResponse.data.data[0])
        setMax(priceListResponse.data.data && priceListResponse.data.data[1])
      } catch (err) {
        console.log(err);
      }
    }
    getPriceFilter();


    async function getNewArricalFilter() {
      try {
        const priceListResponse = await generalService.newArrivalFilterList();
        setNewArrivalFilterArr(priceListResponse.data.data);
      } catch (err) {
        console.log(err);
      }
    }
    getNewArricalFilter();
  }, []);



  const pageChange = (pageNumber) => {
    const end =
      (pageNumber - 1) * limit + 1 + limit > (productList.pages && productList.pages.total_product)
        ? productList.pages && productList.pages.total_product
        : (pageNumber - 1) * limit + limit;
    setOffset(pageNumber);
    setStartIndex((pageNumber - 1) * limit + 1);
    setEndIndex(end);
    window.scrollTo(0, 0);
  };

  const onPreviousPageHandler = () => {
    let end;
    if (productList.pages && productList.pages.prev_page < 1) end = productList.pages && productList.pages.prev_page * limit;
    else end = 1 * limit;
    setStartIndex((offset - 1) * limit - limit + 1);
    setEndIndex(end);
    setOffset(offset - 1);
    window.scrollTo(0, 0);
  };

  const onNextPageHandler = () => {
    const end =
      productList.pages && productList.pages.next_page * limit > productList.pages.total_product
        ? productList.pages.total_product
        : productList.pages.next_page * limit;
    setStartIndex((offset + 1) * limit - limit + 1);
    setEndIndex(end);
    setOffset(offset + 1);
    window.scrollTo(0, 0);
  };

  const resetSelection = () => {
    setOffset(1);
    setStartIndex(1);
    setEndIndex("");
    setSortType("");
  };

  const sortProducts = (e, type) => {
    type === "Type" && setSortType(e.target.value);
    type === "Day" && setSortDay(e.target.value);
    type === "Port" && setSortPort(e.target.value);
    setOffset(1);
    setStartIndex(1);
    setEndIndex("");
  };

  // function toggle() {
  //   setIsOpened(wasOpened => !wasOpened);
  // }

  useEffect(() => {
    if (window.innerWidth < 767) {
      setIsOpened(false);
    }

    // const updateMedia = () => {
    //   if (window.innerWidth > 767) {
    //     setIsOpened(true);
    //   } else {
    //     setIsOpened(false);
    //   }
    // };
    // window.addEventListener('resize', updateMedia);
    // return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <Layout>
      <section className="container-fluid section pt-0">
        <div className="row">
          <FilterProductsContainer className="col-lg-2 col-md-3 p-0 mb-3">
            {isOpened && (
              <FilterProducts
                category={catId}
                setCategory={setCatId}
                subCategory={subCatId}
                setSubCategory={setSubCatId}
                brand={brand}
                setBrand={setBrand}
                min={min}
                setMin={setMin}
                max={max}
                setMax={setMax}
                ratingVal={ratingVal}
                setRatingVal={setRatingVal}
                newArrival={newArrival}
                setNewArrival={setNewArrival}
                priceFilterArr={priceFilterArr}
                newArrivalFilterArr={newArrivalFilterArr}
                catType={catType}
              />
            )}
          </FilterProductsContainer>
          <div className="col-lg-10 col-md-3 mb-3 pt-4">
            <ProductAction className="row">
              <div className="col-12 d-block d-sm-none d-md-none d-lg-none pb-4 text-right">
                {/* {isOpened ? <button  className="mr-2" onClick={!isOpened}>Close</button>: null} */}
                <button onClick={() => setIsOpened(!isOpened)}>{isOpened ? 'Close' : 'Filter'}</button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                {currentPath === "/top-offers" ? (
                  <SectionHeadings sectionHeading="Top Offers" />
                ) : currentPath === "/best-sellers" ? (
                  <SectionHeadings sectionHeading="Best Sellers" />
                ) : currentPath === "/new-releases" ? (
                  <SectionHeadings sectionHeading="New Releases" />
                ) : currentPath === "/quick-deliveries" ? (
                  <SectionHeadings sectionHeading="Quick Deliveries" />
                ) : (
                  currentPath === "/search-product/:type/:search" && (
                    <>
                      <h4 className="font-weight-bold text d-none d-md-block">
                        You searched for "{props.match.params.search}" in "
                        {props.match.params.type !== "Select" ? props.match.params.type : "all"}" category
                      </h4>
                      <h4 className="font-weight-bold text d-md-none">You searched for "{props.match.params.search}"</h4>
                    </>
                  )
                )}
                {productList.pages && productList.product && (
                  <p className="products text">
                    {startIndex} -{" "}
                    {endIndex
                      ? endIndex
                      : limit > (productList.pages && productList.pages.total_product)
                        ? productList.pages.total_product
                        : limit}{" "}
                    out of {productList.pages && productList.pages.total_product} Products
                  </p>
                )}
              </div>

              <div className="col-lg-8 d-flex justify-content-end flex-wrap p-0">
                {currentPath === '/quick-deliveries' &&
                  (<>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <select
                        className="form-control select-popularity cursor-pointer"
                        name="sortPort"
                        onChange={(e, type) => sortProducts(e, "Port")}
                        value={sortPort}
                      >
                        <option value="">Select Port</option>
                        {
                          portList && portList.map((eachPort) => {
                            return <option key={eachPort.port_id} value={eachPort.port_id}>{eachPort.port_name}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <select
                        className="form-control select-popularity cursor-pointer"
                        name="sortDay"
                        onChange={(e, type) => sortProducts(e, "Day")}
                        value={sortDay}
                      >
                        <option value="">No. of Days</option>
                        <option value="2">2 Days</option>
                        <option value="3">3 Days</option>
                        <option value="4">4 Days</option>
                      </select>
                    </div>
                  </>)}

                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  {productList && (
                    <select
                      className="form-control select-popularity cursor-pointer"
                      name="sortType"
                      onChange={(e, type) => sortProducts(e, "Type")}
                      value={sortType}
                    >
                      <option value="">Sort By</option>
                      <option value="1">Sort By: High to Low</option>
                      <option value="2">Sort By: Low to High</option>
                    </select>
                  )}
                </div>
              </div>






            </ProductAction>

            <div className="row">
              {!loadingProducts ? (
                productList && productList.product && productList.product.length > 0 ? (
                  productList.product.map((eachProduct, index) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                      <ProductCard
                        productId={eachProduct.product_id}
                        slugName={eachProduct.p_slug_name}
                        productImageUrl={`${eachProduct.image.path}`}
                        productImageName={eachProduct.image.img}
                        productName={eachProduct.p_product_name}
                        productAvgRating={eachProduct.avg_rating}
                        productRating={eachProduct.rating}
                        // productActualPrice={eachProduct.selling_price}
                        productDiscountedPrice={eachProduct.sss_net_price}
                        productDiscountPercent={eachProduct.discount_percent}
                        supplierId={eachProduct.supplier_id}
                        isCart={eachProduct.isCart}
                        isWishListed={eachProduct.isWishListed}
                        wishlist_id={eachProduct.wishListId}
                        ports={eachProduct.ports}
                        loading={loading}
                        pageLink={`/${eachProduct.p_slug_name}/${eachProduct.supplier_id}`}
                      />
                    </div>
                  ))
                ) : (
                  <NoItems text_style="title_style" title={(sortDay === "" || sortPort === "") && currentPath === "/quick-deliveries" ? "To view Products under Quick Deliveries, please 'Select Port' and 'No. of Days' criteria" : "No Products Found"} content="Go to home page" />
                )
              ) : (
                Array(8)
                  .fill()
                  .map((_, index) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                      <ProductCard
                        productId=""
                        slugName=""
                        productImageUrl=""
                        productImageName=""
                        productName=""
                        productRating=""
                        // productActualPrice=""
                        productDiscountedPrice=""
                        productDiscountPercent=""
                        supplierId=""
                        isCart=""
                        isWishListed=""
                        wishlist_id=""
                        ports=""
                      />
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
        <hr className="hr2" />
        <PaginationContainer className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-sm-12 col-md-7 pl-0">
              {productList.pages && productList.product && (
                <p className="products">
                  {startIndex} -{" "}
                  {endIndex
                    ? endIndex
                    : limit > (productList.pages && productList.pages.total_product)
                      ? productList.pages.total_product
                      : limit}{" "}
                  out of {productList.pages && productList.pages.total_product} Products
                </p>
              )}
            </div>

            <Pagination
              total_product={productList.pages && productList.pages.total_product}
              limit={limit}
              offset={offset}
              onPageChangeHandler={pageChange}
              onPreviousPageHandler={onPreviousPageHandler}
              onNextPageHandler={onNextPageHandler}
            />
          </div>
        </PaginationContainer>
      </section>

      {/* <CommonSliders /> */}
    </Layout>
  );
};

const FilterProductsContainer = styled.div`
  background-color: ${color.colorGrey};
`;

const ProductAction = styled.div`
  .products {
    font: normal normal 600 1rem/22px Nunito;
    color: ${color.colorBlack};
  }

  .select-popularity {
    border: 2px solid ${color.colorPrimary} !important;
    font: normal normal 600 17px/0 Nunito !important;
    color: ${color.colorPrimary} !important;
    background: ${color.colorWhite} !important;
    float: right;
    padding: 0.5rem !important;

    @media (max-width: 767px){
      margin-bottom: 40px;
    }
  }

  .form-control:focus {
    color: ${color.colorPrimary};
    background-color: ${color.colorWhite};
    border-color: ${color.colorPrimary};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
  }
`;

const PaginationContainer = styled.div`
  .products {
    font: normal normal 600 1rem/1.375rem Nunito;
    color: ${color.colorBlack};
    padding-top: 10px;
  }
  .pagination {
    border-radius: 0.25px;
  }
  .page-item .page-link {
    margin-right: 0.625rem;
    border: 2px solid ${color.colorSmallText};
    border-radius: 8px;
    color: ${color.colorSmallText};
    cursor: pointer;
    font: normal normal 600 1.25rem/1.25rem Nunito;
    background-color: ${color.colorWhite};
    padding: 0.5rem 0.65rem !important;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .page-item.active .page-link {
    border: 2px solid ${color.colorPrimary};
    border-radius: 8px;
    margin-right: 10px;
    background: transparent;
    color: ${color.colorPrimary};
    text-align: center;
    font: normal normal 600 1rem/1.25rem Nunito;
  }
`;

const mapStateToProps = (state) => {
  return {
    serverError: state.User.error,
    response: state.User.response,
    productsByPage: state.Product.products,
    loading: state.User.loading,
    offset: state.Product.offset,
    limit: state.Product.limit,
    startIndex: state.Product.startIndex,
    endIndex: state.Product.endIndex,
    currentPath: state.Product.currentPath,
    sortType: state.Product.sortType,
    loadingProducts: state.Product.loading,
    defaultAddressId: state.Address.defaultAddressId,
    defaultPreferredDate: state.Address.defaultPreferredDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProducts: (catType, categoryId, search_key, pageName, limit, offset, sortType, defaultAddress, preferredDeliveryDate, subCatId, brand, min, max, ratingVal, newArrival, sortDay, sortPort) =>
      dispatch(
        productActions.getProductsByPageNameStart(
          catType,
          categoryId,
          search_key,
          pageName,
          limit,
          offset,
          sortType,
          defaultAddress,
          preferredDeliveryDate,
          subCatId,
          brand,
          min,
          max,
          ratingVal,
          newArrival,
          sortDay,
          sortPort
        )
      ),
    onSaveProductData: (startIndex, endIndex, offset, limit, sortType, currentPath) =>
      dispatch(productActions.getProductData(startIndex, endIndex, offset, limit, sortType, currentPath)),
    onProductsReset: () => dispatch(productActions.productsReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPages);
