import * as addressConstants from "store/constants/addressConstants";
import { updateObject } from "shared/utility";

const initialState = {
  error: null,
  loading: false,
  response: null,
  address: [],
  addressData: {},
  defaultAddressId: null,
  defaultPreferredDate: null,
  addressType: null,
};

const getAddressStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getAddressSuccess = (state, action) => {
  return updateObject(state, {
    address: action.address,
    error: null,
    loading: false,
  });
};

const getAddressFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getDefaultsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getDefaultsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    defaultAddressId: action.defaultAddressId,
    defaultPreferredDate: action.defaultPreferredDate,
    addressType: action.addressType,
  });
};

const getDefaultsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    defaultAddressId: null,
    defaultPreferredDate: null,
    addressType: null,
  });
};

const addAddressStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const addAddressSuccess = (state, action) => {
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const addAddressFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const editAddressStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const editAddressSuccess = (state, action) => {
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const editAddressFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const deleteAddressStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const deleteAddressSuccess = (state, action) => {
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const deleteAddressFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getEditAddressStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, response: null });
};

const getEditAddressSuccess = (state, action) => {
  return updateObject(state, {
    addressData: action.address,
    error: null,
    loading: false,
  });
};

const getEditAddressFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const addressResponseReset = (state, action) => {
  return updateObject(state, {
    error: null,
    response: null,
    loading: false,
    // addressType: null,
  });
};

export const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case addressConstants.GET_ADDRESS_START:
      return getAddressStart(state, action);
    case addressConstants.GET_ADDRESS_SUCCESS:
      return getAddressSuccess(state, action);
    case addressConstants.GET_ADDRESS_FAIL:
      return getAddressFail(state, action);

    case addressConstants.GET_DEFAULTS_START:
      return getDefaultsStart(state, action);
    case addressConstants.GET_DEFAULTS_SUCCESS:
      return getDefaultsSuccess(state, action);
    case addressConstants.GET_DEFAULTS_FAIL:
      return getDefaultsFail(state, action);

    case addressConstants.ADD_ADDRESS_START:
      return addAddressStart(state, action);
    case addressConstants.ADD_ADDRESS_SUCCESS:
      return addAddressSuccess(state, action);
    case addressConstants.ADD_ADDRESS_FAIL:
      return addAddressFail(state, action);

    case addressConstants.EDIT_ADDRESS_START:
      return editAddressStart(state, action);
    case addressConstants.EDIT_ADDRESS_SUCCESS:
      return editAddressSuccess(state, action);
    case addressConstants.EDIT_ADDRESS_FAIL:
      return editAddressFail(state, action);

    case addressConstants.DELETE_ADDRESS_START:
      return deleteAddressStart(state, action);
    case addressConstants.DELETE_ADDRESS_SUCCESS:
      return deleteAddressSuccess(state, action);
    case addressConstants.DELETE_ADDRESS_FAIL:
      return deleteAddressFail(state, action);

    case addressConstants.GET_EDIT_ADDRESS_START:
      return getEditAddressStart(state, action);
    case addressConstants.GET_EDIT_ADDRESS_SUCCESS:
      return getEditAddressSuccess(state, action);
    case addressConstants.GET_EDIT_ADDRESS_FAIL:
      return getEditAddressFail(state, action);

    case addressConstants.ADDRESS_RESET:
      return addressResponseReset(state, action);

    default:
      return state;
  }
};
