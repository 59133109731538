import { call, put, takeLatest } from "redux-saga/effects";
import * as addressConstants from "store/constants/addressConstants";
import * as addressActions from "store/actions/addressActions";
import * as addressService from "service/addressService";
import { CATCH_ERROR } from "config/config";

export function* GetAllAddressSaga() {
  try {
    const response = yield call(addressService.GetAllAddress);
    yield put(addressActions.getAddressSuccess(response.data.data.addresses));
  } catch (error) {
    if (error.response) {
      yield put(addressActions.getAddressFail(error.response.data.msg));
    } else {
      yield put(addressActions.getAddressFail(CATCH_ERROR));
    }
  }
}

export function* GetAddressAndDateOfDeliverySaga() {
  try {
    const response = yield call(addressService.getAddressAndDateOfDelivery);
    yield put(
      addressActions.getDefaultsSuccess(
        response.data.data.default_address.am_id,
        response.data.data.default_address,
        response.data.data.default_address.am_pref_delivry_date
      )
    );
  } catch (error) {
    if (error.response) {
      yield put(addressActions.getDefaultsFail(error.response.data.msg));
    } else {
      yield put(addressActions.getDefaultsFail(CATCH_ERROR));
    }
  }
}

export function* AddAddressSaga(action) {
  try {
    const response = yield call(addressService.AddAddress, action.formData);
    yield put(addressActions.addAddressSuccess(response.data.msg));
    yield put(addressActions.getAddressStart());
  } catch (error) {
    if (error.response) {
      yield put(addressActions.addAddressFail(error.response.data.msg));
    } else {
      yield put(addressActions.addAddressFail(CATCH_ERROR));
    }
  }
}

export function* DeleteAddressSaga(action) {
  try {
    const response = yield call(addressService.DeleteAddress, action.addressId);
    yield put(addressActions.deleteAddressSuccess(response.data.msg));
    yield put(addressActions.getAddressStart());
  } catch (error) {
    if (error.response) {
      yield put(addressActions.deleteAddressFail(error.response.data.msg));
    } else {
      yield put(addressActions.deleteAddressFail(CATCH_ERROR));
    }
  }
}

export function* EditAddressSaga(action) {
  try {
    const response = yield call(addressService.EditAddress, action.formData);
    yield put(addressActions.editAddressSuccess(response.data.msg));
    yield put(addressActions.getAddressStart());
  } catch (error) {
    if (error.response) {
      yield put(addressActions.editAddressFail(error.response.data.msg));
    } else {
      yield put(addressActions.editAddressFail(CATCH_ERROR));
    }
  }
}

export function* GetEditAddressSaga(action) {
  try {
    const response = yield call(addressService.GetEditAddress, action.addressId);
    yield put(addressActions.getEditAddressSuccess(response.data.data));
  } catch (error) {
    if (error.response) {
      yield put(addressActions.getEditAddressFail(error.response.data.msg));
    } else {
      yield put(addressActions.getEditAddressFail(CATCH_ERROR));
    }
  }
}

export function* addressFlowWatcher() {
  yield takeLatest(addressConstants.GET_ADDRESS_START, GetAllAddressSaga);
  yield takeLatest(addressConstants.GET_DEFAULTS_START, GetAddressAndDateOfDeliverySaga);
  yield takeLatest(addressConstants.DELETE_ADDRESS_START, DeleteAddressSaga);
  yield takeLatest(addressConstants.ADD_ADDRESS_START, AddAddressSaga);
  yield takeLatest(addressConstants.GET_EDIT_ADDRESS_START, GetEditAddressSaga);
  yield takeLatest(addressConstants.EDIT_ADDRESS_START, EditAddressSaga);
}
