import axios from "axios";
import * as Config from "../config/config";
var FormData = require("form-data");

export const CreateAccount = (formdata) =>
{
  var data = new FormData();
  let store_list = formdata.storeList.join();
  let ports = formdata.mostVisitedPort.join();

  data.append("utype", formdata.registrationType);
  // data.append("utitle", formdata.select_type);
  data.append("fname", formdata.firstName);
  data.append("lname", formdata.lastName);
  data.append("comp_name", formdata.companyName ? formdata.companyName : "");
  data.append("mobile", formdata.mobileNumber);
  data.append("emailid", formdata.email);

  data.append("postalcode", formdata.postalCode);
  data.append(
    "service_type",
    formdata.userPosition === "shipOwner"
      ? 1
      : formdata.userPosition === "shipManager"
        ? 2
        : formdata.userPosition === "portOperator"
          ? 3
          : 4
  );
  data.append("fleet_size", formdata.fleetSize);
  data.append("fleet_type", formdata.fleetType);
  data.append("pattern", formdata.tradingPattern);
  data.append("visited_port", ports);
  data.append("port_name", formdata.portName);
  data.append("functional_area", formdata.functionalArea);
  data.append("others", formdata.otherPosition);
  data.append("username", formdata.userName);
  data.append("password", formdata.password);
  data.append("remark", formdata.remarks);
  data.append("store_list", store_list);

  return axios.post(Config.API_URL + "Register", data);
};

export const getOTP = (otp_for, currentData, newData, confirmNewData, type) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("action", "get_otp");
  data.append("otp_for", otp_for);
  if (type === "email") {
    data.append("current_email", currentData);
    data.append("new_email", newData);
    data.append("reenter_new_email", confirmNewData);
    return axios.post(Config.API_URL + "buyer_api/change_email", data);
  } else if (type === "mobile") {
    data.append("current_mobile", currentData);
    data.append("new_mobile", newData);
    data.append("reenter_new_mobile", confirmNewData);
    return axios.post(Config.API_URL + "buyer_api/change_mobile_no", data);
  }
};

export const updateEmail = (emailOtp, newEmail) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("action", "change_email");
  data.append("otp", emailOtp);
  // data.append("current_email", currentEmail);
  data.append("new_email", newEmail);
  // data.append("reenter_new_email", confirmNewEmail);
  return axios.post(Config.API_URL + "buyer_api/change_email", data);
};

export const updateName = (firstName, lastName) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("first_name", firstName);
  data.append("last_name", lastName);
  return axios.post(Config.API_URL + "buyer_api/change_name", data);
};

export const updateUserName = (userName) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("user_name", userName);
  return axios.post(Config.API_URL + "buyer_api/change_username", data);
};

export const updateMobile = (mobileOtp, newMobile) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("action", "change_mobile");
  data.append("otp", mobileOtp);
  data.append("new_mobile", newMobile);
  return axios.post(Config.API_URL + "buyer_api/change_mobile_no", data);
};

// export const CartList = () =>
// {
//   var data = new FormData();
//   const token = localStorage.getItem(Config.STORAGE_TOKEN);
//   data.append("Appkey", Config.APP_KEY);
//   data.append("token", token);
//   return axios.post(Config.API_URL + "buyer_api/buyer_cart_list", data);
// };

export const CartList = (quotation_Id, cartassistrfqstatus) =>
{

  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("quotation_id", quotation_Id);
  data.append("cart_assist_rfq_status", cartassistrfqstatus);

  return axios.post(Config.API_URL + "Buyer_api/buyer_cart_list_for_quotation", data);
};


export const SubmitQoutation = (flag, AddressId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("flag", flag)
  data.append("address_id", AddressId ? AddressId : '')
  return axios.post(Config.API_URL + "Buyer_api/buyer_submit_quotation", data);
};


export const Wishlist = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  return axios.post(Config.API_URL + "buyer_api/buyer_wishlist", data);
};

export const ClearWishlist = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  return axios.post(Config.API_URL + "buyer_api/clear_wishlist", data);
};

export const AddToCart = (productId, qty, specification, deptId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("vp_id", productId);
  data.append("qty", qty);
  data.append("specification_id", specification);
  data.append("dept_id", deptId);
  return axios.post(Config.API_URL + "buyer_api/buyer_addto_cart", data);
};

export const RemoveFromCart = (cartId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("cart_id", cartId);
  return axios.post(Config.API_URL + "buyer_api/buyer_removefrom_cart", data);
};

export const RemoveAllFromCart = (quotationRemoveAll, cartassistrfqstatus) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("quotation_id", quotationRemoveAll);
  data.append("cart_assist_rfq_status", cartassistrfqstatus);
  return axios.post(Config.API_URL + "buyer_api/buyer_clear_cart_item", data);
};

export const AddToWishlist = (productId, specification) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("wishlist_vp_id", productId);
  data.append("specification_id", specification);
  return axios.post(Config.API_URL + "buyer_api/buyer_addto_wishlist", data);
};

export const RemoveFromWishlist = (wishlistId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("wishlist_id", wishlistId);
  return axios.post(Config.API_URL + "buyer_api/buyer_removefrom_wishlist", data);
};

export const getWishlistedItems = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  return axios.post(Config.API_URL + "buyer_api/buyer_wishlist", data);
};

export const updateCart = (cartId, qty, qoutationUpdateId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("cart_id", cartId);
  data.append("cart_qty", qty);
  data.append("quotation_id", qoutationUpdateId);
  return axios.post(Config.API_URL + "buyer_api/buyer_cart_update", data);
};

export const saveLaterService = (cartId, wishlist_vp_id, specification_id,) =>
{

  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("cart_id", cartId);
  data.append("wishlist_vp_id", wishlist_vp_id);
  data.append("specification_id", specification_id);
  return axios.post(Config.API_URL + "buyer_api/saveforlater", data);
};

export const getUserProfileInfo = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  return axios.post(Config.API_URL + "buyer_api/buyer_get_details_by_login", data);
};

export const setUserProfileInfo = (formdata) =>
{
  var data = new FormData();
  let ports = formdata.mostVisitedPort && formdata.mostVisitedPort.map((eachPort) => eachPort.value);
  let storeTypes = formdata.storeList.map((eachStoreType) => eachStoreType.value);
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("fname", formdata.firstName);
  data.append("lname", formdata.lastName);
  data.append("position", formdata.position);
  data.append("comp_name", formdata.companyName ? formdata.companyName : "");
  data.append("gst", formdata.gstNo);
  data.append("pan", formdata.panNo);
  data.append("mobile", formdata.mobileNumber);
  data.append("email", formdata.email);
  data.append("address1", formdata.address);
  data.append("address2", formdata.address2);
  data.append("country", formdata.country);
  data.append("state", formdata.state);
  if (formdata.profile_img) {
    data.append("doc_img", formdata.profile_img);
  }
  if (formdata.country === "101") {
    data.append("city", formdata.city);
  } else {
    data.append("city", formdata.cityName);
  }
  data.append("postalcode", formdata.postalCode);
  data.append(
    "service_type",
    formdata.userPosition === "shipOwner"
      ? 1
      : formdata.userPosition === "shipManager"
        ? 2
        : formdata.userPosition === "portOperator"
          ? 3
          : 4
  );
  data.append("fleet_size", formdata.fleetSize);
  data.append("fleet_type", formdata.fleetType);
  data.append("pattern", formdata.tradingPattern);
  data.append("visited_port", ports);
  data.append("port_name", formdata.portName);
  data.append("functional_area", formdata.functionalArea);
  data.append("others", formdata.otherPosition);
  data.append("store_list", storeTypes);
  data.append("remark", formdata.remarks);

  return axios.post(Config.API_URL + "buyer_api/buyer_update_profile_by_login", data);
};

export const rateProduct = (product_id, rating, title, message) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("product_id", product_id);
  data.append("rating", rating);
  title && data.append("title", title);
  message && data.append("message", message);
  return axios.post(Config.API_URL + "buyer_api/feedback", data);
};

export const validateUserData = (action, value) =>
{
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  data.append("action", action);
  if (action === "email") {
    data.append("email", value);
  } else if (action === "mobile") {
    data.append("mobile", value);
  } else if (action === "username") {
    data.append("username", value);
  }
  return axios.post(Config.API_URL + "Register/validate_eml_mob", data);
};

export const getCartAssistExcel = () =>
{
  return axios.get(Config.API_URL + "buyer_api/sampleCartAssistFunction");
}

export const fileUploadExcel = (filePath, addressId) =>
{
  console.log(addressId, "addressId")
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("cart_file", filePath);
  data.append('address_id', addressId)

  return axios.post(Config.API_URL + "buyer_api/CartAssist", data);
}


export const getCareerUserInfo = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  return axios.post(Config.API_URL + "api/add_application", data);
};

export const setCareerUserInfo = (formdata) =>
{
  var data = new FormData();
  console.log(formdata);
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("user_full_name", formdata.uname);
  data.append("dob", formdata.dob);
  data.append("email", formdata.uemail);
  data.append("mobile_no", formdata.mobilenumber);
  data.append("years_of_exp", formdata.yoe);
  data.append("last_pos_held", formdata.lastPosition);
  data.append("listing_id", formdata.positionApplied);
  data.append("hear_about_us", formdata.about);
  data.append("user_photo", formdata.imagefiles);
  data.append("documents", formdata.documents);

  return axios.post(Config.API_URL + "api/add_application", data);
}

// Rfq Form Api Calling
export const setRfqUserInfo = async (formdata) =>
{
  var data = new FormData();
  var fileArray = formdata.rfqfiles;
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  Array.from(fileArray).forEach((f) =>
  {
    data.append('rfq_file[]', f);
  });
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("name", formdata.rfqname);
  data.append("company_name", formdata.rfqcompname);
  data.append("email_id", formdata.rfqemail);
  data.append("contact_no", formdata.rfqmobilenumber);
  //data.append("rfq_file[]", fileArray);
  data.append("comment", formdata.rfqcomment);

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  return axios.post(Config.API_URL + "buyer_api/add_rfq_form", data, config);
}

// Update cart products on selection of alternate product
export const selectAlternateProduct = async (spId, quotationId, cartQuotationId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("sp_id", spId);
  data.append("quotation_id", quotationId);
  data.append("cart_quotation_id", cartQuotationId);

  return axios.post(Config.API_URL + "Buyer_api/select_alternative_product", data);
}

//rfq list in cart
export const selectRfqList = async () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  return axios.post(Config.API_URL + "Buyer_api/buyer_rfq_list", data);
}

export const getDashBoardValues = async (startdate, enddate) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("start_date", "2021-07-06");
  data.append("end_date", enddate);

  return axios.post(Config.API_URL + "DashboardController/BuyerDashboard", data);
}

export const createMemberAccount = (formdata) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);

  data.append('Appkey', Config.APP_KEY);
  data.append("token", token);
  data.append('fname', formdata.firstName);
  data.append('lname', formdata.lastName);
  data.append('emailid', formdata.email);
  data.append('mobile', formdata.mobileNumber);
  data.append("username", formdata.userName);
  data.append("password", formdata.password);

  return axios.post(Config.API_URL + 'Register/create_members_account', data);
};