import React from 'react';
import classes from "./Loader.module.scss"

export default function Loader() {
  return (
    <div className="section-top-margin">
    <div className={classes.loader}>Loading...</div>
    <div className='d-flex justify-content-center'><p>Please Wait...</p></div>
    </div>
  )
}
