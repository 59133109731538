import axios from "axios";
import * as Config from "../config/config";
var FormData = require("form-data");

export const getBlogBySlugNameService = (blogSlug) => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  data.append("slug", blogSlug);
  return axios.post(Config.API_URL + "buyer_api/buyer_blogs", data);
};

export const blogs = () => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  return axios.post(Config.API_URL + "buyer_api/buyer_blog_list", data);
};

export const blogCategories = () => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  return axios.post(Config.API_URL + "buyer_api/blog_categories", data);
};

export const searchBlogsByCategory = (searchValue, cat_slug) => {
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token ? token : "void");
  searchValue && data.append("search_key", searchValue);
  cat_slug && data.append("cat_slug", cat_slug);
  return axios.post(Config.API_URL + "buyer_api/blog_search", data);
};
