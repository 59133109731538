import React, { useState, useEffect } from "react";
import { color } from "GlobalStyles";
import styled from "styled-components";
import * as generalService from "service/generalService";
import './styles/product-detail.css'
import Ratings from "component/Utilities/Ratings";
import 'react-input-range/lib/css/index.css';
// import {
//   ReactiveBase,
//   RangeInput
// } from '@appbaseio/reactivesearch';
import InputRange from 'react-input-range';

function FilterProducts(props)
{

  const [categoryArr, setCategoryArr] = useState([]);
  const [brandArr, setBrandArr] = useState([]);
  const [subCategoryOptionsArr, setSubCategoryOptionsArr] = useState();
  const [size, setSize] = useState();
  const [showAll, setShowAll] = useState(false);
  let value = { min: props.min ? props.min : 0, max: props.max ? props.max : 500000 }
  let minDefault = props.priceFilterArr && props.priceFilterArr.length > 0 ? parseInt(props.priceFilterArr[0]) : 0;
  let maxDefault = props.priceFilterArr && props.priceFilterArr.length > 0 ? parseInt(props.priceFilterArr[1]) : 500000;


  useEffect(() =>
  {
    async function getCategories()
    {
      try {
        const categoryListResponse = await generalService.categoryList();
        const sortedCategories = categoryListResponse.data.data.categoryList.sort((a, b) => (a.cat_name > b.cat_name ? 1 : -1));
        setCategoryArr(sortedCategories);
      } catch (err) {
        console.log(err);
      }
    }

    async function getBrand()
    {
      try {
        const brandListResponse = await generalService.brandList();
        const brandData = brandListResponse.data.data;
        setBrandArr(brandData);
        brandListResponse.data.data.length > 10
          ? setSize(10)
          : setSize(brandListResponse.data.data.length);
      } catch (err) {
        console.log(err);
      }
    }

    getCategories();
    getBrand();
  }, []);

  let categoryOptions = null;
  if (categoryArr) {
    if (categoryArr.length !== 0) {
      categoryOptions = categoryArr.map((eachCategory) =>
      {
        return (
          <option key={eachCategory.cat_id} value={eachCategory.cat_id}>
            {eachCategory.cat_name}
          </option>
        );
      });
    } else {
      categoryOptions = <option value="">No category availble</option>;
    }
  }

  let brandOptions = null;
  if (brandArr) {
    if (brandArr.length !== 0) {
      brandOptions = brandArr.slice(0, size).map((eachBrand) =>
      {
        return (
          <div className="form-group mb-0" key={eachBrand.brand_name}>
            <div className="custom-control custom-checkbox d-flex align-items-center pl-0">
              {/* <input type="checkbox" className="custom-control-input" name={eachBrand.brand_name} /> */}
              {/* <div>
                <input
                  onChange={(e) => {
                    // add to list
                    if (e.target.checked) {
                      props.setBrand([
                        ...props.brand,
                        eachBrand.brand_name
                      ]);
                    } else {
                      // remove from list
                      props.setBrand(
                        props.brand.filter((eb) => eb !== eachBrand.brand_name),
                      );
                    }
                  }}
                  value={props.brand}
                  style={{
                    marginRight: '10px',
                    width: '16px',
                    height: '16px',
                    marginTop: '5px',
                  }}
                  type="checkbox"
                  checked={props.brand && props.brand.includes(eachBrand.brand_name)}
                />
              </div>
              <div>
                <label htmlFor={eachBrand.brand_name} style={{ marginTop: '10px' }}>
                  {eachBrand.brand_name}
                </label>
              </div> */}
              <label>
                <div>
                  <input
                    onChange={(e) =>
                    {
                      // add to list
                      if (e.target.checked) {
                        props.setBrand([
                          ...props.brand,
                          eachBrand.brand_name
                        ]);
                      } else {
                        // remove from list
                        props.setBrand(
                          props.brand.filter((eb) => eb !== eachBrand.brand_name),
                        );
                      }
                    }}
                    value={props.brand}
                    style={{
                      marginRight: '0px',
                      width: '16px',
                      height: '16px',
                      marginTop: '3px',
                      visibility: 'hidden',
                      display: 'none',
                    }}
                    type="checkbox"
                    checked={props.brand && props.brand.includes(eachBrand.brand_name)}

                  />
                </div>
                <div>
                  <span key={brandArr} className={`mt-0 ${props.brand && props.brand.includes(eachBrand.brand_name) && 'active'}`} >
                    {eachBrand.brand_name}
                  </span>
                </div>
              </label>
            </div>
          </div>
        );
      });
    } else {
      brandOptions = <span value="">No brand availble</span>;
    }
  }

  useEffect(() =>
  {
    let subCategoryOptions;
    if (categoryArr) {
      if (categoryArr.length !== 0) {
        let filterCat = categoryArr.filter((eachCategory) => { return parseInt(eachCategory.cat_id) === parseInt(props.category) });

        if (filterCat[0]) {
          if (filterCat[0].subCategoryList) {
            if (filterCat[0].subCategoryList.length !== 0) {
              subCategoryOptions = filterCat[0].subCategoryList.map((eachSubCategory) =>
              {
                return (
                  <option key={eachSubCategory.cat_id} value={eachSubCategory.cat_id}>
                    {eachSubCategory.cat_name}
                  </option>
                );
              });
              setSubCategoryOptionsArr(subCategoryOptions)
            }
            else {
              subCategoryOptions = <option value="">No sub category availble</option>;
              setSubCategoryOptionsArr(subCategoryOptions)
            }
          }
          else {
            subCategoryOptions = <option value="">No sub category availble</option>;
            setSubCategoryOptionsArr(subCategoryOptions)
          }
        }
        else {
          subCategoryOptions = <option value="">No sub category availble</option>;
          setSubCategoryOptionsArr(subCategoryOptions)
        }
      } else {
        subCategoryOptions = <option value="">No sub category availble</option>;
        setSubCategoryOptionsArr(subCategoryOptions)
      }
    }
  }, [categoryArr, props.category]);


  const showAllHandler = () =>
  {
    if (!showAll) {
      setSize(brandArr && brandArr.length);
    } else {
      setSize(10);
    }
    setShowAll(!showAll);
  };


  const priceChangeHandler = (value) =>
  {
    props.setMin(value.min);
    props.setMax(value.max);
  };

  const debounce = function (fn, d)
  {
    let timer;
    return function ()
    {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() =>
      {
        priceChangeHandler.apply(context, arguments);
      }, d);
    }
  }

  const betterFunction = debounce(priceChangeHandler, 100);



  return (
    <Filters>
      <div>
        <div className="text-right cursor-pointer font-weight-bold" onClick={() => window.location.reload()}>Clear All</div>
        <h3 className="text font-weight-bold  filter-title">Category</h3>

        <select className="form-control bg-white px-0 py-1 select-dropdown cursor-pointer" value={props.category} name="category" onChange={(e) => props.setCategory(e.target.value)}>
          <option value="">Select Category</option>
          {categoryOptions}
        </select>
      </div>

      <div>
        <h3 className="mt-3 text font-weight-bold filter-title">Sub Category</h3>

        <select className="form-control bg-white py-1 px-0 select-dropdown cursor-pointer" value={props.subCategory} name="subCategory" onChange={(e) => props.setSubCategory(e.target.value)}>
          <option value="">Select Sub Category</option>
          {subCategoryOptionsArr}
        </select>
      </div>

      {/* <h3 className="text font-weight-bold mt-3">Brands</h3> */}
      <div className="title-with-clear">
        <h3 className="text font-weight-bold mt-4 mb-0">Brands</h3>
        <span className="pointer" onClick={() => props.setBrand([])}>Clear</span>
      </div>
      <div className='brandlist-height'>
        {brandOptions}
      </div>
      <div>
        {brandArr && brandArr.length > 10 && (
          <div onClick={showAllHandler}>
            {!showAll ? (
              <div className='text-right cursor-pointer' style={{ paddingTop: '10px', fontSize: '12px' }}>
                Show More <i className='fas fa-arrow-down'></i>
              </div>
            ) : (
              <div className='text-right cursor-pointer' style={{ paddingTop: '10px', fontSize: '12px' }}>
                Show Less <i className='fas fa-arrow-up'></i>
              </div>
            )}
          </div>
        )}
      </div>


      {/* Customer Reviews */}

      <div className='pb-2 filters'>
        <div className="title-with-clear">
          <h3 className="text font-weight-bold mt-3 mb-0">Customer Reviews</h3>
          <span className="pointer" onClick={() => props.setRatingVal('')}>Clear</span>
        </div>
        <div className={`d-flex align-items-end mb-3 ${props.ratingVal && props.ratingVal === '4' && 'active'}`} onClick={() => props.setRatingVal('4')}>
          <Ratings rating={4} starDimension='23px' starSpacing='1px' starRatedColor='#FFB700' />
          <span>&nbsp;</span>
          <span className='cursor-pointer' style={{ position: 'relative', top: '2px' }}>& Up</span>
        </div>
        <div className={`d-flex align-items-end mb-3 ${props.ratingVal && props.ratingVal === '3' && 'active'}`} onClick={() => props.setRatingVal('3')}>
          <Ratings rating={3} starDimension='23px' starSpacing='1px' starRatedColor='#FFB700' />
          <span>&nbsp;</span>
          <span className='cursor-pointer' style={{ position: 'relative', top: '2px' }}>& Up</span>
        </div>
        <div className={`d-flex align-items-end mb-3 ${props.ratingVal && props.ratingVal === '2' && 'active'}`} onClick={() => props.setRatingVal('2')}>
          <Ratings rating={2} starDimension='23px' starSpacing='1px' starRatedColor='#FFB700' />
          <span>&nbsp;</span>
          <span className='cursor-pointer' style={{ position: 'relative', top: '2px' }}>& Up</span>
        </div>
        <div className={`d-flex align-items-end mb-3 ${props.ratingVal && props.ratingVal === '1' && 'active'}`} onClick={() => props.setRatingVal('1')}>
          <Ratings rating={1} starDimension='23px' starSpacing='1px' starRatedColor='#FFB700' />
          <span>&nbsp;</span>
          <span className='cursor-pointer' style={{ position: 'relative', top: '2px' }}>& Up</span>
        </div>
      </div>

      {/* New Arrivals */}
      <div>
        <div className="title-with-clear">
          <h3 className="text font-weight-bold mt-3 mb-0">New Arrivals</h3>
          <span className="pointer" onClick={() => props.setNewArrival('')}>Clear</span>
        </div>
        <div className='d-grid'>
          <div className='row'>
            <div className='col-md-12 filters'>
              {props.newArrivalFilterArr && props.newArrivalFilterArr.map((eachArr) =>
              {
                return <span key={eachArr} className={`d-block pb-2 cursor-pointer ${props.newArrival && props.newArrival === eachArr && 'active'}`} onClick={() => props.setNewArrival(eachArr)}>{`Last ${eachArr} days`}</span>
              })
              }
            </div>
          </div>
        </div>
      </div>

      {/* <h3 className="text font-weight-bold mt-3 mb-4 filter-title">Price</h3> */}
      <div className='pb-3 d-none'>
        {/* <ReactiveBase
          app="good-books-ds"
          url="https://a03a1cb71321:75b6603d-9456-4a5a-af6b-a487b309eb61@appbase-demo-ansible-abxiydt-arc.searchbase.io"
        >
          <RangeInput
            componentId="RangeInputSensor"
            dataField="rating"
            range={{
              "start": minDefault,
              "end": maxDefault
            }}
            defaultValue={{
              "start": minDefault,
              "end": maxDefault
            }}
            rangeLabels={{
              "start": `₹ ${minDefault}`,
              "end": `₹ ${maxDefault}`
            }}
            onValueChange={
              function (value) {
                props.setMin(value.start);
                props.setMax(value.end);
              }
            }
          />
        </ReactiveBase> */}
        <div className='col-md-12 pt-4 pb-4'>
          <InputRange
            maxValue={maxDefault}
            minValue={minDefault}
            value={value}
            formatLabel={value => `₹ ${value}`}
            onChange={(value) => betterFunction(value)}
            draggableTrack
          />

        </div>
      </div>

    </Filters>
  );
}

// const Filters = styled.div`
//   height: 100%;
//   padding: 20px;
//   .select-dropdown {
//     color: ${color.colorPrimary} !important;
//     border: 2px solid ${color.colorPrimary}!important;
//     font: normal normal 600 14px/0 Nunito !important;
//   }

//   h3 {
//     border-bottom: 1px solid ${color.colorSmallText};
//     // width: 50%;
//     margin-bottom: 10px;
//     font-size: 20px;
//     padding-bottom: 5px;
//   }
//   .color {
//     height: 20px;
//     width: 20px;
//     margin-bottom: 10px;
//     border-radius: 50%;
//   }
//   .price {
//     font: normal normal bold 14px/22px Nunito;
//     margin-bottom: 0;
//   }
//   .brandlist-height{
//     height: 220px;
//   overflow: auto;
//   }

//   .brandlist-height::-webkit-scrollbar {
//   width: .3em;
//   background-color: #d8d8d8;
//   border-radius: 20px;


// }

// .brandlist-height::-webkit-scrollbar-track {
//   box-shadow: none;
// }

// .brandlist-height::-webkit-scrollbar-thumb {
//   background-color: #28384b;
//   outline: 1px solid #28384b;
//   border-radius: 20px;
//   width: .3em;
// }
// .active{
//     color: #28384b;
//     font-weight: 800;
// }
// .filters span{
// transition: .5s all;
// }
// .filters span:hover{
//   color: #FFB700;
// transition: .5s all;
// }
//   `;


const Filters = styled.div`
  height: 100%;
  padding: 20px;
  .select-dropdown {
    color: ${color.colorPrimary} !important;
    border: 2px solid ${color.colorPrimary}!important;
    font: normal normal 700 14px/0 Nunito !important;
    height: 35px;
  }

  h3.filter-title {
    border-bottom: 1px solid ${color.colorSmallText};
    // width: 50%;
    margin-bottom: 10px;
    font: normal normal 700 18px Nunito !important;
    padding-bottom: 5px;

    @media only screen and (device-width: 1280px) and (device-height: 720px) {
      font: normal normal 700 14px Nunito !important;
    }
  }
  .color {
    height: 20px;
    width: 20px;
    margin-bottom: 10px;
    border-radius: 50%;
  }
  .price {
    font: normal normal bold 14px/22px Nunito;
    margin-bottom: 0;
  }
  .brandlist-height{
    height: 250px;
  overflow: auto;

  label{
    cursor: pointer;
    font-size: 14px;
    line-height: 29px;
    margin-bottom: -5px !important;
    display: flex;
    font-weight: 500;
  }
  }

  .brandlist-height::-webkit-scrollbar {
  width: .3em;
  background-color: #d8d8d8;
  border-radius: 20px;

  
}

.brandlist-height::-webkit-scrollbar-track {
  box-shadow: none;
}
 
.brandlist-height::-webkit-scrollbar-thumb {
  background-color: #28384b;
  outline: 1px solid #28384b;
  border-radius: 20px;
  width: .3em;
}
.active{
    color: #ee8400;
    font-weight: 800;
}
.filters span{
transition: .5s all;
@media only screen and (device-width: 1280px) and (device-height: 720px) {
      font: normal normal 600 14px Nunito !important;
}
}
.filters span:hover{
  color: #FFB700;
transition: .5s all;
}

.clear-all{
  @media only screen and (device-width: 1280px) and (device-height: 720px) {
      font: normal normal 700 14px Nunito !important;
  }
}

.title-with-clear{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid ${color.colorSmallText};
  margin-bottom: 15px;

  p, span{
    font-size: 14px;
    font-weight: 400;
    color: #de0000;
  }
}

.pointer{
  cursor: pointer;
}
`;



export default FilterProducts;
