import axios from "axios";
import * as Config from "../config/config";
var FormData = require("form-data");

export const GetAllAddress = () => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  return axios.post(Config.API_URL + "buyer_api/buyer_getAddress", data);
};

export const AddAddress = (formdata) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("address_type", formdata.addressType);
  if (formdata.addressType !== "ship_address") {
    data.append("country", formdata.country);
    data.append("state", formdata.state);
    if (formdata.country === "101") {
      data.append("town_city", formdata.city);
    } else {
      data.append("town_city", formdata.cityName);
    }
    data.append("zip_code", formdata.pincode);
    data.append("name", formdata.officeName);
    data.append("address1", formdata.address_basic1);
    data.append("address2", formdata.address_basic2);
    data.append("gst_no", formdata.gstNo);
    data.append("pan_detail", formdata.panNo);
  } else {
    data.append("imo_no", formdata.IMONo);
    data.append("port_details", formdata.portDetails);
    data.append("berth_details", formdata.berthDetails);
    data.append("ship_agent_name", formdata.shipAgencyNamePIC);
    data.append("ship_agency_name", formdata.shipAgencyName);
    data.append("ship_agency_no", formdata.shipAgencyNumber);
    data.append("ship_agency_email", formdata.shipAgencyEmail);
    data.append("ship_trade_pattern", formdata.shipTradingPattern);
    data.append("name", formdata.shipName);
    data.append("address1", formdata.address);
    // data.append("address2", formdata.address2);
    data.append("zip_code", formdata.pincode);

  }
  return axios.post(Config.API_URL + "buyer_api/buyer_addAddress", data);
};

export const DeleteAddress = (addressId) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("id", addressId);
  return axios.post(Config.API_URL + "buyer_api/buyer_delete_address", data);
};

export const GetEditAddress = (addressId) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("id", addressId);
  return axios.post(Config.API_URL + "buyer_api/buyer_get_this_address", data);
};

export const EditAddress = (formdata) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("address_type", formdata.addressType);
  data.append("id", formdata.id);
  if (formdata.addressType !== "ship_address") {
    data.append("country", formdata.country);
    data.append("state", formdata.state);
    if (formdata.country === "101") {
      data.append("town_city", formdata.city);
    } else {
      data.append("town_city", formdata.cityName);
    }
    data.append("zip_code", formdata.pincode);
    data.append("name", formdata.officeName);
    data.append("address1", formdata.address_basic1);
    data.append("address2", formdata.address_basic2);
    data.append("gst_no", formdata.gstNo);
    data.append("pan_detail", formdata.panNo);
  } else {
    data.append("imo_no", formdata.IMONo);
    data.append("port_details", formdata.portDetails);
    data.append("berth_details", formdata.berthDetails);
    data.append("ship_agent_name", formdata.shipAgencyNamePIC);
    data.append("ship_agency_name", formdata.shipAgencyName);
    data.append("ship_agency_no", formdata.shipAgencyNumber);
    data.append("ship_agency_email", formdata.shipAgencyEmail);
    data.append("ship_trade_pattern", formdata.shipTradingPattern);
    data.append("name", formdata.shipName);
    data.append("address1", formdata.address);
    // data.append("address2", formdata.address2);
    data.append("zip_code", formdata.pincode);
  }
  return axios.post(Config.API_URL + "buyer_api/buyer_EditAddress", data);
};

export const saveAddressAndDateOfDelivery = (addressId, deliveryDate) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("action", "default");
  data.append("add_id", addressId);
  data.append("prefered_delivery_date", deliveryDate);
  data.append("default_type", 2);
  return axios.post(Config.API_URL + "buyer_api/default_address", data);
};

export const saveDeliveryAddress = (addressId, deliveryDate) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("action", "default");
  data.append("add_id", addressId);
  data.append("prefered_delivery_date", deliveryDate);
  data.append("default_type", 1);
  return axios.post(Config.API_URL + "buyer_api/default_address", data);
};

export const clearall = () => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("action", "clearall");
  return axios.post(Config.API_URL + "buyer_api/default_address", data);
};

export const getAddressAndDateOfDelivery = () => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("action", "get_default_address");
  data.append("default_type", 2);
  return axios.post(Config.API_URL + "buyer_api/default_address", data);
};
