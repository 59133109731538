export const CREATE_ACCOUNT_START = "CREATE_ACCOUNT_START";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL";

export const LIST_CART_ITEMS_START = "LIST_CART_ITEMS_START";
export const LIST_CART_ITEMS_SUCCESS = "LIST_CART_ITEMS_SUCCESS";
export const LIST_CART_ITEMS_FAIL = "LIST_CART_ITEMS_FAIL";

export const ADD_TO_CART_START = "ADD_TO_CART_START";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";

export const REMOVE_FROM_CART_START = "REMOVE_FROM_CART_START";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAIL = "REMOVE_FROM_CART_FAIL";

export const LIST_WISHLIST_ITEMS_START = "LIST_WISHLIST_ITEMS_START";
export const LIST_WISHLIST_ITEMS_SUCCESS = "LIST_WISHLIST_ITEMS_SUCCESS";
export const LIST_WISHLIST_ITEMS_FAIL = "LIST_WISHLIST_ITEMS_FAIL";

export const CLEAR_WISHLIST_ITEMS_START = "CLEAR_WISHLIST_ITEMS_START";
export const CLEAR_WISHLIST_ITEMS_SUCCESS = "CLEAR_WISHLIST_ITEMS_SUCCESS";
export const CLEAR_WISHLIST_ITEMS_FAIL = "CLEAR_WISHLIST_ITEMS_FAIL";

export const ADD_TO_WISHLIST_START = "ADD_TO_WISHLIST_START";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAIL = "ADD_TO_WISHLIST_FAIL";

export const REMOVE_FROM_WISHLIST_START = "REMOVE_FROM_WISHLIST_START";
export const REMOVE_FROM_WISHLIST_SUCCESS = "REMOVE_FROM_WISHLIST_SUCCESS";
export const REMOVE_FROM_WISHLIST_FAIL = "REMOVE_FROM_WISHLIST_FAIL";

export const UPDATE_CART_START = "UPDATE_CART_START";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAIL = "UPDATE_CART_FAIL";

export const SAVE_LATER_START = "SAVE_LATER_START";
export const SAVE_LATER_SUCCESS = "SAVE_LATER_SUCCESS";
export const SAVE_LATER_FAIL = "SAVE_LATER_FAIL";

export const REMOVE_ALL_FROM_CART_START = "REMOVE_ALL_FROM_CART_START";
export const REMOVE_ALL_FROM_CART_SUCCESS = "REMOVE_ALL_FROM_CART_SUCCESS";
export const REMOVE_ALL_FROM_CART_FAIL = "REMOVE_ALL_FROM_CART_FAIL";

export const USER_RESET = "USER_RESET";

export const RESET = "RESET";
