import { useEffect, useState } from "react";

function useDeviceMobile() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.innerWidth < 768 ? setShow(true) : setShow(false);
    function handleResize() {
      window.innerWidth < 768 ? setShow(true) : setShow(false);
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [show]);

  return show;
}

export default useDeviceMobile;
