import { fork, all } from "redux-saga/effects";
import { addressFlowWatcher } from "./addressSaga";

import { authFlowWatcher } from "./authSaga";
import { userFlowWatcher } from "./userSaga";
import { productFlowWatcher } from "./productSaga";
import { checkoutFlowWatcher } from "./checkoutSaga";
import { orderFlowWatcher } from "./orderSaga";

export default function* rootSaga() {
  yield all([
    fork(authFlowWatcher),
    fork(userFlowWatcher),
    fork(addressFlowWatcher),
    fork(productFlowWatcher),
    fork(checkoutFlowWatcher),
    fork(orderFlowWatcher),
  ]);
}
