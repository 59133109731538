import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Toast from "component/Utilities/Toast";

function Layout({ children }) {
  return (
    <LayoutContainer>
      <Toast />
      <div className="content">
        <Header />
        <div className="main_content">{children}</div>
      </div>
      <Footer />
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .content {
    flex-grow: 1;
  }
  .main_content {
    margin: 0 auto;
    max-width: 2000px;
  }
`;

export default withRouter(Layout);
