import * as authConstants from "store/constants/authConstants";
import { updateObject } from "shared/utility";

const initialState = {
  error: null,
  loading: false,
  token: null,
  name: null,
  comp_name: null,
  otpUserId: null,
  response: null,
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, response: null });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    name: action.name,
    comp_name: action.comp_name,
    error: null,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const sendOtpStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, response: null });
};

const sendOtpSuccess = (state, action) => {
  return updateObject(state, {
    otpUserId: action.userId,
    response: action.response,
    error: null,
    loading: false,
  });
};

const sendOtpFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const forgotPasswordStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, response: null });
};

const forgotPasswordSuccess = (state, action) => {
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const forgotPasswordFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const logoutStart = (state, action) => {
  return updateObject(state, {
    token: null,
    name: null,
    error: null,
    loading: false,
  });
};

const logoutSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    response: action.response,
  });
};

const logoutFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authReset = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    response: null,
  });
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.AUTH_START:
      return authStart(state, action);
    case authConstants.AUTH_SUCCESS:
      return authSuccess(state, action);
    case authConstants.AUTH_FAIL:
      return authFail(state, action);

    case authConstants.SEND_OTP_START:
      return sendOtpStart(state, action);
    case authConstants.SEND_OTP_SUCCESS:
      return sendOtpSuccess(state, action);
    case authConstants.SEND_OTP_FAIL:
      return sendOtpFail(state, action);

    case authConstants.FORGOT_PASSWORD_START:
      return forgotPasswordStart(state, action);
    case authConstants.FORGOT_PASSWORD_SUCCESS:
      return forgotPasswordSuccess(state, action);
    case authConstants.FORGOT_PASSWORD_FAIL:
      return forgotPasswordFail(state, action);

    case authConstants.LOGOUT_START:
      return logoutStart(state, action);
    case authConstants.LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case authConstants.LOGOUT_FAIL:
      return logoutFail(state, action);

    case authConstants.AUTH_RESET:
      return authReset(state, action);

    default:
      return state;
  }
};
