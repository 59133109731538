import React from 'react';
import styled from 'styled-components';
import { color } from 'GlobalStyles';
import { NavLink } from 'react-router-dom';

function ContactSection() {
  return (
    <ContactDiv className='py-2 px-0'>
      <div className='container-fluid contact-bg'>
        <div className='row'>
          <div className='col-12 text-center mb-4'>
            <h3 className='large-text font-weight-bold text-white'>Want to Know More?</h3>
            <h5 className='text text-white text-center'>Get in touch with us for Enquiries</h5>
            <NavLink to='/contact-us'>
              <button className='white-bordered-btn mb-3 px-4'>Contact Us</button>
            </NavLink>
          </div>
        </div>
      </div>
    </ContactDiv>
  );
}

const ContactDiv = styled.div`
  h3 {
    // font: normal normal bold 38px/46px Ubuntu;
    padding-top: 40px;
  }
  h5 {
    font: normal normal bold 26px/46px Ubuntu;
    padding-bottom: 20px;
  }
  .contact-bg {
    background-color: ${color.colorPrimary};
  }
`;

export default React.memo(ContactSection);
