import { call, put, takeLatest } from 'redux-saga/effects';
import * as userConstants from 'store/constants/userConstants';
import * as userActions from 'store/actions/userActions';
import * as userService from 'service/userService';
import { CATCH_ERROR, STORAGE_NAME, STORAGE_TOKEN, COMPANY_NAME, STORAGE_USER_TYPE } from 'config/config';
import * as authActions from 'store/actions/authActions';

export function* CreateAccountSaga(action)
{
  try {
    const response = yield call(userService.CreateAccount, action.formdata);
    const token = response.data.data.u_token;
    const name = response.data.data.u_fname + ' ' + response.data.data.u_lname;
    const comp_name = response.data.data.u_business_name;
    const u_type = response.data.data.u_type;
    localStorage.setItem(STORAGE_TOKEN, token);
    localStorage.setItem(STORAGE_NAME, name);
    localStorage.setItem(STORAGE_USER_TYPE, u_type);
    if (comp_name) {
      localStorage.setItem(COMPANY_NAME, comp_name);
    }
    yield put(authActions.authSuccess(token, name, comp_name));
    yield put(userActions.accountCreateSuccess(response.data.message));
  } catch (error) {
    if (error.response) {
      yield put(userActions.accountCreateFail(error.response.data.message));
    } else {
      yield put(userActions.accountCreateFail(CATCH_ERROR));
    }
  }
}

export function* CartListSaga()
{
  try {
    const response = yield call(userService.CartList);
    yield put(userActions.cartListSuccess(response.data.data));
  } catch (error) {
    if (error.response) {
      yield put(userActions.cartListFail(error.response.data.msg));
    } else {
      yield put(userActions.cartListFail(CATCH_ERROR));
    }
  }
}

export function* WishListSaga()
{
  try {
    const response = yield call(userService.Wishlist);
    yield put(userActions.getWishlistSuccess(response.data.data.wishlist_items));
  } catch (error) {
    if (error.response) {
      yield put(userActions.getWishlistFail(error.response.data.msg));
    } else {
      yield put(userActions.getWishlistFail(CATCH_ERROR));
    }
  }
}

export function* ClearWishListSaga()
{
  try {
    const response = yield call(userService.ClearWishlist);
    yield put(userActions.clearWishlistSuccess(response.data.msg));
  } catch (error) {
    if (error.response) {
      yield put(userActions.clearWishlistFail(error.response.data.msg));
    } else {
      yield put(userActions.clearWishlistFail(CATCH_ERROR));
    }
  }
}

export function* AddToCartSaga(action)
{
  try {
    const response = yield call(userService.AddToCart, action.productId, action.qty, action.specification, action.activeDpt);
    yield put(userActions.addToCartSuccess(response.data.msg));
    yield put(userActions.cartListStart());
    yield put(userActions.getWishlistStart());
  } catch (error) {
    if (error.response) {
      yield put(userActions.addToCartFail(error.response.data.msg));
    } else {
      yield put(userActions.addToCartFail(CATCH_ERROR));
    }
  }
}

export function* RemoveFromCartSaga(action)
{
  try {
    const response = yield call(userService.RemoveFromCart, action.cartId);
    yield put(userActions.removeFromCartSuccess(response.data.msg));
    yield put(userActions.cartListStart());
  } catch (error) {
    if (error.response) {
      yield put(userActions.removeFromCartFail(error.response.data.msg));
    } else {
      yield put(userActions.removeFromCartFail(CATCH_ERROR));
    }
  }
}

export function* AddToWishlistSaga(action)
{
  try {
    const response = yield call(userService.AddToWishlist, action.productId, action.specification);
    yield put(userActions.addToWishlistSuccess(response.data.msg));
    yield put(userActions.getWishlistStart());
  } catch (error) {
    if (error.response) {
      yield put(userActions.addToWishlistFail(error.response.data.msg));
    } else {
      yield put(userActions.addToWishlistFail(CATCH_ERROR));
    }
  }
}

export function* RemoveFromWishlistSaga(action)
{
  try {
    const response = yield call(userService.RemoveFromWishlist, action.wishlistId);
    yield put(userActions.removeFromWishlistSuccess(response.data.msg));
    yield put(userActions.getWishlistStart());
  } catch (error) {
    if (error.response) {
      yield put(userActions.removeFromWishlistFail(error.response.data.msg));
    } else {
      yield put(userActions.removeFromWishlistFail(CATCH_ERROR));
    }
  }
}

export function* UpdateSaga(action)
{
  try {
    const response = yield call(userService.updateCart, action.cartId, action.qty, action.qoutationUpdateId);
    yield put(userActions.updateCartSuccess(response.data.msg));
    yield put(userActions.cartListStart());
  } catch (error) {
    if (error.response) {
      yield put(userActions.updateCartFail(error.response.data.msg));
    } else {
      yield put(userActions.updateCartFail(CATCH_ERROR));
    }
  }
}

export function* SaveLaterSaga(action)
{
  try {
    const response = yield call(userService.saveLaterService, action.cartId, action.wishlist_vp_id, action.specification_id);
    yield put(userActions.saveLaterSuccess(response.data.msg));
    yield put(userActions.cartListStart());
    yield put(userActions.getWishlistStart());
  } catch (error) {
    if (error.response) {
      yield put(userActions.saveLaterFail(error.response.data.msg));
    } else {
      yield put(userActions.saveLaterFail(CATCH_ERROR));
    }
  }
}

export function* RemoveAllFromCartSaga(action)
{
  try {
    const response = yield call(userService.RemoveAllFromCart, action.quotationRemoveAll);
    yield put(userActions.removeAllFromCartSuccess(response.data.msg));
    yield put(userActions.cartListStart());
  } catch (error) {
    if (error.response) {
      yield put(userActions.removeAllFromCartFail(error.response.data.msg));
    } else {
      yield put(userActions.removeAllFromCartFail(CATCH_ERROR));
    }
  }
}


export function* userFlowWatcher()
{
  yield takeLatest(userConstants.CREATE_ACCOUNT_START, CreateAccountSaga);
  yield takeLatest(userConstants.ADD_TO_CART_START, AddToCartSaga);
  yield takeLatest(userConstants.REMOVE_FROM_CART_START, RemoveFromCartSaga);
  yield takeLatest(userConstants.ADD_TO_WISHLIST_START, AddToWishlistSaga);
  yield takeLatest(userConstants.REMOVE_FROM_WISHLIST_START, RemoveFromWishlistSaga);
  yield takeLatest(userConstants.LIST_CART_ITEMS_START, CartListSaga);
  yield takeLatest(userConstants.LIST_WISHLIST_ITEMS_START, WishListSaga);
  yield takeLatest(userConstants.UPDATE_CART_START, UpdateSaga);
  yield takeLatest(userConstants.SAVE_LATER_START, SaveLaterSaga);
  yield takeLatest(userConstants.CLEAR_WISHLIST_ITEMS_START, ClearWishListSaga);
  yield takeLatest(userConstants.REMOVE_ALL_FROM_CART_START, RemoveAllFromCartSaga);
}
