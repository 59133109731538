import React from 'react';
import { color } from 'GlobalStyles';

function SectionHeadings(props) {
  return (
    <h2
      className={props.className}
      style={{
        font: 'normal normal 600 27px/36px Ubuntu',
        color: `${color.colorPrimary}`,
        marginBottom: '1rem',
        textTransform: 'text-capitalize',
      }}
    >
      {props.sectionHeading}
    </h2>
  );
}

export default React.memo(SectionHeadings);
