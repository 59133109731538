import React from "react";
import Modal from "react-bootstrap4-modal";
import { NavLink } from "react-router-dom";


export default function ReactModal(props)
{
  return (
    <Modal visible={props.showModal} onClickBackdrop={props.closeModal} className={props.className}>
      {props.heading && (
        <div className="modal-header">
          <div>
            <h2 className="add-new-address">{props.heading}</h2>
            {props.showTextInModal && <span className="font-small">
              <i>*To Add or Amend Billing Address and/or Delivery Address please visit <u><NavLink to='/user-profile/user-address'>My Address</NavLink></u>.</i>
            </span>}
          </div>
          <button type="button" className="close">
            <span onClick={props.closeModal}>&times;</span>
          </button>
        </div>
      )}
      <div className="modal-body py-1">{props.modalBody}</div>
    </Modal>
  );
}
