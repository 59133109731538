import React from "react";
import StarRatings from 'react-star-ratings';

function Ratings({ rating, starDimension, starSpacing, starRatedColor, changeRating, starHoverColor }) {
  return (
  <div className='cursor-pointer'>
    <StarRatings
    rating={rating}
    starDimension={starDimension}
    starSpacing={starSpacing}
    starRatedColor={starRatedColor}
    changeRating={changeRating}
    starHoverColor={starHoverColor}
    />
  </div>  
  )
}

export default Ratings;