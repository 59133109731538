import axios from "axios";
import * as Config from "../config/config";
var FormData = require("form-data");

export const getUserOrders = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  return axios.post(Config.API_URL + "buyer_api/buyer_my_order", data);
};

export const changeUserOrderStatus = (orderId, status, reason, amount, utr, creditPeriod, paymentType, paymentAction) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  const statuscode = status === "Cancel order" ? 5 : status === "Confirm order" ? 2 : status === "Return order" ? 8 : parseInt(status);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token && token);
  data.append("order_id", orderId);
  data.append("status", statuscode);
  data.append("reason", reason);
  data.append("paid_amount", amount);
  data.append("utr_no", utr);
  data.append("credit_period", creditPeriod);
  data.append("payment_status", paymentType);
  data.append("payment_action", paymentAction);
  return axios.post(Config.API_URL + "buyer_api/after_order_action", data);
};
export const userBilling = (productId, quantity, specification) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("product_id", productId);
  data.append("quantity", quantity);
  data.append("specification_id", specification);
  return axios.post(Config.API_URL + "buyer_api/buy_now", data);
};

export const billByOrder = (orderId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("order_id", orderId);
  return axios.post(Config.API_URL + "buyer_api/repeat_order", data);
};

export const getOrderDetails = (orderId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("order_id", orderId);
  return axios.post(Config.API_URL + "buyer_api/buyer_this_order_detail", data);
};

export const getMemberOrderDetails = (orderId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token);
  data.append("order_id", orderId);
  return axios.post(Config.API_URL + "Buyer_api/buyer_this_member_order_detail", data);
};

export const getMemberLists = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append("token", token);

  return axios.post(Config.API_URL + 'Member_report_api/memberList', data);
};