import * as productConstants from "store/constants/productConstants";

export const getProductsByPageNameStart = (
  catType,
  categoryId,
  search_key,
  pageName,
  limit,
  offset,
  sortType,
  defaultAddress,
  preferredDeliveryDate,
  subCatId,
  brand,
  min,
  max,
  ratingVal,
  newArrival,
  sortDay,
  sortPort
) => {
  return {
    type: productConstants.GET_PRODUCTS_BY_PAGE_NAME_START,
    catType,
    categoryId,
    search_key,
    pageName,
    limit,
    offset,
    sortType,
    defaultAddress,
    preferredDeliveryDate,
    subCatId,
    brand,
    min,
    max,
    ratingVal,
    newArrival,
    sortDay,
    sortPort
  };
};

export const getProductsByPageNameSuccess = (productList) => {
  return {
    type: productConstants.GET_PRODUCTS_BY_PAGE_NAME_SUCCESS,
    productList: productList,
  };
};

export const getProductsByPageNameFail = (error) => {
  return {
    type: productConstants.GET_PRODUCTS_BY_PAGE_NAME_FAIL,
    error: error,
  };
};

export const getProductData = (startIndex, endIndex, offset, limit, sortType, currentPath) => {
  return {
    type: productConstants.GET_PRODUCTS_DATA,
    startIndex,
    endIndex,
    offset,
    limit,
    sortType,
    currentPath,
  };
};

export const productsReset = () => {
  return {
    type: productConstants.PRODUCTS_RESET,
  };
};
