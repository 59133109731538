import React, { useEffect, useState } from "react";
import * as homeService from "service/homeService";
import styled from "styled-components";
import { color } from "GlobalStyles";

function Counter() {
  const [counterDetail, setCounterDetail] = useState([]);

  useEffect(() => {
    async function getCounterValues() {
      try {
        const counters = await homeService.counters();
        setCounterDetail(counters.data.data.counterSection[0]);
      } catch (err) {
        console.log(err);
      }
    }
    getCounterValues();
  }, []);

  return (
    <CounterSection className="text-center py-4">
      <div className="container-fluid bg-image-blue">
        <div className="row">
          <div className="col-md-4 col-lg-4 col-6 col-sm-12 py-5">
            <h3 className="counter-subtitle">No. of Categories</h3>
            <span className="counter text-white">{counterDetail.no_categories ? counterDetail.no_categories : "0"}</span>
          </div>
          <div className="col-md-4 col-lg-4 col-6 col-sm-12 py-5">
            <h3 className="counter-subtitle">No. Of Products</h3>
            <span className="counter text-white">{counterDetail.no_prods ? counterDetail.no_prods : "0"}</span>
          </div>
          <div className="col-md-4 col-lg-4 col-12 col-sm-12 py-5">
            <h3 className="counter-subtitle">No. Of Ports</h3>
            <span className="counter text-white">{counterDetail.no_ports ? counterDetail.no_ports : "0"}</span>
          </div>
        </div>
      </div>
    </CounterSection>
  );
}

const CounterSection = styled.section`
  width: 100%;
  .bg-image-blue {
    padding-top: 25px;
    background-color: ${color.colorPrimary};
  }

  .counter-subtitle {
    font: normal normal bold 1.875rem/2.5rem Nunito;
    color: ${color.colorSmallText};
  }

  .counter {
    font: normal normal 500 4rem/3.125rem Ubuntu;
  }
`;

export default React.memo(Counter);
