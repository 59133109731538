export const GET_ADDRESS_START = "GET_ADDRESS_START";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAIL = "GET_ADDRESS_FAIL";

export const GET_DEFAULTS_START = "GET_DEFAULTS_START";
export const GET_DEFAULTS_SUCCESS = "GET_DEFAULTS_SUCCESS";
export const GET_DEFAULTS_FAIL = "GET_DEFAULTS_FAIL";

export const ADD_ADDRESS_START = "ADD_ADDRESS_START";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL";

export const EDIT_ADDRESS_START = "EDIT_ADDRESS_START";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAIL = "EDIT_ADDRESS_FAIL";

export const GET_EDIT_ADDRESS_START = "GET_EDIT_ADDRESS_START";
export const GET_EDIT_ADDRESS_SUCCESS = "GET_EDIT_ADDRESS_SUCCESS";
export const GET_EDIT_ADDRESS_FAIL = "GET_EDIT_ADDRESS_FAIL";

export const DELETE_ADDRESS_START = "DELETE_ADDRESS_START";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAIL = "ADD_ADDRESS_FAIL";

export const ADDRESS_RESET = "ADDRESS_RESET";
