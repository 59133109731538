import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import addressIcon from 'assets/icons/manage-address.svg';
import Modal from 'component/Utilities/Modal';
import ConfimationPrompt from 'component/Utilities/ConfimationPrompt';
import { color } from 'GlobalStyles';
import { addressValidations } from 'validations/addressValidations';
import ForwardArrow from 'assets/icons/forward-arrow-white.svg';
import BackArrow from 'assets/icons/Back-arrow-white.svg';
import InlineError from 'Helpers/InlineError';
import * as generalService from 'service/generalService';
import * as addressActions from 'store/actions/addressActions';
import * as toastActions from 'store/actions/toastActions';
import { connect } from 'react-redux';
import { STORAGE_USER_TYPE } from 'config/config';
import { NavLink } from 'react-router-dom';

const UserAddress = (props) =>
{
  const {
    onGetUserAddresses,
    addresses,
    response,
    onAddressSelectHandler,
    onAddUserAddress,
    onDeleteUserAddress,
    onShowToast,
    onGetUserEditAddress,
    addressData,
    onEditUserAddress,
    addressResponseReset,
    billingAddressId,
    setBillingAddressId,
  } = props;

  const initialFormdata = {
    officeName: '',
    address_basic1: '',
    address_basic2: '',
    country: '',
    state: '',
    city: '',
    cityName: '',
    pincode: '',
    shipName: '',
    IMONo: '',
    portDetails: '',
    berthDetails: '',
    shipAgencyName: '',
    shipAgencyNamePIC: '',
    shipAgencyNumber: '',
    shipAgencyEmail: '',
    address: '',
    address2: '',
    addressType: '',
    shipTradingPattern: '',
    gstNo: '',
    panNo: '',
  };

  const [userAddresses, setUserAddresses] = useState([]);
  const [userBillingAddresses, setUserBillingAddresses] = useState('');
  const [userShippingAddresses, setUserShippingAddresses] = useState('');
  const [show, setShow] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [ports, setPorts] = useState([]);
  const [countryOptions, setCountryOptions] = useState();
  const [statesOptions, setStatesOptions] = useState();
  const [citiesOptions, setCitiesOptions] = useState();
  const [portOptions, setPortOptions] = useState();
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  const [details, setDetails] = useState('basicDetails');
  const [formData, setFormData] = useState(initialFormdata);
  const [errors, setErrors] = useState(null);
  const [showChangeAddress, setChangeAddress] = useState(false);
  const [selectedAddressType, setSelectedAddressType] = useState();
  // console.log("userBillingAddresses", userBillingAddresses);
  const onAddressCloseModal = () => setChangeAddress(false);

  const onCloseModal = () => setShow(false);
  const onShowAddressModal = () =>
  {
    setShow(true);
    setFormData(initialFormdata);
  };

  const onClosePrompt = () => setShowConfirmationPrompt(false);

  useEffect(() =>
  {
    onGetUserAddresses();
    return () =>
    {
      addressResponseReset();
    };
  }, [onGetUserAddresses, response, addressResponseReset]);

  //addresses response from api
  useEffect(() =>
  {
    if (addresses) {
      setUserAddresses(addresses);
      setUserBillingAddresses(addresses[0]);
      let shippingDefault = addresses && addresses.length > 0 && addresses.filter((eachAddress) => eachAddress.am_is_default === "1")
      setUserShippingAddresses(shippingDefault[0]);
      props.type !== 'location_modal' && setBillingAddressId(addresses[0] && addresses[0].am_id)
      props.type !== 'profile' && shippingDefault[0] && onAddressSelectHandler(shippingDefault[0].am_id, shippingDefault[0].am_ship_trade_pattern, props.type)
    } else {
      setUserAddresses([]);
    }
  }, [addresses, response]);

  useEffect(() =>
  {
    if (response === 'Successfully ! Added User Address' || response === 'Successfully ! Updated User Address') {
      onShowToast(response, 'success');
      onCloseModal();
      setDetails('basicDetails');
      onAddressSelectHandler('');
    }
  }, [response, onShowToast, onAddressSelectHandler]);

  useEffect(() =>
  {
    if (show) {
      async function getCountriesAPI()
      {
        try {
          const countries = await generalService.getCountries();
          setCountries(countries.data.data);
        } catch (err) {
          console.log(err);
        }
      }
      getCountriesAPI();
    }
  }, [show]);

  useEffect(() =>
  {
    async function getStatesAPI()
    {
      try {
        const states = await generalService.getStates(formData.country);
        setStates(states.data.data);
      } catch (err) {
        console.log(err);
      }
    }
    getStatesAPI();
  }, [formData.country]);

  useEffect(() =>
  {
    async function getCitiesAPI()
    {
      if (formData.state) {
        try {
          const cities = await generalService.getCities(formData.state);
          setCities(cities.data.data);
        } catch (err) {
          console.log(err);
        }
      }
    }
    getCitiesAPI();
  }, [formData.state]);

  useEffect(() =>
  {
    if (show) {
      async function getPortsAPI()
      {
        try {
          const ports = await generalService.getPorts();
          setPorts(ports.data.data);
        } catch (err) {
          console.log(err);
        }
      }
      getPortsAPI();
    }
  }, [show]);

  useEffect(() =>
  {
    let countryoptions = null;

    //countries Dropdown
    if (countries && countries.length > 0) {
      countryoptions = Object.keys(countries).map((eachCountry) =>
      {
        return (
          <option key={countries[eachCountry].id} value={countries[eachCountry].id}>
            {countries[eachCountry].name}
          </option>
        );
      });
    } else {
      countryoptions = <option value=''>Select Country</option>;
    }
    setCountryOptions(countryoptions);
  }, [countries]);

  useEffect(() =>
  {
    let statesoptions = null;

    //state dropdown
    if (states && states.length > 0) {
      statesoptions = Object.keys(states).map((eachState) =>
      {
        return (
          <option key={states[eachState].id} value={states[eachState].id}>
            {states[eachState].name}
          </option>
        );
      });
    } else {
      statesoptions = <option value=''>Select State</option>;
    }
    setStatesOptions(statesoptions);
  }, [states]);

  useEffect(() =>
  {
    let citiesoptions = null;
    //cities dropdown

    if (cities && cities.length > 0) {
      citiesoptions = Object.keys(cities).map((eachCity) =>
      {
        return (
          <option key={cities[eachCity].id} value={cities[eachCity].id}>
            {cities[eachCity].name}
          </option>
        );
      });
    } else {
      citiesoptions = <option value=''>Select City</option>;
    }
    setCitiesOptions(citiesoptions);
  }, [cities]);

  useEffect(() =>
  {
    let portsoptions = null;
    //ports dropdown

    if (ports && ports.length > 0) {
      portsoptions = Object.keys(ports).map((eachPort) =>
      {
        return (
          <option key={ports[eachPort].port_id} value={ports[eachPort].port_id}>
            {ports[eachPort].port_name}
          </option>
        );
      });
    } else {
      portsoptions = <option value=''>Select Port</option>;
    }
    setPortOptions(portsoptions);
  }, [ports]);

  // Set edit address response in formdata
  useEffect(() =>
  {
    if (addressData) {
      let newFormData = {
        id: addressData.am_id ? addressData.am_id : '',
        officeName: addressData.am_name ? addressData.am_name : '',
        address_basic1: addressData.am_address1 ? addressData.am_address1 : '',
        address_basic2: addressData.am_address2 ? addressData.am_address2 : '',
        country: addressData.am_country ? addressData.am_country : '',
        state: addressData.am_state ? addressData.am_state : '',
        city: addressData.am_town_city ? addressData.am_town_city : '',
        cityName: addressData.city_name ? addressData.city_name : '',
        pincode: addressData.am_zip_code ? addressData.am_zip_code : '',
        addressType: addressData.am_address_type ? addressData.am_address_type : '',
        shipName: addressData.am_name ? addressData.am_name : '',
        IMONo: addressData.am_imo_no ? addressData.am_imo_no : '',
        portDetails: addressData.port_id ? addressData.port_id : '',
        berthDetails: addressData.am_berth_details ? addressData.am_berth_details : '',
        shipAgencyName: addressData.am_ship_agency_name ? addressData.am_ship_agency_name : '',
        shipAgencyNamePIC: addressData.am_ship_agent_name ? addressData.am_ship_agent_name : '',
        shipAgencyNumber: addressData.am_ship_agency_no ? addressData.am_ship_agency_no : '',
        shipAgencyEmail: addressData.am_ship_agency_email ? addressData.am_ship_agency_email : '',
        address: addressData.am_address1 ? addressData.am_address1 : '',
        address2: addressData.am_address2 ? addressData.am_address2 : '',
        shipTradingPattern: addressData.am_ship_trade_pattern ? addressData.am_ship_trade_pattern : '',
        gstNo: addressData.am_gst_no ? addressData.am_gst_no : '',
        panNo: addressData.am_pan_detail ? addressData.am_pan_detail : '',
        billing_default: addressData.am_billing_default ? addressData.am_billing_default : '',
        registrationType: localStorage.getItem(STORAGE_USER_TYPE) ? localStorage.getItem(STORAGE_USER_TYPE) : '',
      };
      setFormData(newFormData);
    } else {
      // setFormData();
    }
  }, [addressData, response]);

  const onPrevClickHandler = () =>
  {
    setDetails('basicDetails');
  };

  const onNextClickHandler = () =>
  {
    const validationResponse = addressValidations(formData, details);
    setErrors(validationResponse.error);
    if (Object.keys(validationResponse.error).length === 0) {
      setDetails('moreDetails');
    } else {
      document.getElementsByName(validationResponse.field)[0].focus();
    }
  };

  const onChangeHandler = (e) =>
  {
    setErrors(null);
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'addressType') {
      // if (!formData.id) {
      setFormData((prevState) =>
      {
        return { ...initialFormdata, addressType: value };
      });
      // } else {
      //   setFormData((prevState) => {
      //     return { ...prevState, addressType: value };
      //   });
      // }
    } else {
      setFormData((prevState) =>
      {
        return { ...prevState, [name]: value };
      });
    }
  };

  const onSubmitHandler = (e) =>
  {
    e.preventDefault();
    const validationResponse = addressValidations(formData, details);
    setErrors(validationResponse.error);
    if (Object.keys(validationResponse.error).length === 0) {
      if (formData.id) {
        onEditUserAddress(formData);
      } else {
        onAddUserAddress(formData);
      }
      // onAddUserAddress(formData);
    } else {
      document.getElementsByName(validationResponse.field)[0].focus();
    }
  };

  const modalBody = (
    <div className='AddAddressModalBody'>
      <ul className='nav nav-pills w-100 d-flex justify-content-around mb-4' id='pills-tab' role='tablist'>
        <li className='nav-item flex-grow-1'>
          <span
            className={`w-75 m-auto text-center ${details === 'basicDetails' ? 'nav-link active' : 'nav-link'}`}
            id='pills-basicDetails-tab'
            data-toggle='pill'
            role='tab'
            name='basicDetails'
          >
            Basic Details
          </span>
        </li>
        {formData && formData.billing_default !== "1" &&
          <li className='nav-item flex-grow-1'>
            <span
              className={`w-75 m-auto text-center ${details === 'moreDetails' ? 'nav-link active' : 'nav-link'}`}
              id='pills-moreDetails-tab'
              data-toggle='pill'
              role='tab'
              name='moreDetails'
              style={{
                cursor: `${formData.addressType !== 'ship_address' ? 'not-allowed' : 'auto'}`,
                backgroundColor: `${formData.addressType !== 'ship_address' ? '#eee' : '#fff'}`,
              }}
            >
              More Details
            </span>
          </li>}
      </ul>
      <div className='tab-content' id='pills-tabContent'>
        <div
          className={`tab-pane h-100 ${details === 'basicDetails' ? 'show active' : 'fade'}`}
          id='pills-basicDetails'
          role='tabpanel'
          aria-labelledby='pills-basicDetails-tab'
        >
          <div className='pane-content'>
            <h3 className='account-heading mt-0'>Address Type</h3>
            <div className='row mt-3'>
              <div className='col-sm-4'>
                <div className='custom-control custom-radio custom-control-inline'>
                  <input
                    type='radio'
                    name='addressType'
                    className='custom-control-input'
                    id='head_office'
                    value='head_office'
                    onChange={onChangeHandler}
                    checked={formData.addressType === 'head_office'}
                  />
                  <label className='custom-control-label register__radio-label' htmlFor='head_office'>
                    Head Office Address
                  </label>
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='custom-control custom-radio custom-control-inline'>
                  <input
                    type='radio'
                    name='addressType'
                    className='custom-control-input'
                    id='branch_office'
                    value='branch_office'
                    onChange={onChangeHandler}
                    checked={formData.addressType === 'branch_office'}
                  />
                  <label className='custom-control-label register__radio-label' htmlFor='branch_office'>
                    Branch Office Address
                  </label>
                </div>
              </div>
              {formData && formData.billing_default !== "1" &&
                <div className='col-sm-4'>
                  <div className='custom-control custom-radio custom-control-inline'>
                    <input
                      type='radio'
                      name='addressType'
                      className='custom-control-input'
                      id='ship_address'
                      value='ship_address'
                      onChange={onChangeHandler}
                      checked={formData.addressType === 'ship_address'}
                    />
                    <label className='custom-control-label register__radio-label' htmlFor='ship_address'>
                      Ship Address
                    </label>
                  </div>
                </div>}
            </div>
            {errors && errors.addressType ? <InlineError text={errors.addressType} /> : <span>&nbsp;</span>}
            {formData.addressType !== 'ship_address' && (
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <div className='form-group mb-0'>
                        <label>
                          Name of Office<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='officeName'
                          placeholder='Enter name of office'
                          value={formData.officeName}
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}

                        />
                        {errors && errors.officeName ? <InlineError text={errors.officeName} /> : <span>&nbsp;</span>}
                      </div>
                    </div>

                    <div className='col-lg-6 '>
                      <div className='form-group mb-0'>
                        <label>
                          Address<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='address_basic1'
                          placeholder='Enter address'
                          value={formData.address_basic1}
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}

                        />
                        {errors && errors.address_basic1 ? <InlineError text={errors.address_basic1} /> : <span>&nbsp;</span>}
                      </div>
                    </div>

                    <div className='col-lg-6 '>
                      <div className='form-group mb-0'>
                        <label className='d-none d-md-block'>&nbsp;</label>
                        <input
                          type='text'
                          className='form-control'
                          name='address_basic2'
                          placeholder='Enter address'
                          value={formData.address_basic2}
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}

                        />
                        {errors && errors.address_basic2 ? <InlineError text={errors.address_basic2} /> : <span>&nbsp;</span>}
                      </div>
                    </div>

                    <div className='col-lg-6 '>
                      <div className='form-group mb-0'>
                        <label htmlFor='portDetails'>
                          Country<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <select name='country' id='country' className='mt-0' value={formData.country} onChange={(e) => onChangeHandler(e)} disabled={formData && formData.addressType ? false : true}>
                          {countryOptions && countryOptions.length > 0 && <option value=''>Select Country</option>}
                          {countryOptions}
                        </select>
                        {errors && errors.country ? <InlineError text={errors.country} /> : <span>&nbsp;</span>}
                      </div>
                    </div>

                    <div className='col-lg-6 '>
                      <div className='form-group mb-0'>
                        <label htmlFor='state'>State</label>

                        <select name='state' id='state' className='mt-0' value={formData.state} onChange={(e) => onChangeHandler(e)} disabled={formData && formData.addressType ? false : true}>
                          {statesOptions && statesOptions.length > 0 && <option value=''>Select State</option>}
                          {statesOptions}
                        </select>

                        {/* {errors && errors.state ? <InlineError text={errors.state} /> : <span>&nbsp;</span>} */}
                      </div>
                    </div>

                    <div className='col-lg-6'>
                      <div className='form-group mb-0'>
                        <label htmlFor='city'>
                          City<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        {formData.country === '101' ? (
                          <select name='city' id='city' className='mt-0' value={formData.city} onChange={(e) => onChangeHandler(e)} disabled={formData && formData.addressType ? false : true}>
                            {citiesOptions && citiesOptions.length > 0 && <option value=''>Select City</option>}
                            {citiesOptions}
                          </select>
                        ) : (
                          <input
                            type='text'
                            placeholder='Enter city'
                            className='form-control'
                            name='cityName'
                            value={formData.cityName}
                            onChange={(e) => onChangeHandler(e)}
                            disabled={formData && formData.addressType ? false : true}
                          />
                        )}
                        {errors && errors.cityName ? <InlineError text={errors.cityName} /> : <span>&nbsp;</span>}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group mb-0'>
                        <label>
                          Postal Code<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='pincode'
                          placeholder='Enter postal code'
                          value={formData.pincode}
                          maxLength='6'
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}
                        />
                        {errors && errors.pincode ? <InlineError text={errors.pincode} /> : <span>&nbsp;</span>}
                      </div>
                    </div>
                    {/* GST */}
                    <div className='col-lg-6'>
                      <div className='form-group mb-0'>
                        <label>
                          GST No. / VAT No. {formData.registrationType === "company" && <span className='text-danger font-weight-bold'>*</span>}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='gstNo'
                          placeholder='Enter GST / VAT Number'
                          value={formData.gstNo}
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}
                        />
                        {errors && errors.gstNo ? <InlineError text={errors.gstNo} /> : <span>&nbsp;</span>}
                      </div>
                    </div>
                    {/* Pan */}
                    <div className='col-lg-6'>
                      <div className='form-group mb-0'>
                        <label>
                          PAN Number<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='panNo'
                          placeholder='Enter PAN Number'
                          value={formData.panNo}
                          maxLength='10'
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}
                        />
                        {errors && errors.panNo ? <InlineError text={errors.panNo} /> : <span>&nbsp;</span>}
                      </div>
                    </div>
                    <div className='col-lg-12 text-right add-address-next-btn mt-1'>
                      {formData.addressType === 'ship_address' ? (
                        <button onClick={onNextClickHandler}>
                          <span>
                            Next <img src={ForwardArrow} alt='Forward' />
                          </span>
                        </button>
                      ) : (
                        <button onClick={onSubmitHandler}>
                          <span>
                            Submit <img src={ForwardArrow} alt='Forward' />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {formData.addressType === 'ship_address' && (
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 '>
                      <div className='form-group mb-0'>
                        <label htmlFor='shipName'>
                          Ship Name<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='shipName'
                          id='shipName'
                          placeholder='Enter ship name'
                          value={formData.shipName}
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}
                        />
                        {errors && errors.shipName ? <InlineError text={errors.shipName} /> : <span>&nbsp;</span>}
                      </div>
                    </div>

                    <div className='col-lg-6 '>
                      <div className='form-group mb-0'>
                        <label htmlFor='IMONo'>
                          IMO No.<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='IMONo'
                          id='IMONo'
                          maxLength='7'
                          placeholder='Eg: 1245665'
                          value={formData.IMONo}
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}
                        />
                        {errors && errors.IMONo ? <InlineError text={errors.IMONo} /> : <span>&nbsp;</span>}
                      </div>
                    </div>

                    <div className='col-lg-6 '>
                      <div className='form-group mb-0'>
                        <label htmlFor='portDetails'>
                          Port<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <select
                          name='portDetails'
                          id='portDetails'
                          className='mt-0'
                          value={formData.portDetails}
                          onChange={(e) => onChangeHandler(e)}
                          disabled={formData && formData.addressType ? false : true}
                        >
                          {portOptions && portOptions.length > 0 && <option value=''>Select Port</option>}
                          {portOptions}
                        </select>

                        {errors && errors.portDetails ? <InlineError text={errors.portDetails} /> : <span>&nbsp;</span>}
                      </div>
                    </div>

                    <div className='col-lg-6 '>
                      <div className='form-group mb-0'>
                        <label htmlFor='berthDetails'>
                          Berth<span className='text-danger font-weight-bold'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='berthDetails'
                          id='berthDetails'
                          placeholder='Enter Berth Details'
                          value={formData.berthDetails}
                          onChange={onChangeHandler}
                          disabled={formData && formData.addressType ? false : true}
                        />
                        {errors && errors.berthDetails ? <InlineError text={errors.berthDetails} /> : <span>&nbsp;</span>}
                      </div>
                    </div>
                    <div className='col-lg-12 text-right add-address-next-btn mt-1'>
                      <button onClick={onNextClickHandler}>
                        <span>
                          Next <img src={ForwardArrow} alt='Forward' />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}{' '}
          </div>
        </div>

        {/* More Details tab */}
        <div
          className={`tab-pane ${details === 'moreDetails' ? 'show active' : 'fade'}`}
          id='pills-moreDetails'
          role='tabpanel'
          disabled
          aria-labelledby='pills-moreDetails-tab'
        >
          <div className='pane-content'>
            <div className='row'>
              <div className='col-lg-6 '>
                <div className='form-group mb-0'>
                  <label>
                    Ship Agency Name<span className='text-danger font-weight-bold'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='shipAgencyName'
                    placeholder='Enter here'
                    autoFocus
                    value={formData.shipAgencyName}
                    onChange={onChangeHandler}
                    disabled={formData && formData.addressType ? false : true}
                  />
                  {errors && errors.shipAgencyName ? <InlineError text={errors.shipAgencyName} /> : <span>&nbsp;</span>}
                </div>
              </div>
              <div className='col-lg-6 '>
                <div className='form-group mb-0'>
                  <label>
                    Ship Agent Name (PIC)<span className='text-danger font-weight-bold'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='shipAgencyNamePIC'
                    placeholder='Enter here'
                    value={formData.shipAgencyNamePIC}
                    onChange={onChangeHandler}
                    disabled={formData && formData.addressType ? false : true}
                  />
                  {errors && errors.shipAgencyNamePIC ? <InlineError text={errors.shipAgencyNamePIC} /> : <span>&nbsp;</span>}
                </div>
              </div>
              <div className='col-lg-6 '>
                <div className='form-group mb-0'>
                  <label htmlFor='shipAgencyNumber'>
                    Contact Number<span className='text-danger font-weight-bold'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='shipAgencyNumber'
                    placeholder='Enter here'
                    maxLength='10'
                    value={formData.shipAgencyNumber}
                    onChange={onChangeHandler}
                    disabled={formData && formData.addressType ? false : true}
                  />
                  {errors && errors.shipAgencyNumber ? <InlineError text={errors.shipAgencyNumber} /> : <span>&nbsp;</span>}
                </div>
              </div>
              <div className='col-lg-6 '>
                <div className='form-group mb-0'>
                  <label htmlFor='shipAgencyEmail'>
                    Email<span className='text-danger font-weight-bold'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='shipAgencyEmail'
                    placeholder='Enter here'
                    value={formData.shipAgencyEmail}
                    onChange={onChangeHandler}
                    disabled={formData && formData.addressType ? false : true}
                  />
                  {errors && errors.shipAgencyEmail ? <InlineError text={errors.shipAgencyEmail} /> : <span>&nbsp;</span>}
                </div>
              </div>
              <div className='col-lg-6 '>
                <div className='form-group mb-0'>
                  <label>
                    Address<span className='text-danger font-weight-bold'>*</span> <small> <span> (Please also mention State, Country etc.)</span></small>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='address'
                    placeholder='Address Line 1'
                    value={formData.address}
                    onChange={onChangeHandler}
                    disabled={formData && formData.addressType ? false : true}
                  />

                  {errors && errors.address ? <InlineError text={errors.address} /> : <span>&nbsp;</span>}
                </div>
              </div>
              <div className='col-lg-6 '>
                <div className='form-group mb-0'>
                  <label>
                    Postal Code<span className='text-danger font-weight-bold'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='pincode'
                    placeholder='Enter postal code'
                    value={formData.pincode}
                    onChange={onChangeHandler}
                    maxLength="6"
                    disabled={formData && formData.addressType ? false : true}
                  />
                  {errors && errors.pincode ? <InlineError text={errors.pincode} /> : <span>&nbsp;</span>}
                </div>
              </div>
              <div className='col-lg-12'>
                <h3 className='account-heading'>
                  Ship Trading Pattern<span className='text-danger font-weight-bold'>*</span>
                </h3>
                <div className='row my-3'>
                  <div className='col-sm-4'>
                    <div className='custom-control custom-radio custom-control-inline'>
                      <input
                        type='radio'
                        name='shipTradingPattern'
                        className='custom-control-input'
                        id='harbourOperation'
                        value='harbourOperation'
                        onChange={onChangeHandler}
                        checked={formData.shipTradingPattern === 'harbourOperation'}
                        disabled={formData && formData.addressType ? false : true}
                      />
                      <label className='custom-control-label register__radio-label' htmlFor='harbourOperation'>
                        Harbour Operation
                      </label>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='custom-control custom-radio custom-control-inline'>
                      <input
                        type='radio'
                        name='shipTradingPattern'
                        className='custom-control-input'
                        id='coastalVoyages'
                        value='coastalVoyages'
                        onChange={onChangeHandler}
                        checked={formData.shipTradingPattern === 'coastalVoyages'}
                        disabled={formData && formData.addressType ? false : true}
                      />
                      <label className='custom-control-label register__radio-label' htmlFor='coastalVoyages'>
                        Coastal Voyages
                      </label>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='custom-control custom-radio custom-control-inline'>
                      <input
                        type='radio'
                        name='shipTradingPattern'
                        className='custom-control-input'
                        id='FGVoyages'
                        value='FGVoyages'
                        onChange={onChangeHandler}
                        checked={formData.shipTradingPattern === 'FGVoyages'}
                        disabled={formData && formData.addressType ? false : true}
                      />
                      <label className='custom-control-label register__radio-label' htmlFor='FGVoyages'>
                        F.G Voyages
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-12 text-right add-address-next-btn mt-3'>
                <button onClick={onPrevClickHandler} className='mr-4'>
                  <img src={BackArrow} alt='backarrow' className='mr-2' />
                  <span>Prev</span>
                </button>
                <button onClick={(e) => onSubmitHandler(e)}>
                  <span>
                    Submit <img src={ForwardArrow} alt='Forward' />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const onDeleteAddressHandler = (id) =>
  {
    // setShowConfirmationPrompt(true);
    if (window.confirm('Are you sure you want to remove this address?')) {
      onDeleteUserAddress(id);
    }
  };

  const onGetAddressHandler = (id) =>
  {
    onShowAddressModal();
    onGetUserEditAddress(id);
  };



  const onShowAddressHandler = (type) =>
  {
    setChangeAddress(true);
    setSelectedAddressType(type);
  };

  const onPopUpAddressSelectHandler = (addressId) =>
  {
    let selectedAddress = userAddresses && userAddresses.length > 0 && userAddresses.filter((eachAddress) => eachAddress.am_id === addressId)

    if (selectedAddressType === 1) {
      setBillingAddressId('');
      setUserBillingAddresses(selectedAddress[0]);
    }
    else if (selectedAddressType === 2) {
      onAddressSelectHandler('', '', props.type)
      setUserShippingAddresses(selectedAddress[0]);
    }
    setChangeAddress(false);
  };

  const modalshowAddressBody = (
    <div className='AddAddressModalBody'>
      {userAddresses &&
        userAddresses.map((eachAddress) =>
        {

          return selectedAddressType !== 1 ? (<div
            className={`${props.type === 'location_modal' ? 'col-12' : 'col-lg-12 col-sm-12 p-0'}`}
            key={eachAddress.am_id}
            onClick={() => onPopUpAddressSelectHandler(eachAddress.am_id)}
          >
            <div
              className={`address-details ${eachAddress.am_id === props.defaultAddress && 'activeDefault'
                }`}
            >
              <div className='d-flex align-items-baseline justify-content-between'>
                <h6>
                  {eachAddress.am_address_type === 'head_office'
                    ? 'Head Office'
                    : eachAddress.am_address_type === 'ship_address'
                      ? 'Ship Address'
                      : 'Branch Office'}
                </h6>


              </div>
              <p className='text-capitalize mb-2'>{eachAddress.am_name}</p>
              {eachAddress.am_address_type === 'ship_address' ? (
                <>
                  <p>
                    Port : <span>{eachAddress.port_name}</span>
                  </p>
                  <p>
                    IMO No : <span className='font-weight-bold'>{eachAddress.am_imo_no}</span>
                  </p>
                  <p className='mb-2'>Email : {eachAddress.am_ship_agency_email}</p>
                </>
              ) : (
                <>
                  <p>
                    Address :{' '}
                    <span>
                      {eachAddress.am_address1} {eachAddress.am_address2 && `,${eachAddress.am_address2}`}
                    </span>
                  </p>
                  <p className='text-capitalize'>
                    City : <span className='font-weight-bold'>{eachAddress.city_name}</span>
                  </p>
                  <p className='mb-2'>Postal Code : {eachAddress.am_zip_code}</p>
                </>
              )}
              {/* {props.type === "cart" && (
                          <div className="d-flex ">
                            <p className="mb-1 font-weight-bold text-primary">Edit</p>
                            <p className="mb-1 font-weight-bold text-danger mx-3">Delete</p>
                          </div>
                        )} */}
            </div>
          </div>) : selectedAddressType === 1 && eachAddress.am_address_type !== "ship_address" && (
            <div
              className={`${props.type === 'location_modal' ? 'col-12' : 'col-lg-12 col-sm-12 p-0'}`}
              key={eachAddress.am_id}
              onClick={() => onPopUpAddressSelectHandler(eachAddress.am_id)}
            >
              <div
                className={`address-details ${eachAddress.am_id === props.defaultAddress && 'activeDefault'
                  }`}
              >
                <div className='d-flex align-items-baseline justify-content-between'>
                  <h6>
                    {eachAddress.am_address_type === 'head_office'
                      ? 'Head Office'
                      : eachAddress.am_address_type === 'ship_address'
                        ? 'Ship Address'
                        : 'Branch Office'}
                  </h6>


                </div>
                <p className='text-capitalize mb-2'>{eachAddress.am_name}</p>
                {eachAddress.am_address_type === 'ship_address' ? (
                  <>
                    <p>
                      Port : <span>{eachAddress.port_name}</span>
                    </p>
                    <p>
                      IMO No : <span className='font-weight-bold'>{eachAddress.am_imo_no}</span>
                    </p>
                    <p className='mb-2'>Email : {eachAddress.am_ship_agency_email}</p>
                  </>
                ) : (
                  <>
                    <p>
                      Address :{' '}
                      <span>
                        {eachAddress.am_address1} {eachAddress.am_address2 && `,${eachAddress.am_address2}`}
                      </span>
                    </p>
                    <p className='text-capitalize'>
                      City : <span className='font-weight-bold'>{eachAddress.city_name}</span>
                    </p>
                    <p className='mb-2'>Postal Code : {eachAddress.am_zip_code}</p>
                  </>
                )}
                {/* {props.type === "cart" && (
                          <div className="d-flex ">
                            <p className="mb-1 font-weight-bold text-primary">Edit</p>
                            <p className="mb-1 font-weight-bold text-danger mx-3">Delete</p>
                          </div>
                        )} */}
              </div>
            </div>
          )

        }
        )}
    </div>
  )





  return (
    userAddresses && userAddresses.length > 0 ?
      <>
        <ConfimationPrompt showModal={showConfirmationPrompt} closeModal={onClosePrompt} />
        <Wrapper>
          {/* <Modal showModal={show} closeModal={onCloseModal} modalBody={modalBody} heading="Add New Address" /> */}
          {ReactDOM.createPortal(
            <Modal showModal={show} closeModal={onCloseModal} modalBody={modalBody} heading='Add New Address' className='address-pop-up' />,
            document.getElementById('address-modal')
          )}
          <UserAddressContainer className='container-fluid'>
            {ReactDOM.createPortal(
              <Modal showModal={showChangeAddress} closeModal={onAddressCloseModal} modalBody={modalshowAddressBody} heading='Change Address' className='change-address-modal' />,
              document.getElementById('modal')
            )}
            <div className='row'>
              <div className={`col-12 ${props.type === 'location_modal' && 'pt-3'}`}>
                {/* <h4 className={`${props.type !== 'location_modal' ? 'address-select' : 'location-select'}`}>
                {props.type === 'cart' ? 'Select Delivery Address' : props.type === 'location_modal' ? 'Select Address' : 'Manage Address'}
                {props.type === 'location_modal' && (
                  <span> Select location and your preferred date of delivery to see product availability.</span>
                )}
              </h4> */}

                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='row'>

                      {userBillingAddresses && props.type !== 'location_modal' &&
                        <div className='col-lg-6'>
                          <div className='pt-2 d-flex'>
                            <p><strong>Select Billing Address</strong></p>
                            <span className='cursor-pointer change-popup ml-3' onClick={() => onShowAddressHandler(1)}>Change</span>
                          </div>
                          <div
                            onClick={() =>
                              props.type !== 'profile' && setBillingAddressId(userBillingAddresses.am_id)
                            }
                            className={`address-details ${userBillingAddresses.am_id === billingAddressId && 'active'} ${userBillingAddresses.am_id === props.defaultAddress && 'activeDefault'
                              }`}>
                            <div className='d-flex align-items-baseline justify-content-between'>
                              <h6>
                                {userBillingAddresses.am_address_type === 'head_office'
                                  ? 'Head Office'
                                  : userBillingAddresses.am_address_type === 'ship_address'
                                    ? 'Ship Address'
                                    : 'Branch Office'}
                              </h6>

                              <div className='icons d-flex align-items-center justify-content-between'>
                                <div class="popover__wrapper">
                                  <span className='cursor-pointer'><i className='fa fa-info-circle' ></i></span>
                                  <div class="popover__content">
                                    <p class="popover__message">To Add or Amend Billing Address and/or Delivery Address please visit <u><NavLink to='/user-profile/user-address'>My Address</NavLink></u>.</p>
                                  </div>
                                </div>
                                {/* <span
                                className='delete-icon text-danger cursor-pointer'
                                onClick={() => onDeleteAddressHandler(userBillingAddresses.am_id)}
                              >
                                <i className='fas fa-trash'></i>
                              </span> */}
                              </div>
                            </div>
                            <p className='text-capitalize mb-2'>{userBillingAddresses.am_name}</p>
                            {userBillingAddresses.am_address_type === 'ship_address' ? (
                              <>
                                <p>
                                  Port : <span>{userBillingAddresses.port_name}</span>
                                </p>
                                <p>
                                  IMO No : <span className='font-weight-bold'>{userBillingAddresses.am_imo_no}</span>
                                </p>
                                <p className='mb-2'>Email : {userBillingAddresses.am_ship_agency_email}</p>
                              </>
                            ) : (
                              <>
                                <p>
                                  Address :{' '}
                                  <span>
                                    {userBillingAddresses.am_address1} {userBillingAddresses.am_address2 && `,${userBillingAddresses.am_address2}`}
                                  </span>
                                </p>
                                <p className='text-capitalize'>
                                  City : <span className='font-weight-bold'>{userBillingAddresses.city_name}</span>
                                </p>
                                <p className='mb-2'>Postal Code : {userBillingAddresses.am_zip_code}</p>
                                {
                                  userBillingAddresses.am_billing_default === '1' &&
                                  <>
                                    <p><span>PAN No: </span><span>{userBillingAddresses.am_pan_detail}</span></p>
                                    <p><span>GST / VAT No: </span><span>{userBillingAddresses.am_gst_no}</span></p>
                                  </>
                                }
                              </>
                            )}
                          </div>
                        </div>}
                      {userShippingAddresses && (
                        <div className={` ${props.type === 'location_modal' ? 'col-lg-12' : 'col-lg-6'}`} key={userShippingAddresses.am_id}>
                          <div className='pt-2 d-flex'>
                            <p><strong>Select Delivery Address</strong></p>
                            <span className='cursor-pointer change-popup ml-3' onClick={() => onShowAddressHandler(2)}>Change</span>
                          </div>
                          <div

                            onClick={() =>
                              props.type !== 'profile' && onAddressSelectHandler(userShippingAddresses.am_id, userShippingAddresses.am_ship_trade_pattern, props.type)
                            }
                            className={`address-details ${userShippingAddresses.am_id === props.activeAddressId && 'active'} ${userShippingAddresses.am_id === props.defaultAddress && 'activeDefault'
                              }`}

                          >

                            <div className='d-flex align-items-baseline justify-content-between'>
                              <h6>
                                {userShippingAddresses.am_address_type === 'head_office'
                                  ? 'Head Office'
                                  : userShippingAddresses.am_address_type === 'ship_address'
                                    ? 'Ship Address'
                                    : 'Branch Office'}
                              </h6>

                              <div className='icons d-flex align-items-center justify-content-between'>
                                <div class="popover__wrapper">
                                  <span className='cursor-pointer'><i className='fa fa-info-circle' style={{ paddingLeft: '3px' }} ></i></span>
                                  <div class="popover__content">
                                    <p class="popover__message">To Add or Amend Billing Address and/or Delivery Address please visit <u><NavLink to='/user-profile/user-address'>My Address</NavLink></u>.</p>

                                  </div>
                                </div>
                                {/* <span
                                  className='delete-icon text-danger cursor-pointer'
                                  onClick={() => onDeleteAddressHandler(userShippingAddresses.am_id)}
                                >
                                  <i className='fas fa-trash'></i>
                                </span> */}
                              </div>
                            </div>
                            <p className='text-capitalize mb-2'>{userShippingAddresses.am_name}</p>
                            {userShippingAddresses.am_address_type === 'ship_address' ? (
                              <>
                                <p>
                                  Port : <span>{userShippingAddresses.port_name}</span>
                                </p>
                                <p>
                                  IMO No : <span className='font-weight-bold'>{userShippingAddresses.am_imo_no}</span>
                                </p>
                                <p className='mb-2'>Email : {userShippingAddresses.am_ship_agency_email}</p>
                              </>
                            ) : (
                              <>
                                <p>
                                  Address :{' '}
                                  <span>
                                    {userShippingAddresses.am_address1} {userShippingAddresses.am_address2 && `,${userShippingAddresses.am_address2}`}
                                  </span>
                                </p>
                                <p className='text-capitalize'>
                                  City : <span className='font-weight-bold'>{userShippingAddresses.city_name}</span>
                                </p>
                                <p className='mb-2'>Postal Code : {userShippingAddresses.am_zip_code}</p>
                              </>)}
                          </div>
                        </div>)
                      }

                    </div>

                  </div>


                  {/* {userAddresses.length < 3 && (
                  <div className={`${props.type === 'location_modal' ? 'col-12' : 'col-lg-6 col-sm-6'}`}>
                    <div className='manage-addr' onClick={onShowAddressModal}>
                      <h4>
                        <img src={addressIcon} alt='address' className='mr-1' />
                        Add Address
                      </h4>
                    </div>
                  </div>
                )} */}
                </div>
              </div>
            </div>
          </UserAddressContainer>
        </Wrapper>
      </>
      :
      <div className='col-md-4'>
        <NavLink to='/user-profile/user-address'>
          <button className='text-center mt-3 mr-3 mb-3' type='button'>
            <i className='fa fa-plus'></i> Add Address First
          </button>
        </NavLink>
      </div>
  );
};

const Wrapper = styled.div``;

const UserAddressContainer = styled.div`
  .location-select {
    font: normal normal bold 32px/32px Ubuntu;
    span {
      font: normal normal normal 16px/20px Nunito;
      color: #333333;
      display: inline-block;
      margin-top: 10px;
    }
  }
  .change-popup{
    color: #6868ff;
    font-size: 14px;
    text-decoration: underline;
  }
  .address-select {
    font: normal normal 600 20px/27px Nunito;
    color: ${color.colorTextPrimary};
    border-bottom: 1px solid ${color.colorSmallText};
    padding-bottom: 10px;
    margin-bottom: 10px;
    span {
      font: normal normal normal 16px/20px Nunito;
      color: #333333;
      display: inline-block;
      margin-top: 10px;
    }
  }
  .address-details {
    border: 2px solid ${color.colorSmallText};
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 0;
    height: 175px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background-color: ${color.colorWhite};
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bbc6c8;
      outline: 1px solid #f6f6f6;
      border-radius: 20px;
    }
    &.active {
      border: 2px solid ${color.colorPrimary};
    }
    &.activeDefault {
      border: 2px solid ${color.colorTextPrimary};
    }
    margin-bottom: 15px;
    .icons {
      font-size: 12px;
    }
    h6 {
      font: normal normal 600 16px/22px Nunito;
      color: ${color.colorBlack};
    }
    p {
      font: normal normal normal 14px/19px Nunito;
      color: ${color.colorDarkGrey};
      margin-bottom: 0.5rem !important;
      word-break: break-word;
    }
  }
  .manage-addr {
    border: 2px dashed ${color.colorSmallText};
    border-radius: 4px;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    h4 {
      font: normal normal 600 24px/32px Nunito;
      color: ${color.colorSmallText};
      cursor: pointer;
    }
  }
  .fa-info-circle{
    font-size: 16px;
    
  }
 
.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 16.5rem;
  top: 7rem;
  transform: translate(0, 10px);
  background-color: #28384b;
  padding: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: 52%;
  @media(max-width: 767px){
    left: 2.5rem;
    top: 8rem;
    width: 81%;
  }
 
  @media only screen and (min-width: 1350px) and (max-width: 1370px){
    left: 4.9rem;
    top: 7rem;
    width: 76%;
  }
 
  @media only screen and (device-width: 1280px) and (device-height: 720px){
    left: 1.9rem;
    top: 7rem;
    width: 85%;
  }
 
  @media only screen and (min-width: 1530px) and (max-width: 1540px){
    left: 116px;
    top: 110px;
    width: 70%;
  }
}

.popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    left: 293px;
    top: -19px;
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent #28384b transparent;
    transform: rotate(270deg);
    -webkit-transform: rotate(2deg);
    @media(max-width: 767px){
      left: 239px;
      top: -19px;
    }
    @media only screen and (min-width: 1350px) and (max-width: 1370px){
      left: 294px;
      top: -19px;
    }
    @media only screen and (device-width: 1280px) and (device-height: 720px){
      left: 313px;
      top: -19px;
    }
    @media only screen and (min-width: 1530px) and (max-width: 1540px){
      left: 307px;
      top: -19px;
    }
    
    
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
p.popover__message {
  text-align: center;
  margin-bottom: 0 !important; 
  font: normal normal 600 0.95rem/20px Nunito !important;
    color: #fff !important;
}
p.popover__message a {
    color: #fff !important;
}
`;

const mapStateToProps = (state) =>
{
  return {
    loading: state.Address.loading,
    serverError: state.Address.error,
    response: state.Address.response,
    addresses: state.Address.address,
    addressData: state.Address.addressData,
  };
};

const mapDispatchToProps = (dispatch) =>
{
  return {
    onGetUserAddresses: () => dispatch(addressActions.getAddressStart()),
    onDeleteUserAddress: (id) => dispatch(addressActions.deleteAddressStart(id)),
    onGetUserEditAddress: (id) => dispatch(addressActions.getEditAddressStart(id)),
    onAddUserAddress: (formdata) => dispatch(addressActions.addAddressStart(formdata)),
    onEditUserAddress: (formdata) => dispatch(addressActions.editAddressStart(formdata)),
    onShowToast: (toastData, type) => dispatch(toastActions.showToast(toastData, type)),
    addressResponseReset: () => dispatch(addressActions.addressResponseReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAddress);
