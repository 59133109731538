import React, { useState, useEffect } from 'react';
import FavouriteIconFilled from 'assets/icons/favorite_filled.svg';
import FavouriteIcon from 'assets/icons/favorite_outline.svg';
// import CompareIcon from "assets/icons/compare_arrows.svg";
import CalendarIcon from 'assets/icons/calendar-date.svg';
// import ShoppingCartWhite from "assets/icons/add_shopping_cart_white.svg";
import ShoppingCartGrey from 'assets/icons/add-shopping-cart-grey.svg';
import ShoppingCartBlue from 'assets/icons/add-shopping-cart-blue.svg';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { color } from 'GlobalStyles';
import { connect } from 'react-redux';
import * as userActions from 'store/actions/userActions';
import * as toastActions from 'store/actions/toastActions';
import Skeleton from 'react-loading-skeleton';

const ProductCard = (props) => {
  const { onAddToCart, onAddToWishlist, userReset, token, onRemoveFromWishlist, serverError, response, onShowToast } = props;
  const history = useHistory();
  const [showCalendarInfo, setShowCalendarInfo] = useState(false);

  const OnAddtoCartHandler = (productId) => {
    if (token) onAddToCart(productId, 1);
    else history.push({ pathname: '/login', state: { page: history.location.pathname } });
  };

  const OnAddtoWishlistHandler = (productId) => {
    if (token) onAddToWishlist(productId);
    else history.push({ pathname: '/login', state: { page: history.location.pathname } });
  };

  const onRemoveFromWishlistHandler = (wishlistId) => {
    if (token) {
      onRemoveFromWishlist(wishlistId);
    } else history.push({ pathname: '/login', state: { page: history.location.pathname } });
  };

  useEffect(() => {
    if (response) {
      onShowToast(response, 'success');
    }
    return () => {
      userReset();
    };
  }, [response, onShowToast, userReset]);

  useEffect(() => {
    if (serverError) {
      onShowToast(serverError, 'danger');
    }
    return () => {
      userReset();
    };
  }, [serverError, onShowToast, userReset]);

  return (
    <ProductCardSection>
      <ProductImage>
        {props.productImageUrl ? (
          <span data-toggle='tooltip' title={props.productName}>
            <NavLink to={`${props.pageLink}`} className='d-flex justify-content-center align-items-center h-100'>
              <img src={props.productImageUrl} alt={props.productImageName} className='img-fluid productImg' />
            </NavLink>
          </span>
        ) : (
          <Skeleton height={250} />
        )}
      </ProductImage>

      <div className='row'>
        <div className='col-12 px-4'>
          <span data-toggle='tooltip' title={props.productName}>
            <h4>{props.productName ? props.productName : <Skeleton />}</h4>
          </span>
         {/*  <div className='price '>{props.productActualPrice ? <h4>₹ {props.productActualPrice} </h4> : <Skeleton />}</div> */}
        </div>
      </div>

      {/* <div className='product-icons d-flex flex-column'>
        {props.productId && (
          <img
            src={props.isCart ? ShoppingCartBlue : ShoppingCartGrey}
            alt='carticon'
            className='cursor-pointer img-fluid'
            onClick={() => OnAddtoCartHandler(props.productId)}
          />
        )}
        {props.productId && (
          <img
            src={props.isWishListed ? FavouriteIconFilled : FavouriteIcon}
            alt='Wishlist'
            className='cursor-pointer img-fluid'
            onClick={() => (props.isWishListed ? onRemoveFromWishlistHandler(props.wishlist_id) : OnAddtoWishlistHandler(props.productId))}
          />
        )}
        {/* <img src={CompareIcon} alt="Compare" className="cursor-pointer" /> */}
       {/* {props.ports && (
          <div className='position-relative'>
            <img
              src={CalendarIcon}
              alt='Calendar'
              className='cursor-pointer img-fluid'
              onMouseOver={() => setShowCalendarInfo(!showCalendarInfo)}
              onMouseOut={() => setShowCalendarInfo(false)}
            />
            {showCalendarInfo && (
              <span className='tooltiptext'>
                <div className='table-responsive'>
                  <table className='table table-bordered table-sm text-white m-0'>
                    <tbody>
                      <tr>
                        <th scope='col'>Port</th>
                        <th scope='col'>Days</th>
                      </tr>
                      {props.ports &&
                        props.ports.map((eachPort) => (
                          <tr key={eachPort.port_id}>
                            <td>{eachPort.port_name}</td>
                            <td>{eachPort.no_of_days}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </span>
            )}
          </div>
        )}
      </div> */}
    </ProductCardSection>
  );
};

const ProductImage = styled.div`
  box-shadow: 0px 2px 4px ${color.colorBoxShadow};
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  height: 300px;
  .productImg {
    max-height: 90%;
  }
  @media (max-width: 767px){
  box-shadow: 0px 1px 6px #00000075;
  }
`;

const ProductCardSection = styled.div`
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  @media only screen and (max-width: 576px) {
    width: auto;
  }

  h4 {
    font: normal normal 800 17px/24px Nunito;
    color: ${color.colorBlack};
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.5s all;
    text-transform: uppercase;
  }

  .price h4 {
    font: normal normal 600 17px/24px Nunito;
    color: ${color.colorTextPrimary};
    @media only screen and (min-width: 1024px) and (max-width: 1200px) {
      font: normal normal 600 0.8rem/1.69rem Nunito;
    }
  }
  .strikedout-price {
    font: normal normal normal 12px/12px Nunito;
    color: ${color.colorSmallText};
    @media only screen and (min-width: 1024px) and (max-width: 1200px) {
      font: normal normal 600 0.6rem/1rem Nunito;
    }
  }
  .product-off {
    background: #40a751 0% 0% no-repeat padding-box;
    font: normal normal 600 12px/12px Nunito;
    color: ${color.colorWhite};
    text-align: center;
    height: 32px;
    @media only screen and (min-width: 1024px) and (max-width: 1200px) {
      font: normal normal 600 0.57rem/1rem Nunito;
      height: 28px;
    }
  }
  .product-off1 {
    background: ${color.colorWhite} 0% 0% no-repeat padding-box;
    height: 32px;
  }
  .to-cart {
    margin-top: 50px;
  }
  .product-icons {
    position: absolute;
    top: 10px;
    right: 10px;
    img {
      margin: 2px 0;
    }
  }
  .bordered-btn {
    border: 2px solid ${color.colorPrimary};
    color: ${color.colorPrimary};
    border-radius: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    &:focus {
      box-shadow: none !important;
    }
  }
  .to-cart button span {
    font: normal normal 600 1.25rem/1.69rem Nunito;
    @media only screen and (min-width: 1024px) and (max-width: 1200px) {
      font: normal normal 600 1rem/1.69rem Nunito;
    }
  }
  .to-cart button:hover:not(:disabled) {
    background-color: ${color.colorPrimary};
    color: ${color.colorWhite} !important;
  }
  .tooltiptext {
    width: auto;
    background-color: ${color.colorPrimary};
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 115%;
    transition: opacity 0.3s;
    &::after {
      content: '';
      position: absolute;
      top: 8%;
      right: -10%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent transparent ${color.colorPrimary};
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    loading: state.User.loading,
    serverError: state.User.error,
    response: state.User.response,
    token: state.Auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddToCart: (productId, qty) => dispatch(userActions.addToCartStart(productId, qty)),
    onAddToWishlist: (productId) => dispatch(userActions.addToWishlistStart(productId)),
    onRemoveFromWishlist: (wishlistId) => dispatch(userActions.removeFromWishlistStart(wishlistId)),
    onShowToast: (toastData, type) => dispatch(toastActions.showToast(toastData, type)),
    userReset: () => dispatch(userActions.userReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
