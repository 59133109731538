import * as toastConstants from "store/constants/toastConstants";
import { updateObject } from "shared/utility";

const initialState = {
  toastData: null,
  toastType: null,
  show: false,
};

const showToast = (state, action) => {
  return updateObject(state, { 
    toastData: action.toastData, 
    toastType: action.toastType, 
    show:true 
  });
};

const hideToast = (state, action) => {
  return updateObject(state, {
    toastData: null,
    show:false
  });
};

export const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case toastConstants.SHOW_TOAST:
      return showToast(state, action);
    case toastConstants.HIDE_TOAST:
      return hideToast(state, action);

    default:
      return state;
  }
};
