import * as authConstants from "store/constants/authConstants";

export const authStart = (userName, password, loginType) => {
  return {
    type: authConstants.AUTH_START,
    userName: userName,
    password: password,
    loginType: loginType,
  };
};

export const authSuccess = (token, name, comp_name) => {
  return {
    type: authConstants.AUTH_SUCCESS,
    token: token,
    name: name,
    comp_name: comp_name,
  };
};

export const authFail = (error) => {
  return {
    type: authConstants.AUTH_FAIL,
    error: error,
  };
};

export const sendOtpStart = (userName) => {
  return {
    type: authConstants.SEND_OTP_START,
    userName: userName,
  };
};

export const sendOtpSuccess = (userId, msg) => {
  return {
    type: authConstants.SEND_OTP_SUCCESS,
    userId: userId,
    response: msg,
  };
};

export const sendOtpFail = (error) => {
  return {
    type: authConstants.SEND_OTP_FAIL,
    error: error,
  };
};

export const forgotPasswordStart = (userId, otp, newPassword) => {
  return {
    type: authConstants.FORGOT_PASSWORD_START,
    userId: userId,
    otp: otp,
    newPassword: newPassword,
  };
};

export const forgotPasswordSuccess = (msg) => {
  return {
    type: authConstants.FORGOT_PASSWORD_SUCCESS,
    response: msg,
  };
};

export const forgotPasswordFail = (error) => {
  return {
    type: authConstants.FORGOT_PASSWORD_FAIL,
    error: error,
  };
};

export const logoutStart = () => {
  return {
    type: authConstants.LOGOUT_START,
  };
};

export const logoutSuccess = (response) => {
  return {
    type: authConstants.LOGOUT_SUCCESS,
    response: response,
  };
};

export const logoutFail = () => {
  return {
    type: authConstants.LOGOUT_FAIL,
  };
};

export const authReset = () => {
  return {
    type: authConstants.AUTH_RESET,
  };
};
