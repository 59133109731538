import axios from 'axios';
import * as Config from '../config/config';
var FormData = require('form-data');

export const getTopPicksForUser = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append('token', token ? token : 'void');
  return axios.post(Config.API_URL + 'buyer_api/buyer_top_picks', data);
};

export const categoryList = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  return axios.post(Config.API_URL + 'buyer_api/buyer_category', data);
};

export const brandList = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  return axios.post(Config.API_URL + 'product_api/product_brand_filter', data);
};

export const getMostSellingProduct = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  return axios.post(Config.API_URL + 'buyer_api/buyer_most_sellingProducts', data);
};

export const quickDeliveries = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  return axios.post(Config.API_URL + 'buyer_api/buyer_quick_deliveries', data);
};

export const getCountries = () =>
{
  return axios.get(Config.API_URL + 'Country');
};

export const getStates = (countryId) =>
{
  var data = new FormData();
  data.append('country', countryId);
  return axios.post(Config.API_URL + 'State', data);
};

export const getCities = (stateId) =>
{
  var data = new FormData();
  data.append('state', stateId);
  return axios.post(Config.API_URL + 'City', data);
};

export const getPorts = () =>
{
  return axios.get(Config.API_URL + 'Ports');
};

export const contact_us = (formdata) =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  data.append('fname', formdata.first_name);
  data.append('lname', formdata.last_name);
  data.append('email', formdata.emailId);
  data.append('phone', formdata.contact_no);
  data.append('message', formdata.message);

  return axios.post(Config.API_URL + 'buyer_api/contact_us', data);
};

export const getStoreTypesForUser = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  return axios.post(Config.API_URL + 'buyer_api/get_store_types', data);
};

export const careers = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  data.append('listing_status', '1');
  // return axios.post(Config.API_URL + 'buyer_api/careers', data);
  return axios.post(Config.API_URL + 'api/get_listings', data);
};

export const priceList = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  return axios.post(Config.API_URL + 'product_api/product_price_filter', data);
};

export const portList = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  return axios.post(Config.API_URL + 'buyer_api/buyer_port_list', data);
};

export const newArrivalFilterList = () =>
{
  var data = new FormData();
  data.append('Appkey', Config.APP_KEY);
  return axios.post(Config.API_URL + 'product_api/product_newarrival', data);
};

export const getSuggestionsBySearchName = (searchTerm, catId, subcatId) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append('token', token && token);
  data.append('search_text', searchTerm);
  data.append('cat_id', catId);
  data.append('subcat_id', subcatId);
  data.append('specifications', '');
  data.append('brand_name', '');
  return axios.post(Config.API_URL + 'product_api/serch_ref_product', data);
};

export const positionList = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append('token', token && token);
  data.append('listing_status', '1');
  return axios.post(Config.API_URL + 'api/get_listings', data);
}

export const getNotificationList = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append('token', token && token);
  return axios.post(Config.API_URL + 'buyer_api/get_notification', data);
}

export const readNotification = (id) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append('token', token && token);
  data.append('id', id ? id : "");
  return axios.post(Config.API_URL + 'buyer_api/read_notification', data);
}

export const getMemberList = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append("token", token);
  return axios.post(Config.API_URL + 'Member_report_api/buyer_fetch_members_for_company', data);
};

export const getOrderStatusList = () =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append("token", token);
  return axios.post(Config.API_URL + 'Member_report_api/OrderStatusList', data);
};

export const memberReportList = (memberId, OrderStatus, startDate, endDate) =>
{
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append('Appkey', Config.APP_KEY);
  data.append("token", token);
  data.append('Order_status', OrderStatus);
  data.append('member_id', memberId);
  data.append('start_date', startDate);
  data.append('end_date', endDate);

  return axios.post(Config.API_URL + 'Member_report_api/member_reports', data);
};


