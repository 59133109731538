import { call, put, takeLatest } from "redux-saga/effects";
import * as productConstants from "store/constants/productConstants";
import * as productActions from "store/actions/productActions";
import * as productService from "service/productService";
import { CATCH_ERROR } from "config/config";

export function* GetProductsByPageNameSaga(action) {
  try {
    const response = yield call(
      productService.pagesData,
      action.catType,
      action.categoryId,
      action.search_key,
      "",
      action.pageName,
      action.limit,
      action.offset,
      action.sortType,
      action.defaultAddress,
      action.preferredDeliveryDate,
      action.subCatId,
      action.brand,
      action.min,
      action.max,
      action.ratingVal,
      action.newArrival,
      action.sortDay,
      action.sortPort
    );
    yield put(productActions.getProductsByPageNameSuccess(response.data.data));
  } catch (error) {
    if (error.response) {
      yield put(productActions.getProductsByPageNameFail(error.response.data.msg));
    } else {
      yield put(productActions.getProductsByPageNameFail(CATCH_ERROR));
    }
  }
}

export function* productFlowWatcher() {
  yield takeLatest(productConstants.GET_PRODUCTS_BY_PAGE_NAME_START, GetProductsByPageNameSaga);
}
