import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import * as homeService from 'service/homeService';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

function Banner() {
  const bannerSettings = {
    dots: false,
    arrows: true,
    speed: 400,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const [bannerImages, setBannerImages] = useState([]);

  useEffect(() => {
    async function getHomeBanners() {
      try {
        const bannerImages = await homeService.getBannerImages();
        if (window.innerWidth < 767) {
          setBannerImages(bannerImages.data.data.bannerMobileData);
        }
        else {
          setBannerImages(bannerImages.data.data.bannerData);
        }
      } catch (err) {
        console.log(err);
      }
    }
    getHomeBanners();
  }, []);

  return (
    <BannerSection>
      {bannerImages && Object.keys(bannerImages).length > 0 ? (
        <Slider {...bannerSettings}>
          {bannerImages.map((eachImage, index) => (
            eachImage.pg_link_type !== 'seller' ? (
              <NavLink to={eachImage.record_type === 'category' ? eachImage.cat_id !== '0' ? `shop-by-category/${eachImage.cat_slug}` : `/${eachImage.cat_slug}` : `/${eachImage.pg_link}`} className='item' key={index}>
                {/* For Web */}
                <img src={eachImage.banner_img_url} alt={eachImage.banner_img} className='img-fluid' />

              </NavLink>
            ) : (
              <a href={`http://seller.shipstorespares.com/${eachImage.pg_link}`} target='_blank' rel='noopener noreferrer' key={index}>
                {/* For Web */}
                <img src={eachImage.banner_img_url} alt={eachImage.banner_img} className='img-fluid' />

              </a>
            )
          ))}

        </Slider>
      ) : (
        <Skeleton count='5' />
      )}
    </BannerSection>
  );
}

const BannerSection = styled.section`
  img {
    width: 100vw;
    max-height: 350px;
    @media only screen and (max-width: 576px) {
      min-height: 180px;
    }
  }
  .slick-arrow.slick-next{
    right: 2px !important;
    display: block;
    color: #28374c !important;
    background: #ebecec !important;
    height: 110px;
    width: 24px;
    z-index: 2;
    opacity: 1 !important;
  }
  .slick-arrow.slick-prev{
    left: 2px !important;
    display: block;
    color: #28374c !important;
    background: #ebecec !important;
    height: 110px;
    width: 24px;
    z-index: 2;
    opacity: 1 !important;
  }
`;
export default React.memo(Banner);
