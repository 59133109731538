import React, { Suspense, lazy } from 'react';

import 'App.scss';
import { SEO } from 'component/SEO/SEO';
import { Switch, Route, BrowserRouter as Routes } from 'react-router-dom';
import Home from 'component/Home/Home';
import GuestRoute from 'routes/GuestRoute';
import Loader from 'component/Utilities/Loader/Loader';
import UserRoute from 'routes/UserRoute';
import HeaderPages from 'component/HeaderPages/HeaderPages';
import ScrollToTop from 'component/Utilities/ScrollToTop/ScrollToTop';
import ReactGA from "react-ga4";

const Contact = lazy(() => import('component/Pages/ContactUs'));
const Seller = lazy(() => import('component/Pages/Seller'));
const Buyer = lazy(() => import('component/Pages/Buyer'));
const ComingSoon = lazy(() => import('component/Pages/ComingSoon'));
const ProductDetail = lazy(() => import('component/Products/ProductDetail'));
const OrderSuccess = lazy(() => import('component/Order/OrderSuccess'));
const SuccessForm = lazy(() => import('component/Pages/SuccessForm'));
const RfqForm = lazy(() => import('component/Pages/RfqForm'));
const OurProcess = lazy(() => import('component/Pages/OurProcess'));
const AboutUpdated = lazy(() => import('component/Pages/AboutUpdated'));
const Login = lazy(() => import('component/User/Login'));
const Register = lazy(() => import('component/User/Register'));
const ForgotPassword = lazy(() => import('component/User/ForgotPassword'));
const ProductList = lazy(() => import('component/Products/ProductList'));
const Cart = lazy(() => import('component/Cart/Cart'));
const Subsection = lazy(() => import('component/SubSection/Subsection'));
const SellerService = lazy(() => import('component/Pages/SellerServices'));
const FAQSection = lazy(() => import('component/Pages/FAQ'));
const Advertise = lazy(() => import('component/Pages/Advertise'));
const BlogsDetails = lazy(() => import('component/Blogs/BlogsDetails'));
const CategoriesList = lazy(() => import('component/Categories/CategoriesList'));
const FullDirectory = lazy(() => import('component/Layout/FullDirectory'));
const BlogList = lazy(() => import('component/Blogs/BlogList'));
const Careers = lazy(() => import('component/Pages/Careers'));
const CareersForm = lazy(() => import('component/Pages/CareersForm'));
const OrderDetails = lazy(() => import('component/AccountOverview/Orders/OrderDetails'));
const MemberOrderDetails = lazy(() => import('component/AccountOverview/Member/MemberOrderDetails'));
const TermsAndConditions = lazy(() => import('component/Pages/Documents/TermsAndConditions'));
const ProductFeedback = lazy(() => import('component/Products/ProductFeedback'));
const Checkout = lazy(() => import('component/Checkout/Checkout'));
const RequestNewProduct = lazy(() => import('component/AccountOverview/Requisitions/RequestNewProduct'));
const AccOverviewTabContent = lazy(() => import('component/AccountOverview/AccOverviewTabContent'));
const ProfileInfo = lazy(() => import('component/AccountOverview/ProfileInfo'));
const Orders = lazy(() => import('component/AccountOverview/Orders/Orders'));
const Wishlist = lazy(() => import('component/AccountOverview/Wishlist'));
const RequestedProductList = lazy(() => import('component/AccountOverview/Requisitions/RequestedProductList'));
const ProfileUserAddress = lazy(() => import('component/AccountOverview/ProfileUserAddress'));
const SecuritySettings = lazy(() => import('component/AccountOverview/SecuritySettings/SecuritySettings'));
const Dashboard = lazy(() => import('component/AccountOverview/Dashboard'));
const MyRequisition = lazy(() => import('component/AccountOverview/Requisitions/MyRequisition'));
const mailRedirection = lazy(() => import('component/Order/MailRedirection'));
const Notification = lazy(() => import('component/Utilities/NotificationPage'));
const AddMember = lazy(() => import('component/AccountOverview/Member/AddMember'));
const MemberReport = lazy(() => import('component/AccountOverview/Member/MemberReport'));
const MemberList = lazy(() => import('component/AccountOverview/Member/MemberList'));

// Google Analytics using GA4
ReactGA.initialize("G-SS5RMJLZ6G"); // Measurment Id
ReactGA.send({ hitType: "pageview", page: `/${window.location.pathname}` }); // location name and location search

export const App = () =>
{

  return (
    <>
      <SEO title='Ship Stores And Spares' />
      <Routes>
        <ScrollToTop />
        <Route component={RouteComponent}></Route>
      </Routes>
    </>
  );
};
const RouteComponent = () =>
{
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <GuestRoute path='/login' exact component={Login} />
        <GuestRoute path='/forgotpassword' exact component={ForgotPassword} />
        <GuestRoute path='/register' exact component={Register} />
        <UserRoute exact path='/cart' component={Cart} />
        <UserRoute exact path='/checkout' component={Checkout} />
        <UserRoute exact path='/order-success' component={OrderSuccess} />
        <Route exact path='/success-form' component={SuccessForm} />
        <Route exact path='/rfq-form' component={RfqForm} />
        <Route exact path='/' component={Home} />
        <Route exact path='/best-sellers' component={HeaderPages} />
        <Route exact path='/top-offers' component={HeaderPages} />
        <Route exact path='/new-releases' component={HeaderPages} />
        <Route exact path='/quick-deliveries' component={HeaderPages} />
        <Route exact path='/search-product/:type/:search' component={HeaderPages} />
        <Route exact path='/shop-by-category/:catSlug' component={Subsection} />
        <Route exact path='/products/:subcatSlug' component={ProductList} />
        <Route exact path='/about' component={AboutUpdated} />
        <Route exact path='/become-buyer' component={Buyer} />
        <Route exact path='/become-seller' component={Seller} />
        <Route exact path='/contact-us' component={Contact} />
        <Route exact path='/buyer-services' component={OurProcess} />
        <Route exact path='/advertise' component={Advertise} />
        <Route exact path='/blog/:blogSlug' component={BlogsDetails} />
        <Route exact path='/blogs/:blogCat?' component={BlogList} />
        <Route exact path='/seller-services' component={SellerService} />
        <Route exact path='/categories' component={CategoriesList} />
        <Route exact path='/full-directory' component={FullDirectory} />
        <Route exact path='/join-us' component={Careers} />
        <Route exact path='/join-us/form' component={CareersForm} />
        <Route exact path='/help-faq' component={FAQSection} />
        <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
        <Route exact path='/order-confirm-mail/:orderId' component={mailRedirection} />
        <UserRoute eact path='/feedback/:slugName/:supplierId' component={ProductFeedback} />
        <Route exact path='/coming-soon' component={ComingSoon} />
        <UserRoute exact path='/user-profile/account-overview' component={AccOverviewTabContent} />
        <UserRoute exact path='/user-profile/profile-details' component={ProfileInfo} />
        <UserRoute exact path='/user-profile/user-orders' component={Orders} />
        <UserRoute exact path='/user-profile/user-orders/:orderId' component={OrderDetails} />
        <UserRoute exact path='/user-profile/user-wishlist' component={Wishlist} />
        <UserRoute exact path='/user-profile/user-address' component={ProfileUserAddress} />

        <UserRoute exact path='/user-profile/user-reports' component={Dashboard} />
        <UserRoute exact path='/user-profile/user-settings' component={SecuritySettings} />
        <UserRoute exact path='/user-profile/requisitions/product-list' component={RequestedProductList} />
        <UserRoute exact path='/user-profile/requisitions/add' component={RequestNewProduct} />
        <UserRoute exact path='/user-profile/requisitions/list' component={MyRequisition} />
        <Route exact path='/user-profile/add-member' component={AddMember} />
        <Route exact path='/user-profile/member-report' component={MemberReport} />
        <Route exact path='/user-profile/member-list' component={MemberList} />
        <Route exact path='/user-profile/member-orders/:orderId' component={MemberOrderDetails} />
        <UserRoute exact path='/notification' component={Notification} />
        <Route exact path='/:productSlugName/:supplierId' component={ProductDetail} />

        <Route path='/' component={Home} />
      </Switch>
    </Suspense>
  );
};