import React from 'react';
import styled from "styled-components";
import { NavLink } from 'react-router-dom';

function Notification(props) {


	const listItems = props.notificationList && props.notificationList.notification.map((eachItem) =>
		<li key={eachItem.id}>
			<div>{eachItem.noti_text}</div>
			<div style={{ fontSize: '11px' }}>{eachItem.created_at}</div>
		</li>
	);

	return <>
		<NotificationDiv>
			<h3>Notification</h3>
			<ul className='notification-list'>
				{listItems}
				{/* <span onClick={toggleReadMore} className='view-more'>
          {isReadMore ? 'View all' : 'View less'}
        </span> */}
				<NavLink to='/notification'>
					<span className='view-more' onClick={() => props.setShowNotification(!props.showNotification)}>
						View all
					</span>
				</NavLink>
			</ul>
		</NotificationDiv>
	</>
};

const NotificationDiv = styled.div`
background: #fff;
width: 300px;
height: auto;
position: absolute;
left: -100px;
top: 45px;
box-shadow: 0 5px 15px #747474;
z-index: 9;
h3 {
	background: #bbc6c8;
	color: #28384b;
	padding: 10px;
	margin-bottom: 0;
	font: normal normal 600 18px Ubuntu;
}

@media only screen and (min-width: 1350px) and (max-width: 1370px){
	left: -148px;
}



ul {
	padding: 10px;
	list-style-type: none;
	height: 150px;
	overflow: auto;
	li {
		list-style: none;
		font: normal normal 600 14px/25px Nunito !important;
		color: #333333 !important;
		cursor: pointer;

		display: block;
		border-bottom: 1px solid #e8e8e8;
		padding-bottom: 10px;
		margin-bottom: 10px;
		&:last-child {
			border-bottom: 0;
			margin-bottom: 0px;
		}

    .message-read{
  background: #bbc6c8;
}
	}
  .view-more{
    font: normal normal 600 14px Nunito !important;
    color: #ffffff !important;
    cursor: pointer;
    background: #28384b;
    width: 100%;
    display: flex;
    padding: 10px;
    margin-bottom: 0;
    text-align: center;
    justify-content: center;
    
  }
}

.notification-list::-webkit-scrollbar {
	width: .3em;
	background-color: #d8d8d8;
	border-radius: 20px;
}

.notification-list::-webkit-scrollbar-track {
	box-shadow: none;
}

.notification-list::-webkit-scrollbar-thumb {
	background-color: #28384b;
	outline: 1px solid #28384b;
	border-radius: 20px;
	width: .3em;
}

`;


export default Notification;