import axios from "axios";
import * as Config from "../config/config";
var FormData = require("form-data");

export const getBannerImages = () => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  return axios.post(Config.API_URL + "buyer_api/buyer_home_banner", data);
};

export const productsByCategory = () => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  return axios.post(Config.API_URL + "buyer_api/buyer_shop_bycategory", data);
};

export const counters = () => {
  var data = new FormData();
  data.append("Appkey", Config.APP_KEY);
  return axios.post(Config.API_URL + "buyer_api/buyer_counter_section", data);
};
