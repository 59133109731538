import React,{useEffect} from "react";
import checkIcon from "assets/images/check.svg";
import errorIcon from "assets/images/error.svg";
import infoIcon from "assets/images/info.svg";
import warningIcon from "assets/images/warning.svg";
import styled from "styled-components";
import { color } from "GlobalStyles";
import * as toastActions from "store/actions/toastActions";
import { connect } from "react-redux";

const Toast = (props) => {
  useEffect(() => {
    const timer = setTimeout(
    () => props.onHideToast(),
      3000);
    return () => clearTimeout(timer);
  });

  
  return (
    <NoticationBox className={`bottom-center ${ props.show ? "d-block":"d-none"}`}>
      <NotificationToast className="bottom-center d-flex align-items-center">
        <NotificationImage>
          <img
            src={`${
              props.type === "success" ? checkIcon : props.type === "danger" ? errorIcon : props.type === "info" ? infoIcon : warningIcon
            }`}
            alt="icon"
          />
        </NotificationImage>
        <NotificationMessage>{props.message}</NotificationMessage>
      </NotificationToast>
    </NoticationBox>
  );
};

const NotificationMessage = styled.p`
  margin: 0;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
`;

const NotificationImage = styled.div`
  margin-right: 15px;
  img {
    width: 30px;
    height: 30px;
  }
`;

const NoticationBox = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  z-index: 1045;
  max-width: 600px;
`;
const NotificationToast = styled.div`
  background: ${color.colorBlack};
  color: ${color.colorWhite};
  padding: 15px 25px 15px 10px;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  margin: 0 0 6px;
  border-radius: 5px;
  opacity: 1;
  display:none;
  @media (max-width: 450px) {
    width: 80vw;
  }
  &:hover {
    opacity: 1;
  }
`;

const mapStateToProps = (state) => {
  return {
    type : state.Toast.toastType,
    show : state.Toast.show,
    message:state.Toast.toastData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHideToast: () => dispatch(toastActions.hideToast()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);