import * as toastConstants from 'store/constants/toastConstants';

export const showToast = (toastData,toastType) => {
  return {
    type: toastConstants.SHOW_TOAST,
    toastData,
    toastType
  }
}

export const hideToast = () => {
  return {
    type: toastConstants.HIDE_TOAST,
  }
}