import * as userConstants from "store/constants/userConstants";
import { updateObject } from "shared/utility";

const initialState = {
  error: null,
  loading: false,
  response: null,
  cartItems: [],
  billing: null,
  wishlistItems: [],
};

const createAccountStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const createAccountSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const createAccountFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const cartListStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const cartListSuccess = (state, action) =>
{
  return updateObject(state, {
    cartItems: action.cartItems,
    status: action.status,
    billing: action.billing,
    cartListRes: action.cartListRes,
    addressInfo: action.addressInfo,
    error: null,
    loading: false,
  });
};

const cartListFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
    cartItems: [],
    billing: null,
  });
};

const getWishListStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const getWishListSuccess = (state, action) =>
{
  return updateObject(state, {
    wishlistItems: action.wishlistItems,
    error: null,
    loading: false,
  });
};

const getWishListFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clearWishListStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const clearWishListSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    wishlistItems: [],
    error: null,
    loading: false,
  });
};

const clearWishListFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const addToCartStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const addToCartSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const addToCartFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const addToWishlistStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const addToWishlistSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const addToWishlistFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const removeFromCartStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const removeFromCartSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const removeFromCartFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const removeFromWishlistStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const removeFromWishlistSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const removeFromWishlistFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updateCartStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const updateCartSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const updateCartFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};


const saveLaterStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const saveLaterSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const saveLaterFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const removeAllFromCartStart = (state, action) =>
{
  return updateObject(state, { error: null, loading: true });
};

const removeAllFromCartSuccess = (state, action) =>
{
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
  });
};

const removeAllFromCartFail = (state, action) =>
{
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};



const userReset = (state, action) =>
{
  return updateObject(state, {
    error: null,
    loading: false,
    response: null,
  });
};

export const userReducer = (state = initialState, action) =>
{
  switch (action.type) {
    case userConstants.CREATE_ACCOUNT_START:
      return createAccountStart(state, action);
    case userConstants.CREATE_ACCOUNT_SUCCESS:
      return createAccountSuccess(state, action);
    case userConstants.CREATE_ACCOUNT_FAIL:
      return createAccountFail(state, action);

    case userConstants.LIST_CART_ITEMS_START:
      return cartListStart(state, action);
    case userConstants.LIST_CART_ITEMS_SUCCESS:
      return cartListSuccess(state, action);
    case userConstants.LIST_CART_ITEMS_FAIL:
      return cartListFail(state, action);

    case userConstants.LIST_WISHLIST_ITEMS_START:
      return getWishListStart(state, action);
    case userConstants.LIST_WISHLIST_ITEMS_SUCCESS:
      return getWishListSuccess(state, action);
    case userConstants.LIST_WISHLIST_ITEMS_FAIL:
      return getWishListFail(state, action);

    case userConstants.CLEAR_WISHLIST_ITEMS_START:
      return clearWishListStart(state, action);
    case userConstants.CLEAR_WISHLIST_ITEMS_SUCCESS:
      return clearWishListSuccess(state, action);
    case userConstants.CLEAR_WISHLIST_ITEMS_FAIL:
      return clearWishListFail(state, action);

    case userConstants.ADD_TO_CART_START:
      return addToCartStart(state, action);
    case userConstants.ADD_TO_CART_SUCCESS:
      return addToCartSuccess(state, action);
    case userConstants.ADD_TO_CART_FAIL:
      return addToCartFail(state, action);

    case userConstants.REMOVE_FROM_CART_START:
      return removeFromCartStart(state, action);
    case userConstants.REMOVE_FROM_CART_SUCCESS:
      return removeFromCartSuccess(state, action);
    case userConstants.REMOVE_FROM_CART_FAIL:
      return removeFromCartFail(state, action);

    case userConstants.ADD_TO_WISHLIST_START:
      return addToWishlistStart(state, action);
    case userConstants.ADD_TO_WISHLIST_SUCCESS:
      return addToWishlistSuccess(state, action);
    case userConstants.ADD_TO_WISHLIST_FAIL:
      return addToWishlistFail(state, action);

    case userConstants.REMOVE_FROM_WISHLIST_START:
      return removeFromWishlistStart(state, action);
    case userConstants.REMOVE_FROM_WISHLIST_SUCCESS:
      return removeFromWishlistSuccess(state, action);
    case userConstants.REMOVE_FROM_WISHLIST_FAIL:
      return removeFromWishlistFail(state, action);

    case userConstants.UPDATE_CART_START:
      return updateCartStart(state, action);
    case userConstants.UPDATE_CART_SUCCESS:
      return updateCartSuccess(state, action);
    case userConstants.UPDATE_CART_FAIL:
      return updateCartFail(state, action);

    case userConstants.SAVE_LATER_START:
      return saveLaterStart(state, action);
    case userConstants.SAVE_LATER_SUCCESS:
      return saveLaterSuccess(state, action);
    case userConstants.SAVE_LATER_FAIL:
      return saveLaterFail(state, action);

    case userConstants.REMOVE_ALL_FROM_CART_START:
      return removeAllFromCartStart(state, action);
    case userConstants.REMOVE_ALL_FROM_CART_SUCCESS:
      return removeAllFromCartSuccess(state, action);
    case userConstants.REMOVE_ALL_FROM_CART_FAIL:
      return removeAllFromCartFail(state, action);

    case userConstants.USER_RESET:
      return userReset(state, action);

    case userConstants.RESET:
      return initialState;

    default:
      return state;
  }
};
