export const addressValidations = (formdata, type) => {
  const response = {
    field: '',
    error: {},
  };

  const regIMO = /^\d{7}$/i;
  // const regEmail = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const regDigits = /^[0-9]+$/;
  const regGST = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}$/;
  const regPAN = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  if (type === 'basicDetails') {
    if (!formdata.addressType || formdata.addressType.trim() === '') {
      response.field = 'addressType';
      response.error.addressType = 'Please select address type';
    } else if (formdata.addressType !== 'ship_address') {
      if (!formdata.officeName || formdata.officeName.trim() === '') {
        response.field = 'officeName';
        response.error.officeName = 'Please enter office name';
      } else if (!formdata.address_basic1 || formdata.address_basic1.trim() === '') {
        response.field = 'address_basic1';
        response.error.address_basic1 = 'Please enter the address';
      } else if (!formdata.country || formdata.country.trim() === '') {
        response.field = 'country';
        response.error.country = 'Please select the country';
      }
      //  else if (!formdata.state || formdata.state.trim() === '') {
      //   response.field = 'state';
      //   response.error.state = 'Please enter the state';
      // }
      else if (formdata.country === '101' && (!formdata.city || formdata.city.trim() === '')) {
        response.field = 'city';
        response.error.city = 'Please enter the city';
      } else if (formdata.country !== '101' && (!formdata.cityName || formdata.cityName.trim() === '')) {
        response.field = 'cityName';
        response.error.cityName = 'Please enter the city';
      } else if (!formdata.pincode || formdata.pincode.trim() === '' || !regDigits.test(formdata.pincode.trim())) {
        response.field = 'pincode';
        response.error.pincode = 'Please enter valid postal code';
      } else if (formdata.pincode.length < 6) {
        response.field = 'pincode';
        response.error.pincode = 'Postal code cannot be less than 6 characters';
      } else if (formdata.registrationType === 'company' && (!formdata.gstNo || formdata.gstNo.trim() === '' || !regGST.test(formdata.gstNo))) {
        response.field = 'gstNo';
        response.error.gstNo = 'Please enter a valid GST NO.';
      } else if (!formdata.panNo || formdata.panNo.trim() === '' || !regPAN.test(formdata.panNo)) {
        response.field = 'panNo';
        response.error.panNo = 'Please enter a valid PAN Number';
      }
    } else if (formdata.addressType === 'ship_address') {
      if (!formdata.shipName || formdata.shipName.trim() === '') {
        response.field = 'shipName';
        response.error.shipName = 'Please enter name of the ship';
      } else if (!formdata.IMONo || formdata.IMONo.trim() === '' || !regIMO.test(formdata.IMONo.trim())) {
        response.field = 'IMONo';
        response.error.IMONo = 'Please type 7 Digit Number';
      } else if (!formdata.portDetails || formdata.portDetails.trim() === '') {
        response.field = 'portDetails';
        response.error.portDetails = 'Please enter port details';
      } else if (!formdata.berthDetails || formdata.berthDetails.trim() === '') {
        response.field = 'berthDetails';
        response.error.berthDetails = 'Please enter berth details';
      }
      // else if (!formdata.deliveryDate || formdata.deliveryDate.trim() === "") {
      //   response.field = "deliveryDate";
      //   response.error.deliveryDate = "Please enter delivery date";
      // }
    }
  } else if (type === 'moreDetails' && formdata.addressType === 'ship_address') {
    if (!formdata.shipAgencyName || formdata.shipAgencyName.trim() === '') {
      response.field = 'shipAgencyName';
      response.error.shipAgencyName = 'Please enter ship agency name';
    } else if (!formdata.shipAgencyNamePIC || formdata.shipAgencyNamePIC.trim() === '') {
      response.field = 'shipAgencyNamePIC';
      response.error.shipAgencyNamePIC = 'Please enter ship agency name PIC';
    } else if (!formdata.address || formdata.address.trim() === '') {
      response.field = 'address';
      response.error.address = 'Please enter the address';
    }
    else if (!formdata.pincode || formdata.pincode.trim() === '' || !regDigits.test(formdata.pincode.trim())) {
      response.field = 'pincode';
      response.error.pincode = 'Please enter valid postal code';
    } else if (formdata.pincode.length < 6) {
      response.field = 'pincode';
      response.error.pincode = 'Postal code cannot be less than 6 characters';
    }
    else if (!formdata.shipTradingPattern || formdata.shipTradingPattern.trim() === '') {
      response.field = 'shipTradingPattern';
      response.error.shipTradingPattern = 'Please select the ship trading pattern';
    }
    //  else if (!formdata.arrivalTime || formdata.arrivalTime.trim() === "") {
    //   response.field = "arrivalTime";
    //   response.error.arrivalTime = "Please enter the arrival time";
    // } else if (!formdata.departureTime || formdata.departureTime.trim() === "") {
    //   response.field = "departureTime";
    //   response.error.departureTime = "Please enter the departure time";
    // }
  }

  return response;
};
