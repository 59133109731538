import { color } from 'GlobalStyles';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const CategoryCard1 = (props) => {
  return (
    <CategoryCardContainer className='row align-items-center'>
      <div className='col-lg-5 col-sm-5 col-5 overflow-hidden p-0'>
        <img src={props.imgUrl} alt={props.productName} className='img-fluid productImg' />
      </div>
      <div className='col-lg-7 col-sm-7 col-7'>
        <span data-toggle='tooltip' title={props.productName}>
          <h3 className='overflowText'>{props.productName && props.productName.toLowerCase()}</h3>
        </span>
        <NavLink to={`${props.pageLink}`}>
          <button className='btn bordered-btn px-0 w-100'>View more </button>
        </NavLink>
      </div>
    </CategoryCardContainer>
  );
};

const CategoryCardContainer = styled.div`
  box-shadow: 0px 4px 8px ${color.colorBoxShadow};
  width: ${(props) => (props.page === 'mobile' ? '100%' : '92% !important')};
  margin: ${(props) => (props.page === 'mobile' ? '0.5rem 0rem 1.5rem' : '0.5rem 0.9rem 1.5rem')};
  height: 200px;
  .productImg {
    max-height: 170px;
    margin: auto;
  }
  h3 {
    font: normal normal normal 20px/26.67px Nunito;
    color: ${color.colorTextPrimary};
    text-transform: capitalize;
    height: 50px;
  }
`;
