import axios from "axios";
import * as Config from "../config/config";
var FormData = require("form-data");

export const getProductDetails = (productSlug, productSupplierId) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token ? token : "void");
  data.append("p_slug", productSlug);
  data.append("sup_id", productSupplierId);
  return axios.post(Config.API_URL + "buyer_api/buyer_product_details", data);
};

export const productData = (defaultAddress, preferredDeliveryDate, cat_slug, pageName, limit, offset, sortType, subCatId, brand, min, max, ratingVal, newArrival, catId) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token ? token : "void");
  data.append("address_id", defaultAddress);
  data.append("prefered_delivery_date", preferredDeliveryDate);
  data.append("api_name", pageName);
  data.append("cat_slug", cat_slug);
  data.append("limit", limit);
  data.append("page", offset);
  data.append("sort", sortType);
  data.append("cat_id", catId);
  data.append("subCatId", subCatId);
  data.append("brand", brand);
  data.append("minprice", min);
  data.append("maxprice", max);
  data.append("rating", ratingVal);
  data.append("arrival", newArrival);
  return axios.post(Config.API_URL + "product_api/search_product_list", data);
};

export const pagesData = (catType, categoryId, search_key, cat_slug, pageName, limit, offset, sortType, defaultAddress, preferredDeliveryDate, subCatId, brand, min, max, ratingVal, newArrival, sortDay, sortPort) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token ? token : "void");
  data.append("api_name", pageName);
  data.append("search_key", search_key);
  data.append("cat_slug", cat_slug);
  // if (catType === "category" && pageName === "search_prod") {
  data.append("cat_id", categoryId);
  // } else if (catType === "subcategory" && pageName === "search_prod") {
  data.append("subCatId", subCatId);
  // }
  data.append("limit", limit);
  data.append("page", offset);
  data.append("sort", sortType);
  data.append("address_id", defaultAddress);
  data.append("prefered_delivery_date", preferredDeliveryDate);
  data.append("brand", brand);
  data.append("minprice", min);
  data.append("maxprice", max);
  data.append("rating", ratingVal);
  data.append("arrival", newArrival);
  data.append("portdays", sortDay ? sortDay : '');
  data.append("portid", sortPort ? sortPort : '');
  
  return axios.post(Config.API_URL + "product_api/search_product_list", data);
};

export const productFeedBack = (action, product_id, reviewId, rating, title, description) => {
  var data = new FormData();
  const token = localStorage.getItem(Config.STORAGE_TOKEN);
  data.append("Appkey", Config.APP_KEY);
  data.append("token", token ? token : "void");
  data.append("action", action);
  data.append("product_id", product_id);
  data.append("pr_id", reviewId);
  data.append("pr_rating", rating);
  data.append("pr_title", title);
  data.append("pr_message", description);

  return axios.post(Config.API_URL + "buyer_api/feedback_rud", data);
};
