import { color } from 'GlobalStyles';
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const BlogSection = (props) => {
  return (
    <BlogContainer className='card mx-3 border-0'>
      <img className='img-fluid rounded-0' src={props.blogImageUrl} alt={props.blogImageName} />
      <div className='card-body '>
        <p className='card-text text-black d-flex justify-content-between'>
          <span>{props.blogDate}</span> <span>- {props.blogAuthor}</span>
        </p>
        <h3 className='blog-title'>{props.blogTitle}</h3>
        <div className='mob-right'>
        <NavLink className='button mr-3 mob-m0' to={`/blog/${props.blogSlug}`}>
          <button className='btn bordered-btn'>Read More</button>
        </NavLink>
        </div>
      </div>
    </BlogContainer>
  );
};

const BlogContainer = styled.div`
  img {
    height: 216px;
  }
  .blog-title {
    font: normal normal 600 22px/27px Nunito;
    color: ${color.colorPrimary};
    margin-bottom: 10px;
    min-height: 50px;
  }
  .card-text {
    font: normal normal 600 16px/19px Nunito;
  }
  @media (max-width: 767px){
    .mob-right{
      text-align: right;
      margin-top: 20px;
    }
    .mob-m0{
      margin-right: 0 !important;
    }
  }
`;
