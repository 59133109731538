import * as productConstants from "store/constants/productConstants";
import { updateObject } from "shared/utility";

const initialState = {
  error: null,
  loading: false,
  response: null,
  products: [],
  offset: 1,
  limit: 12,
  sortType: "",
  currentPath: "",
  startIndex: 1,
  endIndex: "",
};

const getProductsByPageNameStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getProductsByPageNameSuccess = (state, action) => {
  return updateObject(state, {
    products: action.productList,
    error: null,
    loading: false,
  });
};

const getProductsByPageNameFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    products: [],
  });
};

const getProductData = (state, action) => {
  return updateObject(state, {
    offset: action.offset,
    startIndex: action.startIndex,
    limit: action.limit,
    sortType: action.sortType,
    endIndex: action.endIndex,
    currentPath: action.currentPath,
  });
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case productConstants.GET_PRODUCTS_BY_PAGE_NAME_START:
      return getProductsByPageNameStart(state, action);
    case productConstants.GET_PRODUCTS_BY_PAGE_NAME_SUCCESS:
      return getProductsByPageNameSuccess(state, action);
    case productConstants.GET_PRODUCTS_BY_PAGE_NAME_FAIL:
      return getProductsByPageNameFail(state, action);
    case productConstants.GET_PRODUCTS_DATA:
      return getProductData(state, action);

    case productConstants.PRODUCTS_RESET:
      return initialState;
    default:
      return state;
  }
};
