import * as userConstants from "store/constants/userConstants";

export const accountCreateStart = (formdata) =>
{
  return {
    type: userConstants.CREATE_ACCOUNT_START,
    formdata,
  };
};

export const accountCreateSuccess = (response) =>
{
  return {
    type: userConstants.CREATE_ACCOUNT_SUCCESS,
    response: response,
  };
};

export const accountCreateFail = (error) =>
{
  return {
    type: userConstants.CREATE_ACCOUNT_FAIL,
    error: error,
  };
};

export const cartListStart = (quotationCartId) =>
{
  return {
    type: userConstants.LIST_CART_ITEMS_START,
    quotationCartId,
  };
};

export const cartListSuccess = (cartResponse) =>
{
  return {
    type: userConstants.LIST_CART_ITEMS_SUCCESS,
    cartItems: cartResponse.cart_list,
    billing: cartResponse.billing_details,
    status: cartResponse.status,
    addressInfo: cartResponse.address_info,
    cartListRes: cartResponse,
  };

};

export const cartListFail = (error) =>
{
  return {
    type: userConstants.LIST_CART_ITEMS_FAIL,
    error: error,
  };
};

export const getWishlistStart = () =>
{
  return {
    type: userConstants.LIST_WISHLIST_ITEMS_START,
  };
};

export const getWishlistSuccess = (wishlistItems) =>
{
  return {
    type: userConstants.LIST_WISHLIST_ITEMS_SUCCESS,
    wishlistItems: wishlistItems,
  };
};

export const getWishlistFail = (error) =>
{
  return {
    type: userConstants.LIST_WISHLIST_ITEMS_FAIL,
    error: error,
  };
};

export const clearWishlistStart = () =>
{
  return {
    type: userConstants.CLEAR_WISHLIST_ITEMS_START,
  };
};

export const clearWishlistSuccess = (response) =>
{
  return {
    type: userConstants.CLEAR_WISHLIST_ITEMS_SUCCESS,
    response: response,
  };
};

export const clearWishlistFail = (error) =>
{
  return {
    type: userConstants.CLEAR_WISHLIST_ITEMS_FAIL,
    error: error,
  };
};

export const addToCartStart = (productId, qty, specification, activeDpt) =>
{
  return {
    type: userConstants.ADD_TO_CART_START,
    productId,
    qty,
    specification,
    activeDpt,
  };
};

export const addToCartSuccess = (response) =>
{
  return {
    type: userConstants.ADD_TO_CART_SUCCESS,
    response: response,
  };
};

export const addToCartFail = (error) =>
{
  return {
    type: userConstants.ADD_TO_CART_FAIL,
    error: error,
  };
};

export const addToWishlistStart = (productId, specification) =>
{
  return {
    type: userConstants.ADD_TO_WISHLIST_START,
    productId,
    specification
  };
};

export const addToWishlistSuccess = (response) =>
{
  return {
    type: userConstants.ADD_TO_WISHLIST_SUCCESS,
    response: response,
  };
};

export const addToWishlistFail = (error) =>
{
  return {
    type: userConstants.ADD_TO_WISHLIST_FAIL,
    error: error,
  };
};

export const removeFromCartStart = (cartId) =>
{
  return {
    type: userConstants.REMOVE_FROM_CART_START,
    cartId,
  };
};

export const removeFromCartSuccess = (response) =>
{
  return {
    type: userConstants.REMOVE_FROM_CART_SUCCESS,
    response: response,
  };
};

export const removeFromCartFail = (error) =>
{
  return {
    type: userConstants.REMOVE_FROM_CART_FAIL,
    error: error,
  };
};

export const removeFromWishlistStart = (wishlistId) =>
{
  return {
    type: userConstants.REMOVE_FROM_WISHLIST_START,
    wishlistId,
  };
};

export const removeFromWishlistSuccess = (response) =>
{
  return {
    type: userConstants.REMOVE_FROM_WISHLIST_SUCCESS,
    response: response,
  };
};

export const removeFromWishlistFail = (error) =>
{
  return {
    type: userConstants.REMOVE_FROM_WISHLIST_FAIL,
    error: error,
  };
};

export const updateCartStart = (cartId, qty, qoutationUpdateId) =>
{
  return {
    type: userConstants.UPDATE_CART_START,
    cartId,
    qty,
    qoutationUpdateId,
  };
};

export const updateCartSuccess = (response) =>
{
  return {
    type: userConstants.UPDATE_CART_SUCCESS,
    response: response,
  };
};

export const updateCartFail = (error) =>
{
  return {
    type: userConstants.UPDATE_CART_FAIL,
    error: error,
  };
};


export const saveLaterStart = (cartId, wishlist_vp_id, specification_id) =>
{
  return {
    type: userConstants.SAVE_LATER_START,
    cartId,
    wishlist_vp_id,
    specification_id,
  };
};

export const saveLaterSuccess = (response) =>
{
  return {
    type: userConstants.SAVE_LATER_SUCCESS,
    response: response,
  };
};

export const saveLaterFail = (error) =>
{
  return {
    type: userConstants.SAVE_LATER_FAIL,
    error: error,
  };
};

// removeAllFromCartStart
export const removeAllFromCartStart = (quotationRemoveAll, cartassistrfqstatus) =>
{
  // console.log(quotationRemoveAll, cartassistrfqstatus, "--------------------------quotationRemoveAll, cartassistrfqstatus")
  // return {
  //   type: userConstants.REMOVE_ALL_FROM_CART_START,
  //   quotationRemoveAll,
  //   cartassistrfqstatus
  // };
};

export const removeAllFromCartSuccess = (response) =>
{
  return {
    type: userConstants.REMOVE_ALL_FROM_CART_SUCCESS,
    response: response,
  };
};

export const removeAllFromCartFail = (error) =>
{
  return {
    type: userConstants.REMOVE_ALL_FROM_CART_FAIL,
    error: error,
  };
};

// Ends here

export const userReset = () =>
{
  return {
    type: userConstants.USER_RESET,
  };
};

export const reset = () =>
{
  return {
    type: userConstants.RESET,
  };
};
