import * as orderConstants from "store/constants/orderConstants";

export const changeOrderStatusStart = (orderId, orderStatus, reason, amount, utr, creditPeriod, paymentType, paymentAction) => {
  return {
    type: orderConstants.CHANGE_ORDER_STATUS_START,
    orderId,
    orderStatus,
    reason,
    amount,
    utr,
    creditPeriod,
    paymentType,
    paymentAction
  };
};

export const changeOrderStatusSuccess = (response) => {
  return {
    type: orderConstants.CHANGE_ORDER_STATUS_SUCCESS,
    response: response,
  };
};

export const changeOrderStatusFail = (error) => {
  return {
    type: orderConstants.CHANGE_ORDER_STATUS_FAIL,
    error: error,
  };
};

export const userBillingDetailsStart = (productId, quantity, specification) => {
  return {
    type: orderConstants.GET_USER_BILLING_DETAILS_START,
    productId,
    quantity,
    specification,
  };
};

export const userBillingDetailsSuccess = (billing) => {
  return {
    type: orderConstants.GET_USER_BILLING_DETAILS_SUCCESS,
    billing: billing,
  };
};

export const userBillingDetailsFail = (error) => {
  return {
    type: orderConstants.GET_USER_BILLING_DETAILS_FAIL,
    error: error,
  };
};

export const billingByOrderIdStart = (orderId) => {
  return {
    type: orderConstants.BILLING_BY_ORDER_ID_START,
    orderId,
  };
};

export const billingByOrderIdSuccess = (billing) => {
  return {
    type: orderConstants.BILLING_BY_ORDER_ID_SUCCESS,
    billing: billing,
  };
};

export const billingByOrderIdFail = (error) => {
  return {
    type: orderConstants.BILLING_BY_ORDER_ID_FAIL,
    error: error,
  };
};

export const orderStatusReset = () => {
  return { type: orderConstants.ORDER_STATUS_RESET };
};
