import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import closeIcon from 'assets/icons/close.svg';
import Logo from 'assets/images/Layout/logo-white.svg';
import userProfile from 'assets/icons/profile-user.svg';
import Modal from 'component/Utilities/Modal';
import notificationIcon from 'assets/icons/notifications.svg';
import favouriteIcon from 'assets/icons/favorite.svg';
import shoppingCartIcon from 'assets/icons/shopping-cart.svg';
import locationIcon from 'assets/icons/location-white.svg';
import { NavLink, useHistory } from 'react-router-dom';
import { color } from 'GlobalStyles';
import { connect } from 'react-redux';
import * as userActions from 'store/actions/userActions';
import * as authActions from 'store/actions/authActions';
import * as toastActions from 'store/actions/toastActions';
import * as addressActions from 'store/actions/addressActions';
import * as generalService from 'service/generalService';
import * as addressService from 'service/addressService';
import { SearchCategories } from './SearchCategories';
import UserAddress from 'component/Cart/UserAddress';
import InlineError from 'Helpers/InlineError';
import Notification from 'component/Utilities/Notification';
// import saveEarth from 'assets/icons/save-earth.gif'
import * as userService from 'service/userService';

const Header = (props) =>
{
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [activeAddressId, setActiveAddressId] = useState(null);
  const [showNotification, setShowNotification] = useState();
  // const [showBanner, setShowBanner] = useState(true);
  // const onBannerCloseModal = () => setShowBanner(false);
  const [selectedRfqOptionCount, setSelectedRfqOptionCount] = useState(false);


  const {
    token,
    name,
    comp_name,
    onGetCartItems,
    onGetWishlistedItems,
    onShowToast,
    onGetDefaultAddress,
    defaultAddressId,
    defaultPreferredDate,
    addressType,
    response,
    onAuthReset,
  } = props;

  const [defaultAddress, setDefaultAddress] = useState(null);
  const [preferredDeliveryDate, setPreferredDeliveryDate] = useState('');
  const [selectedAddressType, setSelectedAddressType] = useState('');
  const [notificationList, setNotificationList] = useState();
  const [errors, setErrors] = useState(null);
  const todaysDate = new Date().toISOString().split('T')[0];
  const history = useHistory();
  const [userDetailsData, setUserDetailsData] = useState();
  const [loading, setLoading] = useState(false);


  useEffect(() =>
  {
    if (token) {
      onGetCartItems();
      onGetWishlistedItems();
      onGetDefaultAddress();
    }
  }, [onGetCartItems, onGetWishlistedItems, onGetDefaultAddress, token]);

  const getNotification = async () =>
  {
    try {
      const notificationListResponse = await generalService.getNotificationList();
      setNotificationList(notificationListResponse.data.data);
    }
    catch (err) {
      console.log(err);
    }
  }
  useEffect(() =>
  {

    getNotification();
  }, [])


  const notificationReadHandler = async () =>
  {
    if (!showNotification && (notificationList && notificationList.count > 0)) {
      try {
        const notificationListResponse = await generalService.readNotification();
      }
      catch (err) {
        console.log(err);
      }
    }
  }

  const onAddressSelectHandler = (id, tradePattern, type) =>
  {
    type === 'location_modal' ? setDefaultAddress(id) : setActiveAddressId(id);
  };

  useEffect(() =>
  {
    async function getCategories()
    {
      try {
        const categoryListResponse = await generalService.categoryList();
        const appendAll = [{ cat_id: '0', cat_level: '1', cat_name: 'All', cat_parent_id: '-1', cat_status: '1', subCategoryList: [] }];

        const fullDirectoryOption = [
          { cat_id: '999999', cat_level: '1', cat_name: 'Full Directory', cat_parent_id: '9999', subCategoryList: [] },
        ];

        const newCategories = [...appendAll, ...categoryListResponse.data.data.categoryList, ...fullDirectoryOption];
        setCategoriesList(categoryListResponse.data.data);
        setSelectedCategories(newCategories);
      } catch (err) {
        console.log(err);
      }
    }
    getCategories();
  }, []);

  useEffect(() =>
  {
    if (defaultAddressId && addressType) {
      setDefaultAddress(defaultAddressId);
      setPreferredDeliveryDate(defaultPreferredDate);
      if (addressType.am_address_type === 'ship_address') {
        setSelectedAddressType(`${addressType.am_name}, ${addressType.port_name}`);
      } else if (addressType.am_address_type === 'head_office') {
        setSelectedAddressType(`${addressType.am_zip_code}, ${addressType.city_name}`);
      } else if (addressType.am_address_type === 'branch_office') {
        setSelectedAddressType(`${addressType.am_zip_code}, ${addressType.city_name}`);
      }
    } else {
      setDefaultAddress(null);
      setPreferredDeliveryDate('');
      setSelectedAddressType('');
    }
  }, [defaultAddressId, defaultPreferredDate, addressType]);

  useEffect(() =>
  {
    if (response === 'Successfully! logged out.') {
      history.push('/');
    }
    return () =>
    {
      onAuthReset();
    };
  }, [response, history, onAuthReset]);

  const onCloseModal = () => setShow(false);

  const showSubCategoriesDesktop = (categoryId) =>
  {
    const parentCategoryIndex = categoriesList.categoryList.findIndex((element) => element.cat_id === categoryId);
    if (parentCategoryIndex > -1) setSubCategories(categoriesList.categoryList[parentCategoryIndex].subCategoryList);
    else setSubCategories([]);
  };

  const setDefaultDetailsHandler = async () =>
  {
    if (defaultAddress && preferredDeliveryDate) {
      try {
        const saveDefaults = await addressService.saveAddressAndDateOfDelivery(defaultAddress, preferredDeliveryDate);
        if (saveDefaults.data.msg) {
          setShow(false);
          onGetDefaultAddress();
          setErrors('');
          onShowToast('Products will be filtered out based on your address and delivery date', 'success');
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      !defaultAddress ? setErrors('Please select an address') : setErrors('Please select preferred date of delivery');
    }
  };

  const clearDefaultDetailsHandler = async () =>
  {
    try {
      const clearDefaults = await addressService.clearall();
      if (clearDefaults.data.msg) {
        setShow(false);
        onShowToast('Products will be filtered based on your selection', 'success');
        onGetDefaultAddress();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onLogoutHandler = () =>
  {
    props.onLogoutHandler();
  };

  const modalBody = token ? (
    <div className='pb-2'>
      <span className='close cursor-pointer' onClick={onCloseModal}>
        <img src={closeIcon} alt='close Icon' />
      </span>
      <UserAddress
        type='location_modal'
        onAddressSelectHandler={onAddressSelectHandler}
        activeAddressId={activeAddressId}
        defaultAddress={defaultAddress}
      />
      <div className='container mt-3'>
        <div className='row'>
          <div className='col-10 ml-3'>
            <label htmlFor='prefered_delivery_date' className='font-weight-bold'>
              Preferred Date of Delivery
            </label>
            <input
              type='date'
              className='form-control'
              min={todaysDate}
              id='prefered_delivery_date'
              name='prefered_delivery_date'
              value={preferredDeliveryDate}
              onChange={(e) => setPreferredDeliveryDate(e.target.value)}
            ></input>
            {errors && <InlineError text={errors} />}
          </div>
        </div>
      </div>

      <div className='text-center pt-3'>
        <button className='button' type='button' onClick={setDefaultDetailsHandler}>
          Save Details
        </button>
        <p className='mt-3' onClick={clearDefaultDetailsHandler}>
          Don't want to set any address or date as default ?{' '}
          <span
            className='text-info font-weight-bold cursor-pointer
          '
          >
            Click Here
          </span>
        </p>
      </div>
    </div>
  ) : (
    <div className='pb-2'>
      <span className='close cursor-pointer ' onClick={onCloseModal}>
        <img src={closeIcon} alt='close Icon' />
      </span>

      <div className='text-center pt-3'>
        <h3 className='font-weight-bold text text-center'>Select Location</h3>
        <p className='text-center mx-2 text'>To view all your saved address, kindly click on button below to login</p>
        <NavLink to='/login'>
          <button className='button'>Login</button>
        </NavLink>
      </div>
    </div>
  );



  // Diwali Banner
  // const modalBannerBody = (<div className='pb-2'>
  //   <span className='close cursor-pointer' onClick={onBannerCloseModal}>
  //     <img src={closeIcon} alt='close Icon' />
  //   </span>
  //   <div>
  //     <img src={diwaliBanner} className='img-fluid' alt='Diwali banner' />
  //   </div>
  // </div>);

  const handleCloseNav = () =>
  {
    document.getElementById("mySidenav").style.right = "-220px";
    document.getElementById("mySidenav").style.width = "220px";
    var removeBackDrop = document.getElementById("backDrop");
    removeBackDrop.parentNode.removeChild(removeBackDrop);
  }

  const handleOpenNav = () =>
  {
    document.getElementById("mySidenav").style.right = "0px";
    document.getElementById("mySidenav").style.width = "220px";
    if (!document.body.contains(document.getElementById('backDrop'))) {
      var mainBody = document.getElementById('mainBody');
      mainBody.insertAdjacentHTML('afterbegin', `<div id="backDrop" 
    onclick='{
      document.getElementById("mySidenav").style.right = "-220px";
      document.getElementById("mySidenav").style.width = "220px";
      document.getElementById("backDrop").parentNode.removeChild(document.getElementById("backDrop"));}' 
    style="background:rgba(0,0,0,0.4);z-index: 9;height: 100%;position: fixed;top: 0;width: 100%;"></div>`);
    }
  }

  useEffect(() =>
  {
    async function getSelectRfqList()
    {
      try {
        const selectRfqList = await userService.selectRfqList();
        setSelectedRfqOptionCount(selectRfqList.data.data.count_list);
      } catch (err) {
        console.log(err);
      }
    }
    async function getUserInfo()
    {
      try {
        setLoading(true);
        const userDetails = await userService.getUserProfileInfo();
        setUserDetailsData(userDetails.data.data.userDetails[0].multi_user_status)
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
    getSelectRfqList();
    getUserInfo();
  }, []);


  return (
    <>
      {show &&
        ReactDOM.createPortal(
          <Modal showModal={show} closeModal={onCloseModal} modalBody={modalBody} className='location-pop-up' />,
          document.getElementById('modal')
        )}

      {/* {showBanner &&
        ReactDOM.createPortal(
          <Modal  showModal={showBanner} closeModal={onBannerCloseModal} modalBody={modalBannerBody} className='diwali-pop-up' />,
          document.getElementById('modal')
        )} */}

      <HeaderContainer>
        <header className='fixed-top'>
          <nav className='navbar navbar-expand-lg navbar-light bg-blue py-md-0'>
            <div className='navbar-collapse '>
              <div className='col-lg-3 col-9 sss-logo'>
                <NavLink className='navbar-brand' to='/'>
                  <img src={Logo} alt='Sss Logo' className='img-fluid' />
                </NavLink>
              </div>

              <SearchCategories />

              <div className='col-lg-3 d-none d-sm-none d-lg-block pl-0'>
                <ul className='navbar-nav row header-nav align-items-center'>
                  <div className='col-6 '>
                    <li id='profileMenu'>
                      {token && <p>Welcome!</p>}
                      <div className='position-relative  d-inline-block w-100'>
                        {token ? (
                          <div onClick={() => history.push('/user-profile/account-overview')} className='position-relative d-flex'>
                            <span id='name'>{comp_name ? comp_name : name}</span>
                            <i className='fa fa-caret-down pl-2' aria-hidden='true'></i>{' '}
                          </div>
                        ) : (
                          <div>
                            <p>Hello!</p>
                            <NavLink to='/login'>Sign In</NavLink>
                          </div>
                        )}
                        {token && (
                          <div className='profileOptions '>
                            <ul>
                              <NavLink to={token ? '/user-profile/account-overview' : '/login'}>
                                <li className='title'>My Account</li>
                              </NavLink>
                              <NavLink to={token ? '/user-profile/profile-details' : '/login'}>
                                <li>Profile</li>
                              </NavLink>
                              <NavLink to={token ? '/user-profile/user-orders' : '/login'}>
                                <li>Orders</li>
                              </NavLink>
                              <NavLink to={token ? '/user-profile/user-wishlist' : '/login'}>
                                <li>Wishlist</li>
                              </NavLink>
                              <NavLink to={token ? '/user-profile/requisitions/list' : '/login'}>
                                <li>Cart Assist</li>
                              </NavLink>

                              <NavLink to={token ? '/user-profile/user-address' : '/login'}>
                                <li>Address</li>
                              </NavLink>

                              <NavLink to={token ? '/user-profile/user-reports' : '/login'}>
                                <li>Reports</li>
                              </NavLink>

                              <NavLink to={token ? '/user-profile/user-settings' : '/login'}>
                                <li>Security Settings</li>
                              </NavLink>

                              {/* Condition Start */}

                              {/* Condition based using multi user select *Incase registered by company then the below will 2 options will show and if subUser is registered then this 2 options will not show */}
                              {
                                userDetailsData === '0' ?
                                  <NavLink to={token ? '/user-profile/member-report' : '/login'}>
                                    <li>Member</li>
                                  </NavLink>
                                  :
                                  null
                              }


                              {/* Condition End */}

                              {token && <li onClick={onLogoutHandler}>Sign out</li>}
                            </ul>
                          </div>
                        )}
                      </div>
                    </li>
                  </div>
                  <div className='col-2 p-0' onClick={getNotification}>
                    <li onClick={notificationReadHandler}>
                      <span className='cursor-pointer' onClick={() => setShowNotification(prev => !prev)}>
                        <img className='img-fluid' src={notificationIcon} alt='Notifications' />
                        {notificationList && <span className='badge badge-white notify-badge'> {notificationList.count}</span>}
                      </span>
                    </li>
                    {showNotification && notificationList && notificationList.notification.length > 0 && <Notification notificationList={notificationList} setShowNotification={setShowNotification} showNotification={showNotification} />}
                  </div>
                  <div className='col-2 p-0'>
                    <li>
                      <NavLink to={token ? '/user-profile/user-wishlist' : '/login'}  >
                        <img className='img-fluid' src={favouriteIcon} alt='Wishlist' />
                        <span className='badge badge-white'>{props.wishlistItems.length}</span>
                      </NavLink>
                    </li>

                  </div>{' '}
                  {
                    props.cartItems.length > 0 || selectedRfqOptionCount >= 1 ?
                      <div className='col-2 p-0'>
                        <li>
                          <NavLink to={token ? '/cart' : '/login'}>
                            <img className='img-fluid' src={shoppingCartIcon} alt='Cart' />
                            <span className='badge badge-white'>{props.cartItems.length ? props.cartItems.length : selectedRfqOptionCount}</span>
                          </NavLink>
                        </li>
                      </div>
                      :
                      <div className='col-2 p-0'>
                        <li>
                          <NavLink to={token ? '/cart' : '/login'}>
                            <img className='img-fluid' src={shoppingCartIcon} alt='Cart' />
                            <span className='badge badge-white'>{props.cartItems.length}</span>
                          </NavLink>
                        </li>
                      </div>
                  }
                </ul>
              </div>
            </div>
          </nav>
          <BottomHeader className='bg-blue d-none d-sm-none d-lg-block'>
            <div className='container-fluid'>
              <div className='row py-2'>
                <div
                  className='col-lg-3 col-sm-3 col-12 text-white d-flex align-items-center addressDiv pl-5'
                  onClick={() => setShow(true)}
                >
                  <img src={locationIcon} alt='location' />
                  <h4 className='mb-0 pl-1 location cursor-pointer'>
                    {token ? (defaultAddressId ? selectedAddressType : <NavLink className='location cursor-pointer' to='/login'>Select Your Location</NavLink>) : <NavLink className='location cursor-pointer' to='/login'>Select Your Location</NavLink>}
                  </h4>
                  {addressType && addressType.am_address_type &&
                    <HoverDiv className='show-onhover'>
                      <h6>
                        {addressType && addressType.am_address_type === 'ship_address'
                          ? 'Ship Address'
                          : addressType && addressType.am_address_type === 'head_office'
                            ? 'Head Office'
                            : 'Branch Office'
                        } &nbsp;
                        <span>(Default)</span>
                      </h6>
                      {selectedAddressType && addressType &&
                        `${addressType.am_name}${addressType.port_name && ','}
                      ${addressType.port_name}${addressType.am_address1 && ','}
                       ${addressType.am_address1}${addressType.am_zip_code && ','}
                        ${addressType.am_zip_code}${addressType.city_name && ','}
                         ${addressType.city_name}`}



                    </HoverDiv>}
                </div>

                <div className='col-lg-9 pr-5 col-sm-8 col-12 text-white headerLinks'>
                  <ul className='nav nav-pills d-flex justify-content-between align-items-baseline'>
                    <li className='nav-item'>
                      <NavLink activeClassName='activeLink' className='nav-link' to='/full-directory'>
                        Directory
                      </NavLink>
                    </li>
                    <li className='nav-item position-relative'>
                      <div className='dropdown h-100 d-flex align-items-center' id='categoryMegaMenu'>
                        <span className='dropdownSpan dropdown-toggle'>Categories</span>
                        <div className='h-50 categoryListContainer'>
                          <div className='categoryList d-flex justify-space-between'>
                            <ul className='p-0 d-block'>
                              {selectedCategories.map((eachCategory) =>
                                eachCategory.cat_name === 'All' ? (
                                  <NavLink to='/categories' key={eachCategory.cat_id}>
                                    <li
                                      className={`d-flex justify-content-between ${subCategories.length > 0 && eachCategory.cat_id === subCategories[0].cat_parent_id && 'active'
                                        }`}
                                      onMouseOver={() => showSubCategoriesDesktop(eachCategory.cat_id)}
                                    >
                                      <span>{eachCategory.cat_name}</span>
                                    </li>
                                  </NavLink>
                                ) : eachCategory.cat_name === 'Full Directory' ? (
                                  <NavLink to='/full-directory' key={eachCategory.cat_id}>
                                    <li
                                      className={`d-flex justify-content-between ${subCategories.length > 0 && eachCategory.cat_id === subCategories[0].cat_parent_id && 'active'
                                        }`}
                                      onMouseOver={() => showSubCategoriesDesktop(eachCategory.cat_id)}
                                    >
                                      <span>{eachCategory.cat_name}</span>
                                    </li>
                                  </NavLink>
                                ) : (
                                  <NavLink to={`/shop-by-category/${eachCategory.cat_slug}`} key={eachCategory.cat_id}>
                                    <li
                                      key={eachCategory.cat_id}
                                      className={`d-flex justify-content-between ${subCategories.length > 0 && eachCategory.cat_id === subCategories[0].cat_parent_id && 'active'
                                        }`}
                                      onMouseOver={() => showSubCategoriesDesktop(eachCategory.cat_id)}
                                    >
                                      <span>{eachCategory.cat_name}</span>
                                      {eachCategory.subCategoryList && eachCategory.subCategoryList.length > 0 && (
                                        <span>
                                          <i className='fas fa-caret-right'></i>
                                        </span>
                                      )}
                                    </li>
                                  </NavLink>
                                )
                              )}
                            </ul>

                            {subCategories.length > 0 && (
                              <ul className='p-0'>
                                {subCategories.map((eachSubCategory) => (
                                  <li key={eachSubCategory.cat_id} className='d-flex justify-content-between'>
                                    <NavLink to={`/products/${eachSubCategory.sub_cat_slug}`}>{eachSubCategory.cat_name}</NavLink>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className='nav-item'>
                      <NavLink activeClassName='activeLink' className='nav-link' to='/best-sellers'>
                        Best Sellers
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink activeClassName='activeLink' className='nav-link' to='/new-releases'>
                        New Releases
                      </NavLink>
                    </li>
                    {/* <li className='nav-item'>
                      <NavLink activeClassName='activeLink' className='nav-link' to='/top-offers'>
                        Top Offers
                      </NavLink>
                    </li>*/}
                    <li className='nav-item'>
                      <NavLink activeClassName='activeLink' className='nav-link' to='/quick-deliveries'>
                        Quick Deliveries
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink activeClassName='activeLink' className='nav-link' to='/about'>
                        About Us
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink activeClassName='activeLink' className='nav-link' to='/buyer-services'>
                        Services
                      </NavLink>
                    </li>
                    <li className='nav-item' >
                      <NavLink activeClassName='' className='nav-link p-1 white-btn' to='/rfq-form'>
                        RFQ
                      </NavLink>
                    </li>
                    {/* <li className='nav-item'>
                      <img src={saveEarth} alt='Save Earth'/>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </BottomHeader>

          <MobileMenu className='d-lg-none fixed-top'>
            <span className='openSideNav' onClick={handleOpenNav}>&#9776;</span>

            <NavLink to={token ? '/cart' : '/login'} className='cartIconMobile'>
              <img className='img-fluid' src={shoppingCartIcon} alt='Cart' />
              {
                props.cartItems.length > 0 || selectedRfqOptionCount >= 1 ? <span className='badge badge-white'>{props.cartItems.length ? props.cartItems.length : selectedRfqOptionCount}</span> : <span className='badge badge-white'>{props.cartItems.length}</span>
              }
            </NavLink>

            <div id="mySidenav" className="sidenav">
              <span href="/" className="closebtn" onClick={handleCloseNav}>&times;</span>
              <ul className='sidebarMenuInner'>
                <>
                  <li onClick={handleCloseNav}>
                    <>{token ? <span id='name'>Welcome, {comp_name ? comp_name : name}!</span> : <NavLink to='/login'> Sign In</NavLink>}</>
                  </li>
                  <NavLink to={token ? '/user-profile/account-overview' : '/login'}>
                    <li className='title' onClick={handleCloseNav}>
                      <img className='img-fluid' src={userProfile} alt='Notifications' />
                      <span>My Account</span>
                    </li>
                  </NavLink>
                  <li onClick={handleCloseNav}>
                    <NavLink to='/notification'>
                      <img className='img-fluid' src={notificationIcon} alt='Notifications' />
                      <span>Notifications</span>
                    </NavLink>
                  </li>
                  <li onClick={handleCloseNav}>
                    <NavLink to={token ? '/user-profile/user-wishlist' : '/login'}>
                      <img className='img-fluid' src={favouriteIcon} alt='favouriteIcon' />
                      <span>My Wishlist</span>
                    </NavLink>
                  </li>
                  <NavLink to='/categories'>
                    <li onClick={handleCloseNav}>
                      <span>
                        <i
                          className='fas fa-list-alt'
                          style={{
                            fontSize: '25px',
                            marginRight: '15px',
                          }}
                        ></i>
                      </span>
                      <span>Categories</span>
                    </li>
                  </NavLink>
                  <li className='nav-item' onClick={handleCloseNav}>
                    <NavLink activeClassName='activeLink' className='nav-link' to='/full-directory'>
                      Directory
                    </NavLink>
                  </li>
                  <li className='nav-item' onClick={handleCloseNav}>
                    <NavLink activeClassName='activeLink' className='nav-link' to='/best-sellers'>
                      Best Sellers
                    </NavLink>
                  </li>
                  <li className='nav-item' onClick={handleCloseNav}>
                    <NavLink activeClassName='activeLink' className='nav-link' to='/new-releases'>
                      New Releases
                    </NavLink>
                  </li>
                  <li className='nav-item' onClick={handleCloseNav}>
                    <NavLink activeClassName='activeLink' className='nav-link' to='/top-offers'>
                      Top Offers
                    </NavLink>
                  </li>
                  <li className='nav-item' onClick={handleCloseNav}>
                    <NavLink activeClassName='activeLink' className='nav-link' to='/quick-deliveries'>
                      Quick Deliveries
                    </NavLink>
                  </li>
                  <li className='nav-item' onClick={handleCloseNav}>
                    <NavLink activeClassName='activeLink' className='nav-link' to='/about'>
                      About Us
                    </NavLink>
                  </li>
                  <li className='nav-item' onClick={handleCloseNav}>
                    <NavLink activeClassName='activeLink' className='nav-link' to='/buyer-services'>
                      Services
                    </NavLink>
                  </li>
                  <li className='nav-item' onClick={handleCloseNav}>
                    <NavLink activeClassName='activeLink' className='nav-link' to='/rfq-form'>
                      RFQ
                    </NavLink>
                  </li>
                  {/* Condition based using multi user select *Incase registered by company then the below will 2 options will show and if subUser is registered then this 2 options will not show */}

                  {
                    userDetailsData === '0' ?
                      <li className='nav-item' onClick={handleCloseNav}>
                        <NavLink to={token ? '/user-profile/member-report' : '/login'} activeClassName='activeLink' className='nav-link'>
                          Member
                        </NavLink>
                      </li>
                      :
                      null
                  }

                  {/* Condition End */}


                  {token &&
                    <li className='nav-item' onClick={handleCloseNav}>
                      <NavLink activeClassName='activeLink' className='nav-link' to='/home' onClick={onLogoutHandler}>
                        Sign out
                      </NavLink>
                    </li>
                  }
                </>
              </ul>
            </div>
          </MobileMenu>
        </header>
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = styled.div`
  height: 115px;
  @media only screen and (max-width: 992px) {
    height: 125px;
  }
  .form-control {
    background-color: ${color.colorWhite} !important;
    @media (max-width: 320px) {
      height: 42px !important;
    }
  }
  .headerBtn {
    font: normal normal bold 14px/16px Ubuntu;
    color: ${color.colorPrimary} !important;
    border: 0 !important;
    border-radius: 0 !important;
    background: ${color.colorSmallText} 0% 0% no-repeat padding-box !important;
    &:focus {
      box-shadow: none !important;
    }
  }

  .white-btn{
    background: #fff;
    color: #28384b !important;
  }

  #profileMenu {
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      border: 1px solid #fff;
    }
  }

  #profileMenu:hover .profileOptions {
    display: block;
  }

  .profileOptions {
    display: none;
    position: absolute;
    top: 30px;
    min-width: 145px;
    padding: 0;
    right: -4px;
    z-index: 1;
    padding-top: 0;
    line-height: 35px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 24%) 0px 6px 10px;
    left: auto;
    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: 96.3%;
      margin-left: -15px;
      margin-top: -15px;
      width: 0;
      z-index: 1;
      height: 0;
      border-bottom: solid 10px #fff;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }
    .links {
      margin-top: 5px;
      margin-bottom: 0 !important;
      span,
      a {
        margin-left: 8px;
        color: ${color.colorBlack};
        font: normal normal bold 13px/25px Nunito !important;
      }
      .text-link {
        color: ${color.colorPrimary};
        text-decoration: underline !important;
      }
    }
    .title {
      font: normal normal bold 22px/25px Nunito !important;
      padding: 5px 6px 2px;
      margin-bottom: 0;
      color: ${color.colorBlack} !important;
    }
    ul {
      padding: 0;
      li {
        padding: 2px 8px;

        list-style: none;
        font: normal normal 600 14px/25px Nunito !important;
        color: ${color.colorBlack} !important;
        cursor: pointer;
        display: block;
        &.active,
        &:active,
        &:hover {
          color: ${color.colorPrimary} !important;
          font-weight: bold !important;
          text-decoration: none;
          background-color: ${color.colorSmallText} !important;
        }

        &:hover a {
          color: ${color.colorPrimary} !important;
          font-weight: bold !important;
        }
        a {
          font: normal normal 600 14px/25px Nunito !important;
          color: ${color.colorBlack} !important;
        }
      }
    }
  }

  .header-nav {
    font: normal normal bold 16px/18px Ubuntu;
    color: ${color.colorWhite};
    p {
      font: normal normal 400 14px/16px Ubuntu;
      color: ${color.colorSmallText};
      margin-bottom: 0;
    }
    #name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
    a,
    #name {
      font: normal normal bold 16px/18px Ubuntu;
      color: ${color.colorWhite};
      text-decoration: none;
      position: relative;

      .badge-white {
        position: absolute;
        left: 18px;
        border-radius: 50%;
        background-color: #fff;
        color: ${color.colorPrimary} !important;
        top: -10px;
        font-size: 12px;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
    }
  }
  .notify-badge{
        position: absolute;
        left: 18px;
        border-radius: 50%;
        background-color: #fff;
        color: ${color.colorPrimary} !important;
        top: -4px;
        font-size: 12px;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
`;

const BottomHeader = styled.div`
  .addressDiv {
    h4 {
      text-align: left;
      font: normal normal 500 1rem/1.25rem Ubuntu;
      color: ${color.colorWhite};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    div.show-onhover{
      visibility: hidden;
    }

    &:hover div.show-onhover{
      visibility: visible;
      display: block;
      transition: 0.8s;
    }

  }
  .headerLinks ul li a.activeLink,
  .headerLinks ul li a:hover {
    color: ${color.colorPrimary} !important;
    text-decoration: none;
    background-color: ${color.colorSmallText} !important;
  }

  .headerLinks ul li a,
  .headerLinks ul li .dropdownSpan {
    font: normal normal 500 15px/20px Ubuntu;
    color: ${color.colorWhite};
    transition: 0.5s all;
    position: relative;
    cursor: pointer;
  }
  #categoryMegaMenu:hover .categoryListContainer {
    display: flex;
  }

  .categoryListContainer {
    display: none;
    justify-content: space-between;
    position: absolute;
    top: 20px;
    z-index: 10;
    left: -4px;

    .categoryList {
      margin: auto 5px;
      border-radius: 2px;
      background-color: ${color.colorWhite};
      box-shadow: 0px 6px 10px ${color.colorBoxShadow};

      ul {
        max-height: 50vh;
        overflow-y: auto;
        min-width: 20vw;
        &::-webkit-scrollbar {
          width: 2px;
          background-color: ${color.colorBgCard};
          border-radius: 50%;
        }

        &::-webkit-scrollbar-thumb {
          background: #a5a3a3;
          border-radius: 50px;
        }
        li {
          padding: 2px 8px;
          list-style: none;
          font: normal normal 600 14px/25px Nunito !important;
          color: ${color.colorBlack} !important;
          align-items: center;
          cursor: pointer;
          display: block;
          &.active,
          &:active,
          &:hover {
            color: ${color.colorPrimary} !important;
            font-weight: bold !important;
            text-decoration: none;
            background-color: ${color.colorSmallText} !important;
          }

          &:hover a {
            color: ${color.colorPrimary} !important;
            font-weight: bold !important;
          }

          i {
            font-size: 12px !important;
            margin-left: 4px;
          }
          a {
            font: normal normal 600 14px/25px Nunito !important;
            color: ${color.colorBlack} !important;
          }
        }
      }
    }
  }
`;

const MobileMenu = styled.nav`
.cartIconMobile {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 55px;
    width: 25px;
    .badge-white {
      position: absolute;
      left: 15px;
      border-radius: 50%;
      background-color: #fff;
      color: ${color.colorPrimary} !important;
      top: -5px;
      font-size: 12px;
      height: 15px;
      width: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  right: 0px;
  background-color: ${color.colorPrimary};
    z-index: 80;
  overflow-x: hidden;
  transition: 0.5s;
  &::-webkit-scrollbar {
      width: 0px;
      background-color: ${color.colorBgCard};
    }
}

.sidenav a {
  text-decoration: none;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
    top: 18px;
    right: 20px;
    font-size: 36px;
    margin-left: 50px;
    color: white;
}
.openSideNav{
  cursor: pointer;
    position: absolute;
    top: 23px;
    right: 10px;
    width: 25px;
    font-size: 25px;
    color: white;
}
.sidebarMenuInner {
    margin-top: 50px;
    padding: 0px;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  .sidebarMenuInner li {
    font: normal normal 500 15px/20px Ubuntu;
    list-style: none;
    text-transform: uppercase;
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    .mobile_categories {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  .sidebarMenuInner li img {
    width: 30px;
    margin-right: 10px;
  }
  .sidebarMenuInner li i {
  }

  .sidebarMenuInner li span {
    color: ${color.colorWhite};
    text-transform: capitalize;
  }
  .sidebarMenuInner li a {
    color: ${color.colorWhite};
    text-transform: capitalize;
    cursor: pointer;
    text-decoration: none;
  }
`;

const HoverDiv = styled.div`
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    top: 2.5rem;
    font: 400 16px / 24px Ubuntu;
    color: #304855;
    text-transform: capitalize;
    width: 70%;
    box-shadow: 0 0 5px #ccc;

    h6{
      font: 600 18px / 14px Ubuntu;
      color: #28384b;
    }  

    @media (max-width: 767px){
      display: none !important;
    }

    @media only screen and (device-width: 1280px) and (device-height: 720px) {
      width: 73%!important;
    }
`;

const mapStateToProps = (state) =>
{
  return {
    token: state.Auth.token,
    name: state.Auth.name,
    comp_name: state.Auth.comp_name,
    cartItems: state.User.cartItems,
    wishlistItems: state.User.wishlistItems,
    defaultAddressId: state.Address.defaultAddressId,
    defaultPreferredDate: state.Address.defaultPreferredDate,
    addressType: state.Address.addressType,
    response: state.Auth.response,
  };
};

const mapDispatchToProps = (dispatch) =>
{
  return {
    onGetCartItems: () => dispatch(userActions.cartListStart()),
    onGetWishlistedItems: () => dispatch(userActions.getWishlistStart()),
    onShowToast: (toastData, type) => dispatch(toastActions.showToast(toastData, type)),
    onGetDefaultAddress: () => dispatch(addressActions.getDefaultsStart()),
    onLogoutHandler: () => dispatch(authActions.logoutStart()),
    onAuthReset: () => dispatch(authActions.authReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
