import React, { useState, useEffect } from 'react';
import searchIcon from 'assets/icons/search.svg';
import styled from 'styled-components';
import { color } from 'GlobalStyles';
import * as generalService from 'service/generalService';
import { useHistory } from 'react-router';
import useDeviceMobile from 'hooks/useDeviceMobile';
import { NavLink } from 'react-router-dom';

export const SearchCategories = () =>
{
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [catType, setCatType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  const isMobile = useDeviceMobile();

  useEffect(() =>
  {
    if (!isMobile) {
      async function getCategories()
      {
        try {
          const categoryListResponse = await generalService.categoryList();
          const sortedCategories = categoryListResponse.data.data.categoryList.sort((a, b) => (a.cat_name > b.cat_name ? 1 : -1));
          setSelectedCategories(sortedCategories);
        } catch (err) {
          console.log(err);
        }
      }
      getCategories();
    }
  }, [isMobile]);

  const showSearchSubcategories = (categoryId) =>
  {
    const parentCategoryIndex = selectedCategories.findIndex((element) => element.cat_id === categoryId);
    if (parentCategoryIndex > -1) setSubCategories(selectedCategories[parentCategoryIndex].subCategoryList);
    else setSubCategories([]);
  };

  const onDropDownToggleHandler = () =>
  {
    setSubCategories([]);
    setToggleDropdown(!toggleDropdown);
  };

  const onSetSelectCategoryHandler = (catId, catName, type) =>
  {
    setSubCategories([]);
    setSelectedCategory(catName);
    setCategoryId(catId);
    setCatType(type);
  };

  const onSearchProductHandler = (e) =>
  {
    e.preventDefault();
    if (searchTerm && searchTerm.trim() !== '') {
      const regSearch = /^[A-Za-z0-9-"]+$/;
      if (regSearch.test(searchTerm.trim())) {
      history.push({
        pathname: `/search-product/${selectedCategory ? selectedCategory : 'all'}/${searchTerm}`,
        state: { catType: catType, catId: categoryId },
      });
      } else {
        document.getElementById("searchTerm").classList.add("invalid");
      }
    }
  };

  useEffect(() =>
  {
    async function getAutoSuggestions()
    {
      try {
        let catId = '',
          subcatId = '';
        if (catType === 'category') {
          catId = categoryId;
        } else if (catType === 'subcategory') {
          subcatId = categoryId;
        } 
        const suggestions = await generalService.getSuggestionsBySearchName(searchTerm, catId, subcatId);
        setSuggestions(suggestions.data.data);
      } catch (err) {
        setSuggestions([]);
        console.log(err);
      }
    }
    if (searchTerm) {
      getAutoSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [searchTerm, catType, categoryId]);

  return (
    <div className='col-lg-6'>
      <form onSubmit={onSearchProductHandler} autoComplete='new-password'>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <button
              className='btn btn-outline-secondary headerBtn py-1 dropdown-toggle d-none d-md-block'
              type='button'
              data-toggle='dropdown'
              id='dropdownMenuHeader'
              onClick={onDropDownToggleHandler}
              style={{
                width: '120px',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {selectedCategory ? selectedCategory : 'Select'}
            </button>
            {!isMobile && (
              <CategoryDropdown className={`dropdown-menu m-0 ${toggleDropdown ? 'active' : ''}`}>
                <div className='h-50 categoryListContainer'>
                  <div className='categoryList d-flex justify-space-between'>
                    <ul className='p-0 d-block'>
                      <li onClick={() => onSetSelectCategoryHandler('', 'Select', '')} onMouseOver={() => setSubCategories([])}>
                        Select
                      </li>
                      {selectedCategories.map((eachCategory) => (
                        <li
                          key={eachCategory.cat_id}
                          className={`d-flex justify-content-between ${subCategories.length > 0 && eachCategory.cat_id === subCategories[0].cat_parent_id && 'active'
                            }`}
                          onMouseOver={() => showSearchSubcategories(eachCategory.cat_id)}
                          onClick={() => onSetSelectCategoryHandler(eachCategory.cat_id, eachCategory.cat_name, 'category')}
                        >
                          <span>{eachCategory.cat_name}</span>
                          {eachCategory.subCategoryList && eachCategory.subCategoryList.length > 0 && (
                            <span>
                              <i className='fas fa-caret-right'></i>
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>

                    {subCategories.length > 0 && (
                      <ul className='p-0'>
                        {subCategories.map((eachSubCategory) => (
                          <li
                            key={eachSubCategory.cat_id}
                            className='d-flex justify-content-between'
                            onClick={() => onSetSelectCategoryHandler(eachSubCategory.cat_id, eachSubCategory.cat_name, 'subcategory')}
                          >
                            {eachSubCategory.cat_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </CategoryDropdown>
            )}
          </div>
          <div className='position-relative' style={{ flexGrow: 3 }}>
            <input
              type='text'
              className='form-control'
              placeholder='search...'
              name='searchTerm'
              id='searchTerm'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoComplete='off'
            />

            {suggestions && suggestions.length > 0 && (
              <SuggestionsContainer>
                <ul>
                  {suggestions.length > 0 &&
                    suggestions.map((eachSuggestion, index) => (
                      <NavLink to={`/${eachSuggestion.p_slug_name}/${eachSuggestion.supplier_id}`} onClick={() => setSearchTerm('')}>
                        <li key={index}>
                          {`${eachSuggestion.p_product_name} ~ ${eachSuggestion.specifications}`}
                        </li>
                      </NavLink>
                    ))}
                </ul>
              </SuggestionsContainer>
            )}
          </div>

          <div className='input-group-append'>
            <button className='headerBtn px-2' type='submit'>
              <img src={searchIcon} alt='searchIcon' className='img-fluid' />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const CategoryDropdown = styled.div`
  border-radius: 0px;
  .categoryListContainer {
    .categoryList {
      margin: auto 5px;
      background-color: ${color.colorWhite};
      ul {
        max-height: 50vh;
        overflow-y: auto;
        width: 20vw !important;
        &::-webkit-scrollbar {
          width: 2px;
          background-color: ${color.colorBgCard};
          border-radius: 50%;
        }

        &::-webkit-scrollbar-thumb {
          background: #a5a3a3;
          border-radius: 50px;
        }
        li {
          padding: 2px 8px;
          list-style: none;
          font: normal normal 600 14px/22px Nunito !important;
          color: ${color.colorBlack} !important;
          align-items: center;
          cursor: pointer;
          display: block;
          &.active,
          &:active,
          &:hover {
            color: ${color.colorPrimary} !important;
            font-weight: bold !important;
            text-decoration: none;
            background-color: ${color.colorSmallText} !important;
          }

          &:hover a {
            color: ${color.colorPrimary} !important;
            font-weight: bold !important;
          }

          i {
            font-size: 12px !important;
            margin-left: 4px;
          }
          a {
            font: normal normal 600 14px/25px Nunito !important;
            color: ${color.colorBlack} !important;
          }
        }
      }
    }
  }
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  background-color: ${color.colorWhite};
  z-index: 10;
  width: 100%;
  left: 0;
  box-shadow: 0px 4px 4px #00000029;
  border: 1px solid ${color.colorSmallText};
  ul {
    padding: 0;
    max-height: 80vh;
    overflow-y: auto;
    margin: 0;
    &::-webkit-scrollbar {
      width: 2px;
      background-color: ${color.colorBgCard};
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb {
      background: #a5a3a3;
      border-radius: 50px;
    }
    li {
      padding: 2px 8px;
      list-style: none;
      font: normal normal 600 14px/22px Nunito !important;
      color: ${color.colorBlack} !important;
      align-items: center;
      cursor: pointer;
      display: block;
      &.active,
      &:active,
      &:hover {
        color: ${color.colorPrimary} !important;
        text-decoration: none;
        background-color: ${color.colorSmallText} !important;
      }

      &:hover a {
        color: ${color.colorPrimary} !important;
        font-weight: bold !important;
      }

      a {
        font: normal normal 600 14px/25px Nunito !important;
        color: ${color.colorBlack} !important;
      }
    }
  }
`;
