export const color = {
  colorWhite : "#fff",
  colorBlack:"#333333",
  colorPrimary:"#28374C",
  colorTextPrimary:"#304855",
  colorSmallText:"#BBC6C8",
  colorBg:"#0000001F",
  colorTextSecondary:"#888888",
  colorBgCard:"#F2F2F2",
  colorInput:"#e6e9ea",
  colorGrey:"#EEEEEE",
  colorDarkGrey : "#666666",
  colorBoxShadow:"#0000003D"
}


export const boxShadows = {
  boxShadowPrimary : "0px 4px 4px #00000029",
  boxShadowSecondary : "0 0 10px #0000003D",
  boxShadowNone: "none",
}