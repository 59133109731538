export const CHANGE_ORDER_STATUS_START = "CHANGE_ORDER_STATUS_START";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_FAIL = "CHANGE_ORDER_STATUS_FAIL";

export const GET_USER_BILLING_DETAILS_START = "GET_USER_BILLING_DETAILS_START";
export const GET_USER_BILLING_DETAILS_SUCCESS = "GET_USER_BILLING_DETAILS_SUCCESS";
export const GET_USER_BILLING_DETAILS_FAIL = "GET_USER_BILLING_DETAILS_FAIL";

export const BILLING_BY_ORDER_ID_START = "BILLING_BY_ORDER_ID_START";
export const BILLING_BY_ORDER_ID_SUCCESS = "BILLING_BY_ORDER_ID_SUCCESS";
export const BILLING_BY_ORDER_ID_FAIL = "BILLING_BY_ORDER_ID_FAIL";

export const ORDER_STATUS_RESET = "ORDER_STATUS_RESET";
