import React from "react";
import styled from "styled-components";
import { color } from "GlobalStyles";
import { NavLink } from "react-router-dom";

export default function Footer()
{
  return (
    <FooterSection>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <h3>Get to know us</h3>
            <ul>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>

              <li>
                <NavLink to="/blogs">Blogs</NavLink>
              </li>
              <li>
                <NavLink to="/join-us">Careers</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4">
            <h3>Become a seller</h3>
            <ul>
              <li>
                <a href="http://seller.shipstorespares.com" target="_blank" rel="noreferrer">
                  Sign In
                </a>
              </li>

              <li>
                <NavLink to="/seller-services">Services</NavLink>
              </li>

              <li>
                <NavLink to="/advertise">Advertise</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4">
            <h3>Customer care</h3>
            <ul>
              <li>
                <NavLink to="/help-faq">Help & FAQs</NavLink>
              </li>
              <li>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>
            </ul>
          </div>
        </div>
        <hr className="ml-0 mr-0" />
        <div className="row">
          <div className="col-lg-3 social-icons">
            {/* <a href="https://www.linkedin.com/in/ship-stores-and-spares/" target="_blank" rel="noreferrer"> */}
            <a href="https://www.linkedin.com/company/shipstorespares" target="_blank" rel="noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.facebook.com/Ship-Stores-And-Spares-108842244835172" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook-square"></i>
            </a>
          </div>
          <div className="col-lg-4">
            <ul className="security">
              {/* <li>
                <a href="/#">Site map </a>
              </li> */}
              <li>
                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-5 copyright">
            <div className="copyright-version">
              <span>&#169; Cart2Port Services Pvt. Ltd. All Rights Reserved</span>
              <span>V 1.3</span>
            </div>
          </div>
        </div>
      </div>
    </FooterSection>
  );
}

const FooterSection = styled.footer`
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 30px;
  hr {
    background-color: ${color.colorSmallText};
  }

  h3 {
    font: normal normal 600 20px/26.67px Ubuntu;
    color: ${color.colorPrimary};
    text-transform: capitalize;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
    font: normal normal normal 15px/28px Nunito;
  }

  ul li a {
    color: ${color.colorBlack};
    text-decoration: none;
    transition: 0.5s all;
  }

  ul li a:hover {
    color: ${color.colorPrimary};
    text-decoration: none;
    transition: 0.5s all;
  }

  .social-icons a {
    font-size: 1.5rem;
    padding-right: 10px;
    color: ${color.colorPrimary};
    transition: 0.5s all;
  }

  .social-icons a:hover {
    color: ${color.colorPrimary};
    transition: 0.5s all;
  }

  ul.security {
    display: inline-flex;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }

  ul.security li:not(:last-child)::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px;
  }

  .security li a {
    text-transform: capitalize;
  }

  .copyright {
    color: ${color.colorBlack};
    transition: 0.5s all;
  }

  .copyright span {
    list-style-type: none;
    font: normal normal normal 15px/28px Nunito;
    color: ${color.colorBlack};
    transition: 0.5s all;
    @media (max-width: 767px){
    font: normal normal normal 12px/28px Nunito;
    }
    @media (min-width: 321px) and (max-width: 361px) {
      font: normal normal normal 11px/28px Nunito;
	  }
    @media (max-width: 320px) {
    font: normal normal normal 11px/28px Nunito;
    }
    @media only screen and (device-width: 1280px) and (device-height: 720px) {
      font: normal normal normal 14px/28px Nunito;
    }

  }

  .copyright-version{
    display: flex;
    justify-content: space-between;
    
    @media (max-width: 767px){
      display: grid;
      text-align: right;
    }
    @media (min-width: 321px) and (max-width: 361px) {
      display: grid;
      text-align: right;
	  }
    @media (max-width: 320px) {
      display: grid;
      text-align: right;
    }
  }
`;
