import * as checkoutConstants from 'store/constants/checkoutConstants';
import { updateObject } from 'shared/utility';

const initialState = {
  error: null,
  loading: false,
  response: null,
  orderDetails: null,
};

const checkoutStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const checkoutSuccess = (state, action) => {
  return updateObject(state, {
    orderDetails: action.orderDetails,
    error: null,
    loading: false,
    response: action.response,
  });
};

const checkoutFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderDetails: [],
  });
};

const checkoutReset = (state, action) => {
  return updateObject(state, {
    error: null,
    response: null,
    loading: false,
  });
};

export const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkoutConstants.CHECKOUT_START:
      return checkoutStart(state, action);
    case checkoutConstants.CHECKOUT_SUCCESS:
      return checkoutSuccess(state, action);
    case checkoutConstants.CHECKOUT_FAIL:
      return checkoutFail(state, action);
    case checkoutConstants.CHECKOUT_RESET:
      return checkoutReset(state, action);

    default:
      return state;
  }
};
